import DefaultImage from '@/assets/images/logo.png'

export default {
    namespaced: true,
    getters: {
        gameImage(state, getters, rootState){
            return rootState.Games.currentGame.image;
        },
        currentGame(state, getters, rootState){
            return rootState.Games.currentGame;
        }
    },
    mutations: {
        setGameImage(state){
            
        }
    },
    actions: {
        setImage({getters, dispatch}, path) {
            getters.currentGame.image = path;
            dispatch('commitGame');
        },
        setLabelCoords({getters, dispatch}, data){
            getters.currentGame.questions[data.questionIndex].coords = data.coords
            getters.currentGame.questions[data.questionIndex].tmpCoords = data.tmpCoords;
            dispatch('commitGame');
        },
        commitGame({commit, getters}){
            commit('Games/setGame', getters.currentGame, {root:true})
        }
    }
}