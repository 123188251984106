import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import apollo from '@/apolloClient'

// Modules
import Auth from './authentication'
import Header from './header'
import List from './list'
import EditItem from './edit'
import Assets from './assets'
import Logs from './logs'
import Utils from './utils'
import Games from './game'
import Locations from './location'
import Scenarios from './scenario'
import Nodes from './node'
import Media from './media'
import Triggers from './trigger'
import Proposals from './proposal'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
    paths: ['EditItem.currentID', 'Utils', 'Games']
  })],
  modules: {
    Utils,
    Auth,
    Logs,
    Header,
    List,
    EditItem,
    Assets,
    Games,
    Locations,
    Scenarios,
    Nodes,
    Media,
    Triggers,
    Proposals
  }
})
