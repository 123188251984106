<template>
    <div class="authentication-container">
        <div class="logo flex justify-center items-center">
            <img src="@/assets/images/auth-logo.svg" alt="">
        </div>
        <div class="input-fields">
            <b-form @submit="onSubmit" v-if="isShow">
                <b-form-group
                    id="input-group-email"
                    label="Email:"
                    label-for="input-email"
                >
                    <b-form-input
                        id="input-email"
                        v-model="form.email"
                        type="text"
                        required
                        placeholder="Entrez votre adresse mail"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    id="input-group-password"
                    label="Mot de passe:"
                    label-for="input-password"
                >
                    <b-form-input
                        id="input-password"
                        v-model="form.password"
                        type="password"
                        required
                        placeholer="..."
                    ></b-form-input>
                </b-form-group>
                <div class="flex flex-col items-center">
                    <div class="mt-0 mb-3 md:self-start flex flex-col md:flex-row md:w-full md:justify-between">
                        <b-form-checkbox
                            id="input-checkbox"
                            value="true"
                            unchecked-value=""
                            v-model="form.checked"
                        >Se souvenir de moi</b-form-checkbox>
                        <div class="text-red-600 text-center">{{getErrorMessage}}</div>
                    </div>
                    <div class="mb-3">
                        <b-button type="submit" variant="primary">Se connecter</b-button>
                    </div>
                </div>
            </b-form>
        </div>
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'Authentication',
    data(){
        return {
            form: {
                email: '',
                password: '',
                checked: ''
            },
            isShow: true
        }
    },
    computed: {
        ...mapGetters('Auth', ['getErrorMessage'])
    },
    methods: {
        ...mapActions('Auth', ['login']),
        onSubmit(evt){
            evt.preventDefault()
            if (this.form.email != '' && this.form.password != '')
                this.login(this.form);
        }
    }
}
</script>

<style lang="scss" scoped>

.authentication-container{
    @apply bg-gray-300 h-screen flex flex-col items-center justify-center;

    .logo{
        img {
            @apply w-2/3;
        }
        @screen md {
            img {
                @apply w-2/3 mb-12;
            }
        }
    }

    @screen md {
        @apply justify-center;
        .input-fields{
            @apply w-1/3;
        }
    }
}

</style>
