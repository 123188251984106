import gql from 'graphql-tag'

const GC_GET_ASSET_LIST = gql`
query GetMediaList {
    media(order_by: {created_at: asc}) {
      id
      name
      path
      thumbnail_path
      type:mediaType{
        name
        id
        slug
      }
    }
  }
`

const GC_GET_TYPE_LIST = gql`
query GetTypes {
  media_type(order_by: {created_at: asc}) {
    type:name
    id
    slug
  }
}
`

const GC_INSERT_MEDIA_ONE = gql`
mutation InsertMedia($name:String!, $path:String!, $tPath:String!, $type:uuid!) {
  insert_media_one(object: {name: $name, path: $path, thumbnail_path: $tPath, media_type_id:$type}) {
    id
    path
    name
  }
}`

const GC_DELETE_MEDIA_ONE = gql`
mutation DeleteMedia($id:uuid!) {
  delete_media(where: {id: {_eq: $id}}) {
    affected_rows
  }
}

`

export {
    GC_GET_ASSET_LIST,
    GC_GET_TYPE_LIST,
    GC_INSERT_MEDIA_ONE,
    GC_DELETE_MEDIA_ONE
}