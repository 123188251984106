<template>
    <div class="user-detail mt-8 md:mt-0">
        <div class="buttons flex flex-row justify-around md:justify-between">
            <button type="button" class="twn-button text-xs" @click="goBack">Retour</button>
            <button type="button" class="twn-button text-xs danger" @click="createOrEdit">Enregistrer le profil</button>
        </div>
        <div class="flex flex-col">
            <div class="twn-card user-card my-10">
                <div class="uppercase font-principal-bold text-sm" v-if="creating">Ajouter un profil</div>
                <div class="uppercase font-principal-bold text-sm" v-else>éditer le profil</div>
                <div class="stars flex flex-row justify-end">
                    <svg v-for="n in star.number" class="star" :key="n" v-bind:class="n <= star.colored ? 'complete': '' " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.18 64.59"><defs></defs><title>Fichier 302</title><g id="Calque_2" data-name="Calque 2"><g id="Calque_1-2" data-name="Calque 1"><path class="cls-1" d="M64.8,32.09A7.92,7.92,0,0,0,65,21a10.5,10.5,0,0,0-4.57-2.41l-12-1.68A3.45,3.45,0,0,1,46,15.24L40.73,4.41h0A8,8,0,0,0,30.14.8a7.53,7.53,0,0,0-3.61,3.61l-5.3,10.83a3.45,3.45,0,0,1-2.4,1.69l-12,1.68A8,8,0,0,0,.05,27.52a7.16,7.16,0,0,0,2.41,4.57l8.66,8.43a3.4,3.4,0,0,1,.73,2.64l-1.93,12a7.93,7.93,0,0,0,6.5,9.15,9.2,9.2,0,0,0,5-.73l10.84-5.77a3.08,3.08,0,0,1,2.89,0L46,63.62a9.87,9.87,0,0,0,3.61,1,8,8,0,0,0,7.94-8V55.2l-2.41-12a2.84,2.84,0,0,1,1-2.64Zm-12,5.06a7.54,7.54,0,0,0-2.17,7l2.17,12a3.06,3.06,0,0,1-2.41,3.61,2.47,2.47,0,0,1-2.17-.48L37.36,53.51a7.69,7.69,0,0,0-7.22,0L19.07,59.29A3.08,3.08,0,0,1,15,58.09a2.68,2.68,0,0,1-.24-1.93l2.16-12a8.23,8.23,0,0,0-2.16-7L5.59,28.72a3,3,0,0,1,0-4.33,6,6,0,0,1,1.68-1l12-1.69a8.06,8.06,0,0,0,6-4.33L30.62,6.58a3.17,3.17,0,0,1,4.09-1.45,4,4,0,0,1,1.45,1.45l5.29,10.83a7.81,7.81,0,0,0,6,4.09l12,1.69a3.37,3.37,0,0,1,2.88,3.37,2.52,2.52,0,0,1-1.2,2.16Z"/></g></g></svg>
                </div>
                <hr>
                <form class="flex flex-row flex-wrap justify-around" @submit="createOrEdit">
                    <div class="left">
                        <div class="input-group">
                            <label for="name">Nom</label>
                            <b-form-input v-model="fields.name" type="text" id="name" :state="nameValidation"></b-form-input>

                        </div>
                        <div class="input-group">
                            <label for="firstName">Prénom</label>
                            <b-form-input v-model="fields.firstName" type="text" id="firstName"></b-form-input>
                        </div>
                        <div class="input-group">
                            <label for="organisation">Office(s)</label>
                            <v-select
                                label="name"
                                :options="organizationList"
                                v-model="fields.organizations"
                                multiple
                                id="organisation"
                                class="twn-select"
                                :reduce="orga => orga.id"
                            >
                            </v-select>
                        </div>
                        <div class="input-group">
                            <label for="experienced">Navigation</label>
                            <v-select
                                v-model="fields.experienced"
                                id="experienced"
                                :options="experienceList"
                                label="name"
                                class="twn-select"
                                :reduce="experienced => experienced.slug"
                            >
                            </v-select>
                        </div>
                    </div>
                    <div class="right">
                        <div class="input-group mt-0 md:mt-10">
                            <label for="email">Adresse e-mail</label>
                            <b-form-input v-model="fields.email" type="email" id="email" :state="emailValidation"></b-form-input>
                        </div>
                        <div class="input-group">
                            <label for="status">Statut</label>
                            <v-select
                                v-model="fields.status"
                                id="status"
                                :options="statusList"
                                label="name"
                                class="twn-select"
                                :reduce="status => {
                                    return status.slug
                                }"
                            >
                                <template #search="{attributes, events}">
                                    <input
                                    class="vs__search"
                                    v-bind="attributes"
                                    v-on="events"
                                    />
                                </template>
                            </v-select>
                        </div>
                        <div class="input-group" v-if="fields.status && fields.status == 2">
                            <label for="company">Employeur</label>
                            <b-form-input v-model="fields.company" type="text" id="company"></b-form-input>
                        </div>
                        <div class="input-group" v-if="(fields.role && fields.role !== 'superadmin') || isAdmin && !visitor">
                            <label for="role">Rôle</label>
                            <v-select
                                v-model="fields.role"
                                id="role"
                                :options="roleList"
                                label="name"
                                class="twn-select"
                                :reduce="role => role.slug"
                            >
                            </v-select>
                        </div>
                        <div class="input-group">
                            <label for="seniority">Ancienneté</label>
                            <v-select
                                v-model="fields.seniority"
                                id="seniority"
                                :options="seniorityList"
                                label="name"
                                class="twn-select"
                                :reduce="seniority => seniority.slug"
                            >
                            </v-select>
                        </div>

                        <div class="button-holder mb-0">
                            <button type="submit" class="twn-button text-sm" v-if="creating">Ajouter le profil</button>
                            <button type="submit" class="twn-button text-sm" v-else>Valider les modifications</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="my-10" v-if="getUser.id">
                <div class="uppercase font-principal-bold text-sm">Statistiques utilisateur</div>
                <UserDashboard :id="getUser.id" />
            </div>
            <div class="ml-auto">
                <button type="button" class="twn-button text-xs" @click="showDeleteModal" v-if="!creating">Supprimer le profil</button>
            </div>
        </div>
        <b-modal ref="delete-modal-detail-user" class="bootstrap" centered hide-footer id="delete-modal-detail-user" hide-header>
            <div class="d-block text-center my-6 uppercase font-semibold">
                <h3>Confirmer la suppression</h3>
            </div>
            <div class="flex flex-row justify-evenly items-center">
                <button type="button" class="mt-4 twn-button" @click="$bvModal.hide('delete-modal-detail-user')">Retour</button>
                <button type="button" class="mt-4 twn-button danger" @click="deleteItem">Supprimer</button>
            </div>
        </b-modal>
    </div>
</template>
<script>

import { mapGetters, mapActions, mapState } from 'vuex';
import { FC_DELETE_ITEM } from '@/graphql/list'
import UserDashboard from '@/components/dashboard/User'
import router from '@/router/index'


export default {
    name: 'UserDetail',
    props: {
        itemID: {
            type: String,
            required: false
        },
        previousPage: {
            type: Number,
            required: false
        }
    },
    components:{
        UserDashboard
    },
    created(){
        if (this.userInfo.role == 'superadmin'){
            this.isAdmin = true
        } else if(this.userInfo.role == 'organisationadmin') {
            this.isAdmin = false
        } else {
            this.visitor = true
        }
    },
    async mounted() {
        if (this.getCurrentID !== -1 || this.itemID){
            let id = this.itemID;
            if (this.getCurrentID !== -1 && !this.itemID){
                id = this.getCurrentID;
            }
            await this.setUser(id)
            this.mapFields();
        } else {
            this.creating = true;
            await this.initUserEditLists();
            this.organizationList = this.getOrganizationList;
            // this.statusList = this.getStatusList;
        }
    },
    data() {
        return {
            star: {
                number: 8,
                colored: 2
            },
            selectedID: '',
            organizationList: [],
            isAdmin: false,
            visitor: false,
            selectedRole: null,
            selectedStatus: null,
            statusList: [
                {
                    slug: 0,
                    name: 'Administrateur dans un OPH'
                },
                {
                    slug: 1,
                    name: 'Collaborateur dans un OPH'
                },
                {
                    slug: 2,
                    name: 'Autre'
                }
            ],
            experienceList: [
                {
                    slug: true,
                    name: 'Navigation libre'
                },
                {
                    slug: false,
                    name: 'Navigation guidée'
                },
            ],
            seniorityList: [
                {
                    slug: 0,
                    name: 'Administrateur nouvellement formé'
                },
                {
                    slug: 1,
                    name: 'Administrateur expérimenté'
                },
            ],
            fields: {
                id: '',
                firstName: '',
                name: '',
                organizations: [],
                status: null,
                email: '',
                seniority: null,
                company: '',
                experienced: false,
                role: null
            },
            delayer: true,
            creating: false
        }
    },
    computed: {
        ...mapState('Auth', [ 'userInfo' ]),
        ...mapGetters('EditItem', ['getUser', 'getOrganizationList', 'getStatusList', 'getCurrentID']),
        nameValidation(){
            return this.fields.name !== undefined && this.fields.name !== null && this.fields.name !== '';
        },
        emailValidation(){
            return this.fields.email !== undefined && this.fields.email !== null && this.fields.email !== '';
        },
        allValid(){
            return this.nameValidation
                && this.emailValidation
        },
        roleList(){
            if (this.isAdmin){
                return [
                    {slug:"user", name:"Utilisateur"},
                    {slug:"organisationadmin", name:"OfficeAdmin"},
                    {slug:"superadmin", name:"SuperAdmin"},
                ]
            }
            return [
                    {slug:"user", name:"Utilisateur"},
                    {slug:"organisationadmin", name:"OfficeAdmin"},
                ]
        },
    },
    methods: {
        ...mapActions('EditItem', ['setUser', 'editUser', 'initUserEditLists', 'addUser']),
        goBack(){
            router.push({name:'users-list', params: {
                previousPage: this.previousPage
            }})
        },
        showDeleteModal(){
            this.$refs['delete-modal-detail-user'].show();
        },
        async deleteItem(){
            await this.$apollo.query({
                query: FC_DELETE_ITEM("users"),
                variables: {
                    id: this.fields.id
                }
            })
            this.$refs['delete-modal-detail-user'].hide();
            this.$router.go(-1);
        },
        mapFields(){
            this.fields = this.getUser;
            this.organizationList = this.getOrganizationList;
            // this.statusList = this.getStatusList;
        },
        createOrEdit(e){
            e.preventDefault();
            if(this.creating){
                this.confirmCreate();
            } else {
                this.confirmEdit();
            }
        },
        confirmEdit(){
            if (this.allValid && this.delayer){
                this.editUser(this.fields);
                this.delayer = false;
                setTimeout(this.resetDelayer, 500);
            }
        },
        confirmCreate(){
            if (this.allValid && this.delayer){
                this.delayer = false;
                //create
                this.addUser(this.fields);
                this.creating = false;
                setTimeout(this.resetDelayer, 500);
            }
        },
        resetDelayer(){
            this.delayer = true;
        },
    },
}
</script>
<style lang="scss">
.user-card{
    > div{
        @apply my-5;
    }
    .left, .right{
        @apply w-full;
        > div {
            @apply flex flex-row justify-around items-center my-5 flex-no-wrap;
            > label {
                @apply text-right mr-5 w-1/3 text-xs;
            }
            > input{
                &:not(.is-invalid){
                    @apply border-line-top;
                }
                @apply w-2/3 flex-auto;
                border-radius: 0.275rem !important;
            }
            .twn-select {
                @apply w-2/3;
                .vs__dropdown-toggle {
                    @apply border-line-top;
                }
                &.is-invalid{
                    .vs__dropdown-toggle {
                        border-color: #dc3545;
                    }
                }
            }
        }
        @screen xs {
            > div > label {
                @apply text-sm;
            }
        }
    }
    @screen md {
        .left, .right {
            > div {
                @apply my-10;
            }
            @apply w-1/2;
            .button-holder{
                @apply flex flex justify-end items-end;
            }
        }
    }
    hr{
        box-sizing: border-box;
        border: 1px solid;
        @apply border-line-top rounded-lg;
    }
    .stars{
        .star{
            @apply w-8 mx-1 fill-current text-gray-star;
            &.complete{
                @apply text-principal-selected;
            }
        }
    }
}
</style>