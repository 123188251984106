import gql from 'graphql-tag'

const GC_GET_SCENARIO_TYPE_LIST = gql`
query GetScenarioTypeList {
    scenario_type {
      id
      name
      description
      slug
    }
  }
`

const GC_GET_SCENARIO_LIST = gql`
query GetScenarioList {
    scenario {
      id
      name
      description
      scenario_type_id
    }
  }
`

const GC_GET_SCENARIO_BY_ID = gql`
query ScenarioByID($id: uuid!) {
  scenario(where: {id: {_eq: $id}}) {
    id
    name
    description
    scenario_type_id
    scenarioLocations {
      location_id
      scenario_id
    }
    nodes {
      id
      title
      node_content
      nodeType {
        slug
      }
      scenario_id
      position_x
      position_y
      triggers {
        name
        node_id
        triggered_node_id
        triggerType {
          slug
        }
      }
    }
  }
}
`

const GC_ADD_SCENARIO_ONE = gql`
mutation AddScenario($name: String!, $description: String!, $scenario_type_id: uuid!, $locations: [scenario_location_insert_input!]! = [], $nodes: [node_insert_input!]! = []) {
  insert_scenario_one(object: {
    name: $name,
    description: $description,
    scenario_type_id: $scenario_type_id,
    scenarioLocations: {
      data: $locations
    },
    nodes: { data: $nodes }
  }) {
    id
    nodes {
      id
    }
  }
}
`

/*
  1) Update scenario base data
  2) Delete scenario locations that no longer exist, insert newly added scenario locations
  3) Delete nodes that no longer exist, insert newly added nodes
*/
const GC_UPDATE_SCENARIO_BY_ID = gql`
mutation UpdateScenario($id: uuid!, $name: String!, $description: String!, $scenario_type_id: uuid!, $locations_ids: [uuid!]! = [], $new_locations: [scenario_location_insert_input!]! = [], $nodes: [node_insert_input!]! = []) {
  update_scenario_by_pk(pk_columns: { id: $id }, _set: {
    name: $name,
    description: $description,
    scenario_type_id: $scenario_type_id,
  }) {
    id
  }

  delete_scenario_location(where: {
    location_id: {_nin: $locations_ids},
    scenario_id: {_eq: $id}
  }) {
    affected_rows
  }
  insert_scenario_location(objects: $new_locations) {
    affected_rows
  }

  delete_node(where: {
    scenario_id: {_eq: $id}
  }) {
    affected_rows
  }
  insert_node(objects: $nodes) {
    affected_rows
    returning {
      id
    }
  }
}
`

const GC_ADD_NODE_TRIGGERS = gql`
mutation AddTriggers($triggers: [trigger_insert_input!]! = []) {
  insert_trigger(objects: $triggers) {
    affected_rows
  }
}
`

export {
  GC_GET_SCENARIO_TYPE_LIST,
  GC_GET_SCENARIO_LIST,
  GC_GET_SCENARIO_BY_ID,
  GC_ADD_SCENARIO_ONE,
  GC_UPDATE_SCENARIO_BY_ID,
  GC_ADD_NODE_TRIGGERS
}