
export default {
    namespaced: true,
    getters: {
        currentGame(state, getters, rootState){
            return rootState.Games.currentGame;
        },
    },
    actions: {
        // setDefaultGame({dispatch, commit}){
        //     commit('Games/CellByCell/setDefaultQuestion', default_question, {root:true});
        //     dispatch('Games/setDefaultGame', default_game, {root: true});
        // }
    }
}