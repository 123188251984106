import DragNDrop from './dragndrop';
import QCMTab from './qcmtab'


const default_table_header_line = () => {
    return {
        content: 'Ligne',
        index: 1,
        removeState: false
    }
}

const default_table_header_column = () => {
    return {
        content: 'Colonne',
        index: 1,
        removeState: false
    }
}

const defaultQuestionEx = (col, line) => {
    return {
        answers:[],
        text: '',
        column: col,
        line: line,
        gameID: '',
        type: '',
        correction: '',
        removeState: false
    }
}

const default_tab_answer = () => {
    return {
        text: 'Default',
        is_correct: false
    }
}

const defaultTabQuestionEx = (col, line) => {
    return {
        answers:[default_tab_answer()],
        text: '',
        column: col,
        line: line,
        gameID: '',
        type: '',
        correction: '',
        removeState: false
    }
}

export default {
    namespaced: true,
    state:{
        default_question: defaultQuestionEx
    },
    modules: {
        DragNDrop,
        QCMTab
    },
    getters: {
        currentGame(state, getters, rootState){
            return rootState.Games.currentGame;
        },
        lineCount(state, getters){
            return getters.currentGame.table_header_line.length;
        },
        columnCount(state, getters){
            return getters.currentGame.table_header_column.length - 1;
        },
        questionByCoord: (state, getters) => (coord) => {
            let i = getters.currentGame.questions.findIndex(q => q.line == coord.line && q.column == coord.column)

            return {
                index: i,
                question: getters.currentGame.questions[i]
            }
        },
        answersByCoord: (state, getters) => (coord) => {
            let i = getters.currentGame.questions.findIndex(q => q.line == coord.line && q.column == coord.column)
            return i != -1 ? getters.currentGame.questions[i].answers : null;
        },

    },
    actions: {
        addColumn({state, getters, dispatch, rootState}){
            let col = getters.currentGame.table_header_column;
            if (col.length <= 4){
                col.push(default_table_header_column());
                getters.currentGame.table_header_line.forEach((line, i) => {
                    const defaultQuestion = (getters.currentGame.type.slug == 'drag_drop' ? defaultQuestionEx(col.length - 2, i) : defaultTabQuestionEx(col.length - 2, i))
                    getters.currentGame.questions.push(defaultQuestion);
                });
            }
            dispatch('commitGame');
        },
        addLine({state, getters, dispatch, rootState}){
            let line = getters.currentGame.table_header_line;

            line.push(default_table_header_line());
            getters.currentGame.table_header_column.forEach((col, i) => {
                if (i != 0) {
                    const defaultQuestion = (getters.currentGame.type.slug == 'drag_drop' ? defaultQuestionEx(i - 1, line.length - 1) : defaultTabQuestionEx(i - 1, line.length - 1))
                    getters.currentGame.questions.push(defaultQuestion);
                }
            });
            dispatch('commitGame');
        },
        removeColumn({getters, dispatch}, columnIndex){
            if (getters.currentGame.table_header_column.length > 2){
                getters.currentGame.table_header_column.splice(columnIndex, 1);
                getters.currentGame.questions = getters.currentGame.questions.filter(question => question.column !== columnIndex - 1);
                getters.currentGame.questions.forEach(question => {
                    if (question.column > columnIndex - 1){
                        question.column--;
                    }
                })
            }
            dispatch('commitGame');
        },
        removeLine({getters, dispatch}, lineIndex){
            if (getters.currentGame.table_header_line.length > 1){
                getters.currentGame.table_header_line.splice(lineIndex, 1);
                getters.currentGame.questions = getters.currentGame.questions.filter(question => question.line !== lineIndex);
                getters.currentGame.questions.forEach(question => {
                    if (question.line > lineIndex){
                        question.line--;
                    }
                })
            }
            dispatch('commitGame');
        },
        activateColumnRemoveState({getters, dispatch}, index){
            getters.currentGame.table_header_column.forEach(col => {
                if (col)
                    col.removeState = false
            });
            getters.currentGame.table_header_column[index].removeState = true;
            dispatch('commitGame');
        },
        disableColumnRemoveState({getters, dispatch}, index){
            getters.currentGame.table_header_column[index].removeState = false;
            dispatch('commitGame');
        },
        activateLineRemoveState({getters, dispatch}, index){
            getters.currentGame.table_header_line.forEach(line => line.removeState = false);
            getters.currentGame.table_header_line[index].removeState = true;
            dispatch('commitGame');
        },
        disableLineRemoveState({getters, dispatch}, index){
            getters.currentGame.table_header_line[index].removeState = false;
            dispatch('commitGame');
        },
        disableAllColumnRemoveState({getters, dispatch}){
            getters.currentGame.table_header_column.forEach(col => {
                if (col)
                    col.removeState = false
            });
            dispatch('commitGame');
        },
        disableAllLineRemoveState({getters, dispatch}){
            getters.currentGame.table_header_line.forEach(line => line.removeState = false);
            dispatch('commitGame');
        },
        updateColumnHeaderText({getters, dispatch}, data){
            getters.currentGame.table_header_column[data.index].content = data.value.trim();
            dispatch('commitGame');
        },
        updateLineHeaderText({getters, dispatch}, data){
            getters.currentGame.table_header_line[data.index].content = data.value.trim();
            dispatch('commitGame');
        },
        updateQCMAnswer({getters, dispatch}, data){
            getters.currentGame.questions.forEach(question => {
                if (question.line == data.line && question.column == data.column){
                    question.answers[0].is_correct = data.value;
                }
            })
            dispatch('commitGame');
        },
        commitGame({commit, getters}){
            commit('Games/setGame', getters.currentGame, {root:true})
        }
    }
}