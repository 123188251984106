const default_label = () => {
    return {
        text:'Default',
        is_correct: true,
        removeState: false
    }
}

export default {
    namespaced: true,
    getters: {
        currentGame(state, getters, rootState){
            return rootState.Games.currentGame;
        },
    },
    mutations: {

    },
    actions: {
        addLabelToAnswer({getters, dispatch}, data){
            getters.currentGame.questions[data.questionIndex].answers.push(getters.currentGame.labels[data.labelIndex]);
            dispatch('commitGame');
        },
        addDefaultLabel({getters, dispatch}){
            getters.currentGame.labels.push(default_label());
            dispatch('commitGame');
        },
        updateLabelText({getters, dispatch}, data){
            getters.currentGame.labels[data.index].text = data.value;
            dispatch('commitGame');
        },
        removeLabel({getters, dispatch}, index){
            let label = getters.currentGame.labels.splice(index, 1)[0];
            getters.currentGame.questions.forEach(question => {
                let sameIndex = question.answers.indexOf(label);
                if (sameIndex !== -1){
                    question.answers.splice(sameIndex, 1);
                }
            })
            dispatch('commitGame');
        },
        commitGame({commit, getters}){
            commit('Games/setGame', getters.currentGame, {root:true})
        }
    },
}