<template>
    <div>
        <label class="switch" v-if="currentQuestion(i, j)">
            <input type="checkbox" :checked="currentQuestion(i, j).answers[0].is_correct" @input="updateQCMAnswer({value: $event.target.checked, line:i, column:j})">
            <span class="slider round"></span>
        </label>
    </div>
</template>
<script>

import {mapGetters, mapActions} from 'vuex';

export default {
    computed:{
        ...mapGetters('Games', ['currentGame']),
    },
    props: ['i', 'j'],
    methods:{
        ...mapActions('Games/CellByCell', ['updateQCMAnswer']),
        currentQuestion(line, column){
            let question = this.currentGame.questions.find(e => {
                if (e.line == line && e.column == column) return true;
                return false;
            })
            if (!question.answers || question.answers.length == 0)
                return null;
            return question;
        }
    }
}
</script>
<style scoped>

</style>