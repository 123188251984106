<template>
    <div class="flex flex-col justify-start relative">
        <div class="font-semibold flex flex-col md:flex-row items-start md:items-center justify-between mb-5 flex-wrap">
            <div class="search flex flex-row items-center w-1/3 pr-2">
                <label class="mb-0" for="search">
                    <b-icon-search class="h2 my-3 mx-2"></b-icon-search>
                </label>
                <input v-model="searchStr" type="text" id="search" class="form-control w-4/5" :placeholder="searchText">
            </div>
            <div id="filters" class="filters hidden md:flex w-1/3 justify-around">
                <div class="w-full mx-1" v-for="(filter, i) in filterInfo.select" :key="i">
                    <v-select class="style-chooser text-xs" :multiple="filter.multiple" :placeholder="criteria.select[i].label" :label="filter.label" :options="filter.data" v-model="criteria.select[i].filter"></v-select>
                </div>
            </div>
            <div class="filters-mobile w-full md:hidden flex flex-row">
                <button @click="selectedFilter = i" v-for="(filter, i) in filterInfo.select" :key="i" class="text-xs opacity-50 mobile-filter-btn mx-1 border-principal-list-item border-solid border-2 rounded-full px-2 py-1" v-bind:class="[appliedFilters[i] == true ? 'selected' : '']">{{criteria.select[i].label}}</button>
                <div @click="clearFilters" class="text-principal-selected justify-center items-center" v-bind:class="[filterApplied ? 'flex' : 'hidden']"><b-icon-trash></b-icon-trash></div>
            </div>
            <!-- <div class="md:flex flex-row justify-around hidden md:w-2/3"> -->
                <slot name="buttons">
                </slot>
            <!-- </div> -->
            <slot name="fab"></slot>
        </div>
        <b-table
        class="table_list hidden lg:block min-w-0 bootstrap"
        responsive="lg"
        :current-page="page"
        :per-page="pageSize"
        borderless
        :busy="tableBusy"
        :items="getItemsList"
        :fields="fields"
        @filtered="onFiltered"
        :filter="criteria"
        :filter-function="filterTable"
        :tbody-tr-class="{ 'cursor-pointer': selectOnly }"
        :sort-by="defaultSortBy"
        :sort-desc="defaultSortDesc"
        @row-clicked="selectItem">
            <!-- https://bootstrap-vue.js.org/docs/components/table/#tables -->
            <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                <b-spinner class="align-middle mr-1"></b-spinner>
                <strong>Chargement...</strong>
                </div>
            </template>
            <template v-slot:cell(actions)="data">
                <div v-if="!selectOnly" class="flex flex-row justify-around text-xs show-actions">
                    <button @click="editItem(data.item)" type="button" class="twn-button mr-3">{{ modifyButtonText }}</button>
                    <button v-if="withDeleteButton" @click="showDeleteModal(data.item.id)" type="button" class="twn-button danger">Supprimer</button>
                </div>
            </template>
            <hr>
        </b-table>

        <b-table class="table_list mobile lg:hidden bootstrap" responsive="xs" 
        :current-page="page"
        :per-page="pageSize"
        borderless :busy="tableBusy" :items="getItemsList" :fields="mobileFields" :filter="criteria" :filter-function="filterTable"
        :sort-by="defaultSortBy"
        :sort-desc="defaultSortDesc">
            <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                <b-spinner class="align-middle mr-1"></b-spinner>
                <strong>Chargement...</strong>
                </div>
            </template>
            <template v-slot:cell(name)="data">
                <div class="w-full mr-12 sm:mr-0">
                    {{data.item.name}} <br>
                    {{data.item.first_name}}
                </div>
            </template>
            <template v-slot:cell(actions)="data">
                <div v-if="!selectOnly" v-click-outside="clickOutsideSubmenu" @click="showEditMenu(data.item.id, $event)" class="flex justify-center w-full">
                    <div class="relative flex-row justify-around text-xs leading-tight w-1/4 items-center">
                    • <br>
                    • <br>
                    •
                    <div class="rounded-lg edit-menu shadow-twn absolute flex-col justify-evenly h-24 bg-white px-3" v-bind:class="[mobile_edit_menu == data.item.id ? 'flex' : 'hidden']">
                        <div @click="editItem(data.item)" class="flex items-center justify-center px-10">{{ modifyButtonText }}</div>
                        <hr v-if="withDeleteButton">
                        <div v-if="withDeleteButton" @click="showDeleteModal(data.item.id)" class="flex items-center justify-center px-10 text-principal-selected">Supprimer</div>
                        <div @click="closeSubmenu" class=" cursor-pointer w-6 h-6 mt-1 mr-1 flex justify-center items-center absolute top-0 right-0 text-lg font-principal"><b-icon-x></b-icon-x></div>
                    </div>
                    </div>
                </div>
            </template>
        </b-table>

        <b-pagination-nav
            class="m-auto style-paginate"
            v-model="page"
            :link-gen="linkGen"
            :number-of-pages="numPage"
            use-router
        >
        </b-pagination-nav>

        <b-modal ref="delete-modal" class="bootstrap" centered hide-footer id="delete-modal" hide-header>
            <div class="d-block text-center my-6 uppercase font-semibold">
                <h3>Confirmer la suppression</h3>
            </div>
            <div class="flex flex-row justify-evenly items-center">
                <button type="button" class="mt-4 twn-button" @click="$bvModal.hide('delete-modal')">Retour</button>
                <button type="button" class="mt-4 twn-button danger" @click="deleteItem">Supprimer</button>
            </div>
        </b-modal>
        <div class="w-full h-full absolute bg-white bottom-0" v-bind:class="[selectedFilter !== -1 ? 'block' : 'hidden']">
            <div class="w-full h-screen mx-1 flex flex-col items-center justify-around" v-for="(filter, i) in filterInfo.select" :key="i" v-bind:class="[i == selectedFilter ? 'block' : 'hidden']">
                <v-select class="style-chooser text-xl my-5 mx-5 w-full" @input="mobileFilterHandler" :multiple="filter.multiple" :placeholder="criteria.select[i].label" :label="filter.label" :options="filter.data" v-model="criteria.select[i].filter"></v-select>
                <button @click="selectFilter(i)" class="twn-button" type="button">Valider</button>
            </div>
        </div>
    </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'
import { FC_DELETE_ITEM } from '@/graphql/list'
import router from '@/router/index'
import vClickOutside from 'v-click-outside'
import gql from 'graphql-tag'

//this.listing gets the table name to list
//this.fields gets the table fields to show, maybe add a 'db-name' variable to get the actual column name
export default {
    name: 'cooc-list',
    directives: {
      clickOutside: vClickOutside.directive
    },
    data() {
        return {
            page: 1,
            filterInfo: {
                select: []
            },
            criteria: {
                select: []
            },
            tableBusy: false,
            searchStr: '',
            selectedID:'',
            totalItems: 0,
            selectedFilter: -1,
            appliedFilters: [],
            mobile_edit_menu: -1,
            filterApplied: false,
        }
    },
    props: {
        pageSize: {
            type: Number,
            required: false,
            default: 30
        },
        previousPage: {
            type: Number,
            required: false,
            default: null
        },
        listing: {
            type: String,
            required: false,
            default: null,
        },
        fields: {
            type: Array,
            required: false,
            default: null,
        },
        filters: {
            type: Array,
            required: false,
            default: null,
        },
        defaultSortBy: {
            type: String,
            required: false,
            default: '',
        },
        defaultSortDesc: {
            type: Boolean,
            required: false,
            default: false,
        },
        searchText: {
            type: String,
            required: false,
            default: null,
        },
        mobileFields: {
            type: Array,
            required: false,
            default: null,
        },
        editRouteName: {
            type: String,
            required: false,
            default: null,
        },
        editRouteKey: {
            type: String,
            required: false,
            default: null,
        },
        deleteItemOverride: {
            type: Function,
            required: false,
            default: null,
        },
        selectOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
        modifyButtonText: {
            type: String,
            default: 'Modifier'
        },
        withDeleteButton: {
            type: Boolean,
            default: true
        },
        orderBy: {
            type: String,
            required: false
        }
    },
    computed: {
        ...mapGetters('List', ['getItemsList']),
        numPage(){
            if (this.totalItems && this.pageSize){
                return this.totalItems % this.pageSize == 0 ? (this.totalItems / this.pageSize) : (this.totalItems / this.pageSize) + 1
            }
            return 1
        }
    },
    async mounted(){
        await this.getList();
        if (this.previousPage){
            this.page = this.previousPage
            this.$router.push({
                name: this.$router.currentRoute.name,
                query:{
                    page: this.page
                }
            })
        }
    },
    methods: {
        ...mapActions('List', ['listItems']),
        linkGen(page){
            return page === 1 ? '?' : '?page=' + page
        },
        async getList(){
            let data = {
                table: this.listing,
                fields: this.fields,
                orderBy: this.orderBy
            }
            await this.listItems(data);
            this.totalItems = this.getItemsList.length;
            this.createFilters();
        },
        createFilters(){
            this.filterInfo.select = [];
            if (this.filters && Array.isArray(this.filters)){
                this.filters.forEach(filter => {
                    let tmp = [];
                    this.appliedFilters.push(false);
                    this.getItemsList.forEach(item => {
                        if (Array.isArray(item[filter.key])){
                            item[filter.key].forEach(i => tmp.push(i));
                        }
                        else if (item[filter.key] !== undefined && item[filter.key] !== null){
                            tmp.push(item[filter.key].toString());
                        }
                    })
                    let filterOptions = Array.from(new Set(tmp)).filter(a => a);
                    this.filterInfo.select.push({
                        label: filter.key,
                        data:filterOptions,
                        multiple: filter.multiple
                    })
                    this.criteria.select.push({key:filter.key, label: filter.label});
                })
            }
        },
        selectItem(item, index, event) {
            this.$emit('select-item', item)
        },
        editItem(item){
            if (this.editRouteName && this.editRouteName != ''){
                let params = {};
                let name = this.editRouteName;
                if (this.editRouteKey){
                    name = this.editRouteName + '-' + item[this.editRouteKey];
                }
                router.push({
                    name: name,
                    params: {
                        itemID: item.id, 
                        previousPage: this.page
                    }
                })
            } else {
                router.push({
                    name: `${this.listing}-edit`,
                    params: {
                        itemID:item.id,
                        previousPage: this.page
                    }
                })
            }
        },
        async deleteItem(){
            if (this.deleteItemOverride){
                let test = await this.deleteItemOverride(this.selectedID);
            } else {
                const response = await this.$apollo.query({
                    query: FC_DELETE_ITEM(this.listing),
                    variables: {
                        id: this.selectedID
                    }
                })
            }
            this.selectedID = "";
            this.$refs['delete-modal'].hide();
            this.getList();
        },
        showDeleteModal(id){
            this.selectedID = id;
            this.$refs['delete-modal'].show();
        },
        filterTable(row, filter) {
            let valid = true;
            let i = 0;

            this.tableBusy = true;
            filter.select.forEach(f => {
                if (f.filter){
                    i++;
                    for (let j = 0; j < filter.select.length; j++) {
                        if (filter.select[j].filter){
                            if (row[filter.select[j].key] === null){
                                valid = false;
                            }
                            else if (Array.isArray(filter.select[j].filter)){
                                let tmpValid = 0;
                                filter.select[j].filter.forEach(ft => {
                                    if (Array.isArray(row[filter.select[j].key]) && row[filter.select[j].key].includes(ft)){
                                        tmpValid++;
                                    } else if (!Array.isArray(row[filter.select[j].key]) && row[filter.select[j].key] === ft){
                                        tmpValid++;
                                    }
                                })
                                if (tmpValid != filter.select[j].filter.length){
                                    valid = false;
                                }
                                else {
                                    valid = true;
                                }
                            }
                            else if (filter.select[j].filter && row[filter.select[j].key].toString() !== filter.select[j].filter){
                                valid = false;
                            }
                        }
                    }
                }
            })
            if (i == 0)
                valid = true;

            if (this.searchStr !== '' && valid == true){
                valid = false;
                for (const field in row) {
                    if (row[field] && row[field].toString().toLowerCase().includes(this.searchStr.toLowerCase())) {
                        valid = true;
                    }
                }
            }
            this.tableBusy = false;
            return valid;
        },
        onFiltered(items, length){
            this.page = 1;
            this.totalItems = length;
        },
        showEditMenu(id, e){
            e.stopPropagation();
            this.mobile_edit_menu = id;
        },
        selectFilter(i){
            this.selectedFilter = -1;
        },
        clickOutsideSubmenu (event) {
            this.mobile_edit_menu = -1;
        },
        closeSubmenu(e){
            e.stopPropagation();
            this.mobile_edit_menu = -1;
        },
        mobileFilterHandler(val){
            if(val && val.length > 0){
                this.appliedFilters[this.selectedFilter] = true;
            } else {
                this.appliedFilters[this.selectedFilter] = false;
            }
            this.filterApplied = false;
            if (this.appliedFilters.includes(true)){
                this.filterApplied = true;
            }
        },
        clearFilters(){
            this.criteria.select.forEach((c, i) => {
                if (c.filter){
                    //set to null to apply changes to filtering fct
                    c.filter = null;
                    delete c.filter;
                }
                this.appliedFilters[i] = false;
            });
            this.filterApplied = false;
        }
    }
}
</script>

<style lang="scss">
.style-chooser{
    .vs__clear{
        @apply p-1;
    }
    .vs__dropdown-toggle{
        border: none;
        @apply cursor-pointer;
    }
}

.style-paginate{
    .page-link, .page-link:hover{
        @apply text-black;
    }
    .page-item.active .page-link{
        @apply bg-line-left border-principal-list-item;
    }

    .page-item.disabled .page-link{
        @apply opacity-50;
    }

}

#search.form-control{
    @apply text-principal-gray;
    border: none;
}

.fab i {
    color: $textLight !important;
}

.twn-fab > .fab-item-title{
    @apply rounded-full font-principal-medium;
    border-radius: 9999px !important;
    color: $textLight;
    background-color: white;
    box-shadow: $cardShadow !important;
    padding: 0.5rem 0.75rem 0.5rem 0.75rem !important;
}

.mobile-filter-btn.selected{
    @apply border-black opacity-100;
}

.item { 
    @apply rounded-full;
}

.table_list{
    @apply mt-0 rounded-lg py-5 px-8;
    @screen md{
        @apply mt-5;
    }
    box-shadow: $cardShadow;
    thead{
        th{
            padding-bottom: 2rem !important;
            @apply uppercase font-principal-bold px-0 text-xs py-10;
            @screen sm {
                @apply text-sm;
            }
            &:last-child{
                @apply w-1/4;
            }
        }
        th:not(:hover){
            background-image: none !important;
        }
    }
    tbody, thead{
        th, td{
            @apply py-5;
        }
    }
    tbody{
        @apply text-principal-list-item;
        tr {
            &:not(:last-child){
                border-bottom: 1px solid;
                @apply border-line-top;
            }
            &:not(:hover) .show-actions{
                opacity: 0;
            }
            td {
                @apply px-0;
            }
        }
    }
    &.mobile{
        @apply px-4;
        box-shadow: none;
        min-height: 700px;
        .edit-menu{
            right:0px;
            top: -10px;
        }
    }
}
</style>