<template>
    <div class="game-vocab w-full h-full">
        <div class="buttons" v-if="gameImage">
            <button type="button" class="twn-button" @click="newLabelMode">Label</button>
            <button type="button" class="twn-button" @click="selectMode">Select</button>
            <button type="button" class="twn-button" @click="deleteLabel">Delete</button>
        </div>
        <div class="flex items-center flex-1 flex-col m-5 ml-10 mr-10">
            <div class="top-block w-full" >
                <div class="w-full m-5 image-content" @dragover="allowDrop" @drop.prevent.stop="dropImage($event)" ref="parentDiv">
                    <div class="drop-zone p-10 w-full h-full border-dashed border-2" v-show="!gameImage" @click="openMediaChoice">
                        <div class="m-auto w-1/3 break-words text-center text-xs">
                            Ajouter une image depuis la bibliothèque de média
                        </div>
                    </div>
                    <div v-show="gameImage" class="image" @pointermove="moveLabel" @pointerdown="beginDrag($event)" @pointerup="endDrag">
                        <Editor ref="editor" :canvasWidth="targetWidth" :canvasHeight="targetHeight" @dragend="endDrag"/>
                    </div>
                </div>
            </div>
            <div class="bottom-block w-full flex flex-row">
                <b-table-lite class="table w-11/12 mt-5" borderless :items="currentGame.questions" :fields="fields" v-if="currentGame.questions.length != 0">
                    <template v-slot:cell(index)="data">
                        <div class="bg-principal-selected mt-1 number rounded-full w-5 h-5 flex-center text-xs">
                            {{ data.index + 1 }}
                        </div>
                    </template>
                    <template v-slot:cell(text)="data" >
                        <input class="words border-line-top rounded-sm" type="text" :value="data.item.text">
                    </template>
                   <template v-slot:cell(authorized_values)="" >
                        <v-select class="style-chooser w-full text-xs"></v-select>
                    </template>

                </b-table-lite>
                    <div @click="addQuestion" class="plus my-auto ml-auto rounded-full w-10 h-10 flex justify-center items-center cursor-pointer">+</div>
                    <!-- <div @click="toggleBigImage" class="plus my-auto ml-auto rounded-full w-10 h-10 flex justify-center items-center cursor-pointer">+</div> -->
            </div>
        </div>
        <RightPanel ref="rp">
            <Medias :draggable-asset="true"></Medias>
        </RightPanel>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import RightPanel from '@/components/RightPanel'
import Medias from '@/views/Medias'
import Editor from 'vue-image-markup';
import DefaultImage from '@/assets/images/logo.png'

const default_question = () => {
    return {
        text: '',
        type: '',
        correction: '',
        coords: {},
        tmpCoords: {},
        removeState: false
    }
}

const default_game = () => {
    return {
        id: '',
        type: {name: '', id: ''},
        name: '',
        questions: [],
        instruction: '',
        extra_instruction: '',
        image: null
    }
}

    //Draw modes
    // text
    // circle
    // rect
    // selectMode
    // arrow
    // freeDrawing
    // crop
import {fabric} from "fabric";

export default {
    name: 'Vocabulary',
    created(){
        if (this.resetGame){
            this.setDefaultGame(default_game);
        }
        this.setDefaultQuestion(default_question);
    },
    mounted(){
        console.log('this.currentGame.image :>> ', this.currentGame.image);
        if (this.$refs['editor']){
            this.editor = this.$refs['editor'];
            this.targetWidth = this.$refs.parentDiv.clientWidth;
            console.log('this.$refs.parentDiv.clientWidth :>> ', this.$refs.parentDiv.clientWidth);
            this.targetHeight = this.targetWidth / 1.7777; //Keep 16/9 format
            this.editor.canvas.setDimensions({width:this.targetWidth, height:this.targetHeight});
            if (this.gameImage){
                this.getAssetFromPath(this.gameImage).then((res) => {
                    this.editor.setBackgroundImage(res);
                })
            }
            if (this.currentGame.questions.length > 0){
                this.currentGame.questions.forEach((question, i) => {
                    console.log('question :>> ', question);
                    this.editor.set('rect', {
                        stroke: '#DC9799',
                        strokeWidth: 2,
                        strokeUniform: false,
                        left: question.tmpCoords.left,
                        top: question.tmpCoords.top,
                        width: question.tmpCoords.width,
                        height: question.tmpCoords.height
                    })
                })
                console.log('this.editor. :>> ', this.editor.canvas._objects);
            }
            this.editor.canvas.renderAll(this.editor.canvas);
            this.newLabelMode();
        }
    },
    components:{
        RightPanel,
        Medias,
        Editor
    },
    data() {
        return {
            fields:[
                {
                    key: 'index',
                    label: '',
                    sortable: false,
                },
                {
                    key:'text',
                    label: 'Mots',
                    sortable: false,
                },
                {
                    key: 'authorized_values',
                    label: 'Valeurs acceptées',
                    sortable: false,
                },
            ],
            editor: null,
            canvasWidth: 0,
            canvasHeight: 0,
            activeObject: null,
            targetWidth: 0,
            targetHeight: 0
       }
    },
    computed: {
        ...mapGetters('Games', ['currentGame', 'resetGame']),
        ...mapGetters('Games/Vocabulary', ['gameImage']),
        canvasObj(state){
            console.log('state.editor.canvas :>> ', state.editor.canvas);
            return state.editor.canvas._objects.filter(obj => obj.id);
        }
    },
    methods: {
        ...mapMutations('Games', ['setDefaultQuestion', 'addQuestion', 'removeQuestion', 'updateCorrectionText', 'resetImage']),
        ...mapActions('Games/Vocabulary', ['setImage', 'setDefaultGame', 'setLabelCoords']),
        ...mapActions('Assets', ['getAssetFromPath']),
        ...mapActions('Games', ['setDefaultGame']),
        openMediaChoice(){
            this.$refs['rp'].show();
        },
        moveLabel(e){
            e.preventDefault();
            if (e.pressure > 0){

                this.activeObject = this.editor.canvas.getActiveObject()
                if (this.activeObject && this.editor.currentActiveTool == 'selectMode'){

                    let x1 = (this.activeObject.left / this.editor.canvas.width) * 100;
                    let y1 = (this.activeObject.top / this.editor.canvas.height) * 100;
                    let x2 = ((this.activeObject.left + this.activeObject.width) / this.editor.canvas.width) * 100;
                    let y2 = ((this.activeObject.top + this.activeObject.height) / this.editor.canvas.height) * 100;


                    let left = this.activeObject.left + (this.activeObject.width / 2)
                    let top = this.activeObject.top + (this.activeObject.height / 2)

                    let text = this.activeObject.textLabel
                    this.editor.canvas.remove(this.activeObject.textLabel);
                    text.left = left;
                    text.top = top;
                    this.editor.canvas.add(text);
                    this.editor.canvas.renderAll();
                    this.activeObject.textLabel = text;
                }
            }
        },
        allowDrop:function(event) {
            event.preventDefault();
        },
        removeCurrentQuestion(e, i){
            this.removeQuestion(i);
        },
        async dropImage(e){
            const assetJSON = e.dataTransfer.getData("application/json")
            const asset = (assetJSON ? JSON.parse(assetJSON) : null)

            let url = "";
            if (asset.path){
                url = await this.getAssetFromPath(asset.path);
                this.editor.setBackgroundImage(url);
                this.editor.canvas.renderAll(this.editor.canvas);
                this.setImage(asset.path);
            }
        },
        updateText(e, i){
            if (this.currentGame.question[i])
                this.updateQuestionText({index: i, value: e.event.target.innerHTML})
        },
        selectMode(){
            let editor = this.$refs['editor'];
            editor.set('selectMode')
        },
        beginDrag(e){
            // e.preventDefault();
            let editor = this.$refs['editor'];
            this.activeObject = editor.canvas.getActiveObject();
            if (this.activeObject){
                this.activeObject.hasControls = true;
                this.activeObject.hasBorders = true;
                this.activeObject.lockMovementX = false;
                this.activeObject.lockMovementY = false;
                this.activeObject.lockRotation = true;
                this.activeObject.lockUniScaling = false;
                editor.canvas.renderAll();
            }
            console.log('this.activeObject :>> ', this.activeObject);
            // console.log("Begin drag", this.$refs['editor'].pointerX);
        },
        endDrag(){
            this.activeObject = this.editor.canvas.getActiveObject()
            if (this.activeObject){
                let x1 = (this.activeObject.left / this.editor.canvas.width) * 100;
                let y1 = (this.activeObject.top / this.editor.canvas.height) * 100;
                let x2 = ((this.activeObject.left + this.activeObject.width) / this.editor.canvas.width) * 100;
                let y2 = ((this.activeObject.top + this.activeObject.height) / this.editor.canvas.height) * 100;

                let questionIndex;
                if (this.editor.currentActiveTool == 'rect' || this.editor.currentActiveTool == 'selectMode'){
                    if (this.editor.currentActiveTool == 'rect'){
                        this.addQuestion();
                        questionIndex = this.currentGame.questions.length - 1;
                    } else {
                        questionIndex = this.activeObject.id;
                    }

                    let number = questionIndex + 1;
                    let left = this.activeObject.left + (this.activeObject.width / 2)
                    let top = this.activeObject.top + (this.activeObject.height / 2)

                    let text;
                    if (!this.activeObject.textLabel){
                        text = new fabric.IText(number.toString(), {
                            fontSize: 20,
                            left: left,
                            top: top,
                        });
                        this.editor.canvas.add(text);
                    } else {
                        text = this.activeObject.textLabel
                        text.left = left;
                        text.top = top;
                    }
                    this.editor.canvas.renderAll();
                    this.activeObject.textLabel = text;
                }
                this.activeObject.id = questionIndex;
                this.setLabelCoords({
                    questionIndex,
                    coords: {x1, y1, x2, y2},
                    tmpCoords: {
                        left: this.activeObject.left,
                        top: this.activeObject.top,
                        width: this.activeObject.width,
                        height: this.activeObject.height,
                    }
                })
                console.log('this.getObjects :>> ', this.canvasObj);
                // this.getObjects();
            }
        },
        newLabelMode(){
            this.editor.set('rect', {
                stroke: '#DC9799',
                strokeWidth: 2,
                strokeUniform: false
            });
        },
        deleteLabel(){
            this.$refs['editor'].canvas.remove(this.activeObject);
        },
    },
}
</script>
<style lang="scss" scoped>
.game-vocab{
    .main-image{
        @apply w-full bg-line-top;
        .placeholder{
            min-height: 300px;
        }
        img{
            height: -moz-fit-content;
        }
    }
}
</style>