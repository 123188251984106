<template>
    <div class="dashboard">
        <DashboardSuperAdmin v-if="showSA" />
        <DashboardOrganisationAdmin v-else-if="showOrga && orga" :id="orga.id" />
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    import DashboardSuperAdmin from '@/components/dashboard/SuperAdmin'
    import DashboardOrganisationAdmin from '@/components/dashboard/OrganisationAdmin'
    
    export default {
        name: 'Dashboard',
        components: {
            DashboardSuperAdmin,
            DashboardOrganisationAdmin,
        },
        created(){
            if (this.userInfo.role == 'superadmin'){
                this.showSA = true
            } else if (this.userInfo.role == 'organisationadmin'){
                this.showOrga = true
            }
        },
        data(){
            return {
                gameResults: null,
                pdfPerScenario: null,
                averagePdf: null,
                totalUserTime: 0,
                showSA: false,
                showOrga: false,
                showUser: false,
                totalUsers: 0,
                locationsByScenario: null,
                scenarioCompletionPre: null,
                scenarioNameById: null,
                activePerWeek: null,
                newPerWeek: null,
                months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
                    'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
            }
        },
        computed: {
            ...mapState('Auth', [ 'userInfo', 'orga' ])
        }
    }
</script>

<style lang="scss" scoped></style>