<template>
    <div class="game-simple">
        <draggable v-model="questionList" handle=".handle" v-bind="dragOptions" @start="checkMove">
            <div class="w-full" v-for="(unused, i) in currentGame.questions.length" :key="i" @mouseenter="activateQuestionRS(i)" @mouseleave="deactivateQuestionRS(i)">
                <question-simple :number="i" :fixed-answers="currentGame.type.slug == 'true_false' && !unlockTrueFalseCompat" class="my-5"></question-simple>
            </div>
        </draggable>
        <div @click="addQuestion" class="plus m-auto rounded-full w-12 h-12 flex justify-center items-center cursor-pointer text-xl ">+</div>

        <!-- Compatiblity button -->
        <button 
        v-if="currentGame.type.slug == 'true_false' && !unlockTrueFalseCompat"
        @click="unlockTrueFalseCompat = true"
        class="twn-button text-xs float-left mt-5 mb-16"
        type="button">Mode de compatibilité</button>

        <button 
        v-if="currentGame.type.slug == 'true_false' && unlockTrueFalseCompat"
        @click="moveToQCM"
        class="twn-button text-xs float-left mt-5 mb-16"
        type="button">Transformer en QCM</button>
    </div>
</template>
<script>
import Question from '@/components/Games/Question_simple'
import draggable from 'vuedraggable'
import { mapGetters, mapMutations, mapActions } from 'vuex'

const default_answer = (text = '') => {
    return {
        text,
        is_correct: false,
        removeState: false
    }
}

const default_question = () => {
    return {
        answers:[default_answer()],
        text: '',
        gameID: '',
        type: '',
        correction: '',
        removeState: false
    }
}

const default_question_true_false = () => {
    return {
        answers:[default_answer('Vrai'), default_answer('Faux')],
        text: '',
        gameID: '',
        type: '',
        correction: '',
        removeState: false
    }
}

const default_game = () => {
    return {
        id: '',
        type: {name: '', id: ''},
        name: '',
        questions: [default_question()],
        instruction: '',
        extra_instruction: '',
    }
}

const default_game_true_false = () => {
    return {
        id: '',
        type: {name: '', id: ''},
        name: '',
        questions: [default_question_true_false()],
        instruction: '',
        extra_instruction: '',
    }
}

export default {
    name:'QCM',
    components: {
        'question-simple': Question,
        draggable
    },
    data () {
        return {
            unlockTrueFalseCompat: false
        }
    },
    created(){
        if (this.resetGame) this.setDefaultGame(default_game);

        if (this.currentGame.type.slug == 'true_false') {
            if (this.resetGame) this.setDefaultGame(default_game_true_false);
            this.setDefaultQuestion(default_question_true_false)
        } else {
            this.setDefaultQuestion(default_question)
        }
        this.setDefaultAnswer(default_answer)

    },
    computed:{
        ...mapGetters('Games', ['currentGame', 'resetGame']),

        questionList:{
            get(){
                return this.currentGame.questions;
            },
            set(value){
                this.updateListOrder(value);
            }
        },
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        }
    },
    methods:{
        ...mapMutations('Games', ['updateListOrder', 'addQuestion', 'setDefaultQuestion', 'setDefaultAnswer']),
        ...mapActions('Games', ['setDefaultGame', 'setCurrentGameTypeBySlug']),
        ...mapActions('Games/QCM', ['activateQuestionRemoveState', 'deactivateQuestionRemoveState']),
        checkMove(e, t){
            this.activateQuestionRemoveState(e.oldIndex);
        },
        activateQuestionRS(i){
            this.activateQuestionRemoveState(i);
        },
        deactivateQuestionRS(i){
            this.deactivateQuestionRemoveState(i);
        },
        moveToQCM() {
            this.setCurrentGameTypeBySlug('quiz_simple')
        }
    }
}
</script>
<style lang="scss">


.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
</style>