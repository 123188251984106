<template>
    <div id="nav" class="bg-gray-background text-principal-gray p-4 lg:p-5">
        <div class="mb-5 logo-container">
            <div class="flex flex-row items-center">
                <img class="w-2/3 logo" src="@/assets/images/auth-logo.svg" alt="">
            </div>
        </div>
        <div class="nav-links" @click="toggleMobile">
            <router-link :to="{ name: 'dashboard' }">
                <div>
                    <svg version="1.1" class="twn-icon fill-current" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                        <title>AFPOLS_PICTOS_05</title>
                        <g>
                            <path class="st0" d="M79.3,36.5c2.2-3.2,3.7-5.4,3.9-5.8c0.3-0.4,0.2-0.9-0.1-1.3c-0.3-0.3-0.9-0.4-1.3-0.1
                                c-1.4,1-35,23.8-38.1,26.9c-1.7,1.7-2.6,3.9-2.6,6.3s0.9,4.6,2.6,6.3c1.7,1.7,4,2.6,6.3,2.6s4.6-0.9,6.3-2.6
                                c2.2-2.2,14.6-20.2,21.8-30.7c5.7,6.6,8.8,15,8.8,24c0,4.5-0.8,9-2.4,13.2c-0.2,0.5,0.1,1.1,0.6,1.3c0.1,0,0.2,0.1,0.4,0.1
                                c0.4,0,0.8-0.2,0.9-0.6c1.7-4.5,2.6-9.2,2.6-14C89,52.6,85.5,43.5,79.3,36.5z M54.9,67.5c-2.7,2.7-7.1,2.7-9.8,0
                                c-1.3-1.3-2-3-2-4.9s0.7-3.6,2-4.9c2.1-2.1,20.9-15.1,33.5-23.7c-0.4,0.6-0.8,1.2-1.3,1.9c0,0,0,0,0,0c-0.1,0.1-0.2,0.2-0.2,0.4
                                C68.6,48.7,57,65.5,54.9,67.5z"/>
                            <path class="st0" d="M70.3,31.2c0.5,0.3,1.1,0.2,1.4-0.3c0.3-0.5,0.2-1.1-0.3-1.4C65,25.4,57.6,23.2,50,23.2c-21.5,0-39,17.5-39,39
                                c0,4.9,0.9,9.6,2.7,14c0.2,0.4,0.5,0.6,0.9,0.6c0.1,0,0.3,0,0.4-0.1c0.5-0.2,0.8-0.8,0.6-1.3c-1.7-4.1-2.5-8.6-2.5-13.2
                                c0-20.4,16.6-37,37-37C57.2,25.2,64.2,27.3,70.3,31.2z"/>
                            <path class="st0" d="M49,32.6v1.7c0,0.6,0.4,1,1,1s1-0.4,1-1v-1.7c0-0.6-0.4-1-1-1S49,32,49,32.6z"/>
                            <path class="st0" d="M80,61.6h-1.7c-0.6,0-1,0.4-1,1s0.4,1,1,1H80c0.6,0,1-0.4,1-1S80.6,61.6,80,61.6z"/>
                            <path class="st0" d="M21.8,61.6H20c-0.6,0-1,0.4-1,1s0.4,1,1,1h1.7c0.6,0,1-0.4,1-1S22.3,61.6,21.8,61.6z"/>
                        </g>
                    </svg>
                    <span :class="isItemIsThePageWhereUserIs('dashboard') ? 'font-black' : ''">Tableau de bord</span>
                </div>
            </router-link>
            <router-link :to="{ name: 'users-list' }">
                <div>
                    <svg class="twn-icon fill-current" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><defs></defs><title>AFPOLS_PICTOS_05</title><path class="cls-1" d="M51.24,49.49A12.28,12.28,0,1,1,63.52,37.21,12.3,12.3,0,0,1,51.24,49.49Zm0-22.56A10.28,10.28,0,1,0,61.52,37.21,10.29,10.29,0,0,0,51.24,26.93Z"/><path class="cls-1" d="M68,72.47H66V64.73a8,8,0,0,0-8-8H44.47a8,8,0,0,0-8,8v7.74h-2V64.73a10,10,0,0,1,10-10H58a10,10,0,0,1,10,10Z"/><path class="cls-1" d="M77.07,53.38a9.49,9.49,0,1,1,9.49-9.49A9.5,9.5,0,0,1,77.07,53.38Zm0-17a7.49,7.49,0,1,0,7.49,7.49A7.5,7.5,0,0,0,77.07,36.4Z"/><path class="cls-1" d="M89,72.47a1,1,0,0,1-1-1V66.4a5.79,5.79,0,0,0-5.78-5.79H72a1,1,0,0,1,0-2H82.17A7.8,7.8,0,0,1,90,66.4v5.07A1,1,0,0,1,89,72.47Z"/><path class="cls-1" d="M25.06,53.38a9.49,9.49,0,1,1,9.48-9.49A9.5,9.5,0,0,1,25.06,53.38Zm0-17a7.49,7.49,0,1,0,7.48,7.49A7.5,7.5,0,0,0,25.06,36.4Z"/><path class="cls-1" d="M13.17,72.47a1,1,0,0,1-1-1V66.4A7.81,7.81,0,0,1,20,58.61H30.15a1,1,0,0,1,0,2H20a5.8,5.8,0,0,0-5.79,5.79v5.07A1,1,0,0,1,13.17,72.47Z"/></svg>
                    <span :class="isItemIsThePageWhereUserIs('users-list') ? 'font-black' : ''">Gestion utilisateurs</span>
                </div>
            </router-link>
            <router-link :to="{ name: 'organisations-list' }" v-if="userInfo.role == 'superadmin'">
                <div>
                    <svg class="twn-icon fill-current" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><defs></defs><title>AFPOLS_PICTOS_05</title><path class="cls-1" d="M63.38,74.1a1,1,0,0,0,1-1V49.39a1,1,0,0,0-2,0V73.1A1,1,0,0,0,63.38,74.1Z"/><path class="cls-1" d="M71.38,74.1a1,1,0,0,0,1-1V52.44a1,1,0,0,0-2,0V73.1A1,1,0,0,0,71.38,74.1Z"/><path class="cls-1" d="M28.73,74.1H46.62a1,1,0,1,0,0-2H28.73a1,1,0,0,0,0,2Z"/><path class="cls-1" d="M28.73,62.55H46.62a1,1,0,0,0,0-2H28.73a1,1,0,0,0,0,2Z"/><path class="cls-1" d="M28.73,51H46.62a1,1,0,1,0,0-2H28.73a1,1,0,0,0,0,2Z"/><path class="cls-1" d="M28.73,39.45H46.62a1,1,0,0,0,0-2H28.73a1,1,0,0,0,0,2Z"/><path class="cls-1" d="M28.73,27.9H46.62a1,1,0,0,0,0-2H28.73a1,1,0,1,0,0,2Z"/><path class="cls-1" d="M91.38,83.65h-11V44.09a1,1,0,0,0-.62-.93L56,33.42V15.35a1,1,0,0,0-1-1H20.62a1,1,0,0,0-1,1v68.3h-11a1,1,0,1,0,0,2H91.38a1,1,0,0,0,0-2ZM54,34.09V83.65H21.62V16.35H54ZM78.38,83.65H56V35.58l22.38,9.18Z"/></svg>
                    <span :class="isItemIsThePageWhereUserIs('organisations-list') ? 'font-black' : ''">Gestion des offices</span>
                </div>
            </router-link>
            <router-link :to="{ name: 'scenario-list' }" v-if="userInfo.role == 'superadmin'">
                <div>
                    <svg class="twn-icon fill-current" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><defs></defs><title>AFPOLS_PICTOS_05</title><path class="cls-1" d="M69.48,19.5h-7v-7a1,1,0,0,0-1-1h-46a1,1,0,0,0-1,1v65a1,1,0,0,0,1,1h7v7a1,1,0,0,0,1,1h34a13,13,0,0,0,13-13v-53A1,1,0,0,0,69.48,19.5Zm-53,57v-63h44V66.37a2.14,2.14,0,0,1-2.13,2.13H53.48a1,1,0,0,0-1,1v4.87a2.14,2.14,0,0,1-2.13,2.13Zm52-3a11,11,0,0,1-11,11h-33v-6H50.35a4.13,4.13,0,0,0,4.13-4.13V70.5h3.87a4.13,4.13,0,0,0,4.13-4.13V21.5h6Z"/><path class="cls-1" d="M25.52,28.5h26a1,1,0,0,0,0-2h-26a1,1,0,1,0,0,2Z"/><path class="cls-1" d="M25.52,38.5h26a1,1,0,0,0,0-2h-26a1,1,0,1,0,0,2Z"/><path class="cls-1" d="M52.48,47.5a1,1,0,0,0-1-1h-26a1,1,0,1,0,0,2h26A1,1,0,0,0,52.48,47.5Z"/><path class="cls-1" d="M82,19.5a3.5,3.5,0,0,0-3.5,3.5V80A3.5,3.5,0,0,0,81,83.34V87.5a1,1,0,0,0,2,0V83.34A3.5,3.5,0,0,0,85.48,80V23A3.5,3.5,0,0,0,82,19.5ZM83.48,80a1.5,1.5,0,0,1-3,0V38.5h3Zm0-43.5h-3V23a1.5,1.5,0,0,1,3,0Z"/></svg>
                    <span :class="isItemIsThePageWhereUserIs('scenario-list') ? 'font-black' : ''">Scenario</span>
                </div>
            </router-link>
            <router-link :to="{ name: 'games-list' }" v-if="userInfo.role == 'superadmin'">
                <div>
                    <svg class="twn-icon fill-current" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><defs></defs><title>AFPOLS_PICTOS_05</title><path class="cls-1" d="M50,57a7,7,0,1,0-7-7A7,7,0,0,0,50,57Zm0-12a5,5,0,1,1-5,5A5,5,0,0,1,50,45Z"/><path class="cls-1" d="M69.32,30.68a1,1,0,0,0,.7.3,1,1,0,0,0,.71-.3L76,25.41l5.27,5.27A1,1,0,0,0,82,31a1,1,0,0,0,.7-1.71L77.41,24l5.27-5.27a1,1,0,0,0-1.41-1.41L76,22.59l-5.27-5.27a1,1,0,0,0-1.41,1.41L74.59,24l-5.27,5.27A1,1,0,0,0,69.32,30.68Z"/><path class="cls-1" d="M30.68,69.32a1,1,0,0,0-1.41,0L24,74.59l-5.27-5.27a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.41L22.59,76l-5.27,5.27a1,1,0,0,0,0,1.41,1,1,0,0,0,.7.3,1,1,0,0,0,.71-.3L24,77.41l5.27,5.27A1,1,0,0,0,30,83a1,1,0,0,0,.7-.3,1,1,0,0,0,0-1.41L25.41,76l5.27-5.27A1,1,0,0,0,30.68,69.32Z"/><path class="cls-1" d="M85.49,62H64V38H85.49a1,1,0,0,0,0-2H64V14.51a1,1,0,0,0-2,0V36H38V14.51a1,1,0,1,0-2,0V36H14.51a1,1,0,1,0,0,2H36V62H14.51a1,1,0,0,0,0,2H36V85.49a1,1,0,0,0,2,0V64H62V85.49a1,1,0,1,0,2,0V64H85.49a1,1,0,1,0,0-2ZM62,62H38V38H62Z"/></svg>
                    <span :class="isItemIsThePageWhereUserIs('games-list') ? 'font-black' : ''">Mini-jeux</span>
                </div>
            </router-link>
            <router-link :to="{ name: 'media-list' }" v-if="userInfo.role == 'superadmin'">
                <div>
                    <svg class="twn-icon fill-current" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><defs></defs><title>AFPOLS_PICTOS_05</title><path class="cls-1" d="M41.51,65.61a1,1,0,0,1-.48-.12,1,1,0,0,1-.52-.88V35.39a1,1,0,0,1,1.54-.84L65,49.16a1,1,0,0,1,0,1.68l-23,14.61A1,1,0,0,1,41.51,65.61Zm1-28.4V62.79L62.62,50Z"/><path class="cls-1" d="M50,85.38A35.38,35.38,0,1,1,85.38,50,35.42,35.42,0,0,1,50,85.38Zm0-68.76A33.38,33.38,0,1,0,83.38,50,33.43,33.43,0,0,0,50,16.62Z"/></svg>
                    <span :class="isItemIsThePageWhereUserIs('media-list') ? 'font-black' : ''">Médias</span>
                </div>
            </router-link>
        </div>
        <div @click="toggleNav" class="arrows bootstrap md:visible md:block lg:hidden text-center cursor-pointer w-full">
            <b-icon-arrow-up class="hidden h1 up-arrow m-auto"></b-icon-arrow-up>
            <b-icon-arrow-right class="right-arrow h1 m-0"></b-icon-arrow-right>
            <div class="left-arrow hidden justify-between items-center">
                <span class="uppercase ml-auto mr-auto font-semibold opacity-75 ease-linear">Réduire</span>
                <b-icon-arrow-left class="left-arrow h1 mr-3"></b-icon-arrow-left>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import tailwindConf from '../../tailwind.config'

export default {
    name: 'Navbar',
    data(){
        return {
            window: {
                width: 0,
                height: 0
            },
            publicPath: process.env.BASE_URL
        }
    },
    computed: {
        ...mapState('Auth', [ 'userInfo' ]),
    },
    methods: {
        ...mapActions('Auth', ['logout']),
        ...mapActions('Header', ['toggleNav']),
        toggleMobile(e){
            if (this.window.width <= parseInt(tailwindConf.theme.screens.sm, 10)){
                this.toggleNav(e);
            }
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },

        isItemIsThePageWhereUserIs(item) {
            return this.$route.matched.some(({ name }) => name === item)
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    }
}
</script>
<style lang="scss">
#nav {
    @apply border-line-left left-0 right-auto overflow-y-auto flex flex-col justify-between items-center;
    border-right-width: 1px;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.15);
    transition: all .5s cubic-bezier(.685,.0473,.346,1);
    transform: translate3d(0px,-100vh,0)!important;
    // width: $navbarLength;
    width: 100%;
    z-index: 99;

    > .arrows{
        @apply block;
        .up-arrow{
            @apply block;
        }
        .right-arrow{
            @apply hidden;
        }
    }




    &.nav-open {
        transform: translate3d(0px, 0, 0) !important;
    }

    > div:not(.arrows){
        @apply flex flex-col justify-around ;

        &.logo-container{
            @apply self-start;
        }

        .logo {
            transition: all .5s cubic-bezier(.685,.0473,.346,1);
            max-width: 100px;
        }

        &.nav-links{
            @apply mb-5 text-xs tracking-tighter leading-loose;
            opacity: .90;

            div {
                @apply flex justify-start items-center;
                .twn-icon {
                    @apply mb-0 mr-3 transition duration-100 ease-linear;
                    width: 60px;
                    @screen md {
                        width: 40px;
                    }
                }
                span {
                    @apply transition duration-100 ease-linear text-base tracking-normal text-xl;
                    @screen md{ @apply text-base; }
                }
            }

            > * {
                @apply my-1 p-1 rounded;
                @screen xs {
                    @apply my-3;
                }
                &:hover{
                    @apply text-principal-selected no-underline cursor-pointer;
                    // background-color: rgba(255,255,255,.2);
                }
            }
        }
    }

    @screen md {
        @apply flex justify-start flex-col;
        overflow-y: none;
        width: $navbarLength;

        transform: translate3d(0px, 0, 0) !important;

        .arrows{
            @apply mt-auto;
            .up-arrow{
                @apply hidden;
            }
        }
        .right-arrow{
            @apply hidden m-0;
        }
        .left-arrow{
            @apply flex visible m-0;
        }
        .nav-links{
            span {
                @apply opacity-100;
            }
        }
        &:not(.nav-open){
            @apply justify-between;
            .arrows{
                @apply mt-0;
            }
            .left-arrow{
                @apply hidden;
            }
            .right-arrow{
                @apply block visible;
            }
            width: 5rem !important;
            .logo{
                @apply w-full;
            }
            .nav-links{
                .twn-icon {
                    margin: 0 !important;
                }
                span {
                    @apply opacity-0;
                    @apply hidden;
                }
            }
        }

    }

    @screen lg {
        .arrows{
            @apply hidden;
        }
        &:not(.arrows){
            width: $navbarLength;
            @apply justify-start;
            .logo{
                @apply w-2/3;
            }
            .nav-links{
                @apply mt-10;
                margin-bottom: auto !important;
                .twn-icon {
                    margin-right: 0.75rem !important;
                }
                span {
                    @apply visible block opacity-100;
                }
            }
        }
    }

}
</style>