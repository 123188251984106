<template>
    <div class="w-full question flex flex-row relative justify-center items-center">
        <div class="handle self-stretch w-10 flex justify-center items-center cursor-move">
            <div class="rotate-icon">
                <b-icon-justify></b-icon-justify>
            </div>
        </div>
        <div class="flex items-center flex-1">
            <div class="handle cursor-pointer number bg-principal-selected w-10 self-stretch flex justify-center items-center text-xs">
                <div>{{number + 1}}</div>
            </div>
            <div class="content p-10 w-full" v-if="!imageState">
                <div class="input-field w-full">
                    <b-form-input v-model="questionLabel" type="text" :placeholder="questionPlaceholder ? questionPlaceholder : 'Question'"></b-form-input>
                </div>
                <div class="answers w-full flex flex-row justify-center items-center mt-5" v-if="!noAnswer">
                    <!-- Answer text -->
                    <div class="box-answers w-full" v-bind:class="[currentQuestion.answers.length == 0 ? 'w-0' : '']">
                        <div v-for="(answer, i) in currentQuestion.answers" :key="i" class="w-full flex flex-row justify-center items-center" @click="deactivateAnswerRemoveState(i, answer.removeState)">
                            <!-- Number -->
                            <div class="number w-10 text-center self-stretch p-2 cursor-pointer" @click="removeAnswerClick($event, i, answer.removeState)">
                                <div v-if="!answer.removeState">{{i + 1}}</div>
                                <div v-else><b-icon-x></b-icon-x></div>
                            </div>
                            <!-- Input/Text -->
                            <div class="input-field w-full p-2">
                                <span v-if="fixedAnswers">{{answer.text}}</span>
                                <input
                                v-else
                                class="w-full border-none" 
                                @input="updateAnswer($event, i)" 
                                type="text" 
                                :placeholder="answerPlaceholder ? answerPlaceholder : 'Réponse'"
                                :value="answer.text">
                            </div>
                        </div>
                    </div>

                    <!-- Right/Wrong answer switch -->
                    <div class="check-answers self-stretch flex flex-col justify-around items-center">
                        <div v-for="(answer, i) in currentQuestion.answers" :key="i" class="w-full mx-3 flex flex-row justify-center items-center">
                            <label class="switch">
                                <input type="checkbox" :checked="answer.is_correct" @input="updateAnswerisCorrect($event, i)">
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>

                    <!-- New answer button -->
                    <div 
                    v-if="!fixedAnswers"
                    v-bind:class="[currentQuestion.answers.length == 0 ? 'w-full' : '']">
                        <div @click="addDefaultAnswer(number)" class="plus m-auto rounded-full w-10 h-10 flex justify-center items-center cursor-pointer">+</div>
                    </div>
                </div>
                <div class="w-full input-group mt-10" v-if="!noCorrection">
                    <b-form-input v-model="correctionText" class="w-full" type="text" :id="'correction_' + number" :placeholder="correctionPlaceholder ? correctionPlaceholder : 'Correction'"></b-form-input>
                </div>
            </div>
            <slot v-else name="image-content"></slot>
            
        </div>
        <div @click="removeCurrentQuestion" class="cursor-pointer deleteQuestion self-stretch w-10 flex justify-center items-center text-white">
            <b-icon-x></b-icon-x>
        </div>
    </div>
</template>
<script>

import {mapGetters, mapActions, mapMutations} from 'vuex'

export default {
    name: 'Question',
    props: ['number', 'noAnswer', 'noCorrection', 'noAnswerText', 'imageState', 'questionPlaceholder', 'answerPlaceholder', 'correctionPlaceholder', 'fixedAnswers'],
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters('Games', ['currentGame']),
        questionLabel: {
            get(){return this.currentQuestion.text},
            set(value){this.updateQuestionText({index: this.number, value:value})}
        },
        correctionText: {
            get(){return this.currentQuestion.correction},
            set(value){this.updateCorrectionText({index: this.number, value:value})}
        },
        currentQuestion(){
            return this.currentGame.questions[this.number];
        }
    },
    methods: {
        ...mapMutations('Games', [
            'updateQuestionText',
            'updateAnswerText',
            'addDefaultAnswer',
            'removeAnswer',
            'removeQuestion',
            'updateCorrectionText'
        ]),
        ...mapActions('Games/QCM', [
            'toggleAnswerRemoveState',
            'toggleQuestionRemoveState',
            'updateQCMAnswer'
        ]),
        removeAnswerClick(e, i, removeState){
            e.stopPropagation();
            if (!removeState){
                this.toggleAnswerRemoveState({questionIndex:this.number, answerIndex:i});
            } else {
                this.removeAnswer({questionIndex:this.number, answerIndex:i})
            }
        },
        deactivateAnswerRemoveState(i, removeState){
            if (removeState)
                this.toggleAnswerRemoveState({questionIndex:this.number, answerIndex:i});
        },
        updateAnswer(e, i){
            this.updateAnswerText({questionIndex: this.number, answerIndex: i, value:e.target.value})
        },
        updateAnswerisCorrect(e, i){
            this.updateQCMAnswer({questionIndex: this.number, answerIndex: i, value:e.target.checked})
        },
        activateQuestionRS(){
            this.toggleQuestionRemoveState(this.number);
        },
        deactivateQuestionRS(){
            if (this.currentQuestion.removeState)
                this.toggleQuestionRemoveState(this.number);
        },
        removeCurrentQuestion(){
            if (this.currentQuestion.removeState){
                this.removeQuestion(this.number);
            }
        }
    },
}
</script>
<style lang="scss">
.sortable-ghost{
    // background-color: black;
}

.plus{
    background-color: rgba(225, 225, 225, 0.15);
}
.question {
    box-shadow: $cardShadow;
    border-radius: 10px;
    margin-left: 2.5rem;
    margin-right:2.5rem;
    overflow: hidden;
    &.removeState{
        margin-left:0 !important;
        margin-right:0 !important;
    }

    color: $textLight;
    .number{
        background-color: rgba(220, 151, 153, 0.1);
    }

    .rotate-icon{
        transform: rotate(90deg);
    }

    .image-content{
        .image{
            img{
                max-height:150px;
            }
        }
    }

    .deleteQuestion{
        @apply bg-principal-selected;
        // border-radius: 0 10px 10px 0;
    }

    .answers{
        .box-answers{
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
        }
        .remove-state{
            > :first-child{
                @apply bg-principal-selected text-white;
            }
            > :last-child{
                @apply border-principal-selected;
            }
        }
        .input-field{
            border: solid 0.3px #e1e1e1;
            border-left: none;
        }

         /* The switch - the box around the slider */
        .switch {
            position: relative;
            display: inline-block;
            width: 50px;
            height: 24px;
            margin-bottom:0;
        }

        /* Hide default HTML checkbox */
        .switch input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        /* The slider */
        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: .4s;
            transition: .4s;
        }

        .slider:before {
            position: absolute;
            content: "";
            height: 16px;
            width: 16px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
        }

        input:checked + .slider {
            @apply bg-principal-selected;
        }

        input:checked + .slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
        }



        /* Rounded sliders */
        .slider.round {
            border-radius: 24px;
        }

        .slider.round:before {
            border-radius: 50%;
        } 
    }

}



</style>