import gql from 'graphql-tag';

const GC_GET_USER_N_ORGAS = gql`
query GetUserInfo ($id:uuid!){
    users(where: {id: {_eq: $id}}) {
        id
        email
        experienced
        seniority
        first_name
        name
        status
        company
        role
        userOrganisations {
            organisations {
                name
                id
            }
        }
    }
    organisations_aggregate(distinct_on: id) {
        nodes {
            name
            id
        }
    }
    users_aggregate(distinct_on: experienced) {
        nodes {
            experienced
        }
    }
}
`


const GC_EDIT_USER = gql`
mutation UpdateUser($id:uuid!, $email:String!, $company: String, $seniority: Int, $experienced:Boolean, $status: Int, $role: String, $fName:String!, $lName:String!, $orgas: [user_organisations_insert_input!]!) {
    update_users_by_pk(pk_columns: {id: $id}, _set: {
        email: $email,
        experienced: $experienced,
        seniority: $seniority,
        first_name: $fName,
        name: $lName,
        company: $company
        role: $role
        status: $status
    }) {
      id
    }
    delete_user_organisations(where: {user: {_eq: $id}}) {
        affected_rows
    }
    insert_user_organisations(objects: $orgas) {
        affected_rows
    }
  }
`

const GC_ADD_USER_ONE = gql`
    mutation AddUser($email:String!, $company: String, $experienced:Boolean, $status: Int, $seniority: Int, $role: String, $fName:String!, $lName:String, $orgas: [user_organisations_insert_input!]!) {
        insert_users_one(
            object: {
                email: $email,
                experienced: $experienced,
                first_name: $fName,
                seniority: $seniority,
                name: $lName,
                status: $status
                company: $company
                role: $role,
                userOrganisations: {
                    data: $orgas
                }
            }
        ){
            id
        }
    }
`

const GC_EDIT_ORGA_ONE = gql`
mutation UpdateOrga($id:uuid!, $name:String!, $address:String, $post_code: String, $address2:String, $city:String, $country:String, $admin: uuid) {
    update_organisations_by_pk(pk_columns: {id: $id}, _set: {address: $address, address2: $address2, city: $city, name: $name, post_code: $post_code, country: $country, admin_id: $admin}) {
      id
    }
  }
`

const GC_ADD_ORGA_ONE = gql`
mutation AddOrga($id:uuid!, $name:String!, $address:String, $post_code: String, $address2:String, $city:String, $country:String, $admin: uuid) {
    insert_organisations_one(object: {address: $address, address2: $address2, city: $city, name: $name, post_code: $post_code, country: $country, admin_id: $admin}) {
      id
    }
  }
`

const GC_GET_ORGA_ONE = gql`
query GetOrga($id: uuid!) {
    organisations_by_pk(id: $id) {
      name
      id
      city
      address2
      address
      post_code
      country
      admin{
          id
          name
      }
      userOrganisations(distinct_on: user) {
        user
      }
    }
  }
`

export {
    GC_GET_USER_N_ORGAS,
    GC_EDIT_USER,
    GC_EDIT_ORGA_ONE,
    GC_GET_ORGA_ONE,
    GC_ADD_USER_ONE,
    GC_ADD_ORGA_ONE
}