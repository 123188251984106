<template>
    <div class="games">
        <div class="choice">
            <div class="" v-for="(gameType, i) in getGameTypes" :key="i">
                <router-link :to="{ name: 'game-create', params: {gameType: {id: gameType.id, name: gameType.name, description: gameType.description, slug: gameType.slug} } }">
                    <div class="game-card twn-card">
                        <div v-html="gameType.picto" class="flex picto-image"></div>
                        <div class="uppercase text-sm w-full text-center font-principal-bold">{{gameType.name}}</div>
                    </div>
                </router-link>
            </div>
        </div>
        <List listing="game" :fields="fields" :filters="filters" searchText="Rechercher un minijeu" :mobileFields="fields" editRouteName="game-edit" :deleteItemOverride="deleteGameByID">
        </List>
    </div>
</template>
<script>
import List from '@/components/List'
import {mapGetters, mapActions} from 'vuex';
export default {
    name:'MiniGamesList',
    components: {List},
    created(){
        this.setGameTypes();
    },
    data(){
        return {
            fields: [
                {
                    key:'name',
                    label: 'Identifiant',
                    sortable: true
                },
                {
                    key:'title',
                    label: 'Titre',
                    sortable: true
                },
                {
                    key: 'type',
                    db: `type:gameType {
                        name
                    }`,
                    nested: 'name',
                    label: 'Type',
                    sortable: true,
                },
                {
                    key:'actions',
                    db: '',
                    sortable: false,
                    label: ''
                }
            ],
            filters: [
                {
                    key:'type',
                    label: 'Type'
                }
            ]
        }
    },
    computed: {
        ...mapGetters('Games', ['getGameTypes'])
    },
    methods: {
        ...mapActions('Games', ['setGameTypes', 'deleteGameByID'])
    }
}
</script>
<style lang="scss">
.choice{
    // box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.1);
    @apply flex-wrap justify-center w-full flex flex-row mb-5;
    @screen lg {
        @apply justify-start;
    }
    .game-card{
        @apply flex flex-col w-48 h-48 cursor-pointer m-4;
        @screen lg { 
            @apply m-0 mr-4 mb-4;
        }
        
        .picto-image{
            height:90%;
            @apply text-principal-selected fill-current;
            svg {
                width: 100%;
                height: 100%;
            }
        }

        &:hover{
            box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.15);
        }
    }
}
</style>