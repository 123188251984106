import apollo from '@/apolloClient'
import {
    CG_GET_ORGAS,
    CG_GET_STATUS,
    GC_GET_USERS
} from '@/graphql/utils'

export default {
    namespaced: true,
    state:{
        organisationNameList:[],
        statusNameList:[],
        userNameList: []
    },
    getters:{
        getOrgaNameList(state){
            return state.organisationNameList;
        },
        getStatusNameList(state){
            return state.statusNameList;
        },
        getUserNameList(state){
            return state.userNameList;
        }
    },
    mutations:{
        SET_ORGA_LIST(state, orgaList){
            state.organisationNameList = orgaList;
        },
        SET_STATUS_LIST(state, statusList){
            if (statusList && Array.isArray(statusList)){
                let filteredArr = statusList.filter(e => e.experienced !== null);
                state.statusNameList = filteredArr;
            }
        },
        SET_USER_LIST(state, userList){
            state.userNameList = userList;
        }
    },
    actions:{
        async initAll({dispatch}){
            await dispatch('getOrgas');
            await dispatch('getStatus');
            await dispatch('getUsers');
        },
        async getOrgas({commit}){
            const response = await apollo.query({
                query: CG_GET_ORGAS
            })
            commit('SET_ORGA_LIST', response.data.organisations);
            return response;
        },
        async getStatus({commit}){
            const response = await apollo.query({
                query: CG_GET_STATUS
            })
            commit('SET_STATUS_LIST', response.data.users_aggregate.nodes);
            return response;
        },
        async getUsers({commit}){
            const response = await apollo.query({
                query: GC_GET_USERS
            })
            commit('SET_USER_LIST', response.data.users);
            return response;
        }
    }
}