import gql from "graphql-tag"

//-----------------Initialization-----------------//
const GC_INITALIZE_DASHBOARD_DATA = gql`
	query GetScenarioInfo {
		scenario(
			distinct_on: name
			where: { scenarioType: { slug: { _eq: "scenario" } }, scenarioLocations: {} }
		) {
			name
			id
			nodes_aggregate {
				aggregate {
					count
				}
			}
			scenarioLocations {
				location {
					name
				}
			}
		}
		game {
			id
			title
		}
	}
`

const GC_GET_TOTAL = gql`
	query Total(
		$from: timestamptz
		$to: timestamptz
		$xp: Boolean
		$status: Int
		$company: String
		$seminar: Boolean
		$course: Boolean
		$other: Boolean
		$mailing: Boolean
		$seniority: Int
	) {
		users(
			where: {
				role: { _eq: "user" }
				status: { _eq: $status }
				seniority: { _eq: $seniority }
				experience_seminar: { _eq: $seminar }
				experience_course: { _eq: $course }
				experience_other: { _eq: $other }
				mailing: { _eq: $mailing }
				logs: { _and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }] }
				experienced: { _eq: $xp }
				company: {
					_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
					_eq: $company
				}
			}
		) {
			id
			name
			first_name
			email
			experienced
			status
			position
			company
			seniority
			experience_course
			experience_other
			experience_seminar
			mailing
			userOrganisations {
				organisations {
					name
				}
			}
		}
		user_organisations(
			where: {
				users: {
					role: { _eq: "user" }
					status: { _eq: $status }
					seniority: { _eq: $seniority }
					experience_seminar: { _eq: $seminar }
					experience_course: { _eq: $course }
					experience_other: { _eq: $other }
					mailing: { _eq: $mailing }
					experienced: { _eq: $xp }
					company: {
						_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
						_eq: $company
					}
				}
				organisations: { _and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }] }
			}
		) {
			organisations {
				address
				address2
				city
				country
				name
				post_code
				id
				userCount:userOrganisations_aggregate {
					aggregate {
						count(columns: user)
					}
				}
			}
		}
	}
`

//-----------------Game results-----------------//
const GC_GET_GAME_RESULTS = gql`
	query GameResults(
		$from: timestamptz
		$to: timestamptz
		$id: uuid
		$xp: Boolean
		$status: Int
		$company: String
		$seminar: Boolean
		$course: Boolean
		$other: Boolean
		$mailing: Boolean
		$seniority: Int
	) {
		users(
			where: {
				role: { _eq: "user" }
				status: { _eq: $status }
				seniority: { _eq: $seniority }
				experience_seminar: { _eq: $seminar }
				experience_course: { _eq: $course }
				experience_other: { _eq: $other }
				mailing: { _eq: $mailing }
				company: {
					_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
					_eq: $company
				}
				logs: {
					logType: { slug: { _eq: "game_results" } }
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
				experienced: { _eq: $xp }
				userOrganisations: { organisation: { _eq: $id } }
			}
		) {
			id
			logs(
				where: {
					logType: { slug: { _eq: "game_results" } }
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
			) {
				data
				data_key
			}
		}
	}
`

const GC_GET_GAME_RESULTS_BY_USERID = gql`
	query GameResultByUser($id: uuid!, $from: timestamptz, $to: timestamptz) {
		users(
			where: {
				logs: {
					logType: { slug: { _eq: "game_results" } }
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
				id: { _eq: $id }
			}
		) {
			id
			logs(where: { logType: { slug: { _eq: "game_results" } } }) {
				data
				data_key
			}
		}
	}
`

// query Total {
// 	users(where: {role: {_eq: "user"}, _or: [
// 	  {company: {_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]}}
// 	  {company: {_eq: null}}
// 	]}) {
// 	  id
// 	  name
// 	}
//   }
  
//-----------------Scenario Complétion-----------------/
const GC_GET_SCENARIO_COMPLETION = gql`
	query ScenarioCompletion(
		$id: uuid
		$from: timestamptz
		$to: timestamptz
		$xp: Boolean
		$status: Int
		$company: String
		$seminar: Boolean
		$course: Boolean
		$other: Boolean
		$mailing: Boolean
		$seniority: Int
	) {
		users(
			where: {
				role: { _eq: "user" }
				status: { _eq: $status }
				seniority: { _eq: $seniority }
				experience_seminar: { _eq: $seminar }
				experience_course: { _eq: $course }
				experience_other: { _eq: $other }
				mailing: { _eq: $mailing }
				company: {
					_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
					_eq: $company
				}
				logs: {
					logType: { slug: { _eq: "scenario_nodes" } }
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
				experienced: { _eq: $xp }
				userOrganisations: { organisation: { _eq: $id } }
			}
		) {
			id
			logs(
				where: {
					logType: { slug: { _eq: "scenario_nodes" } }
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
			) {
				data_key
				data
			}
		}
	}
`

const GC_GET_SCENARIO_COMPLETION_BY_USERID = gql`
	query ScenarioCompletionByID($id: uuid!, $from: timestamptz, $to: timestamptz) {
		users(
			where: {
				logs: {
					logType: { slug: { _eq: "scenario_nodes" } }
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
				id: { _eq: $id }
			}
		) {
			logs(where: { logType: { slug: { _eq: "scenario_nodes" } } }) {
				created_at
				data
				data_key
			}
		}
	}
`

//-----------------Average Connexion time-----------------//
const GC_GET_AVERAGE_CO_TIME = gql`
	query AverageCoTime(
		$id: uuid
		$from: timestamptz
		$to: timestamptz
		$status: Int
		$xp: Boolean
		$company: String
		$seminar: Boolean
		$course: Boolean
		$other: Boolean
		$mailing: Boolean
		$seniority: Int
	) {
		users(
			where: {
				role: { _eq: "user" }
				status: { _eq: $status }
				seniority: { _eq: $seniority }
				experience_seminar: { _eq: $seminar }
				experience_course: { _eq: $course }
				experience_other: { _eq: $other }
				mailing: { _eq: $mailing }
				logs: {
					_or: [
						{ logType: { slug: { _eq: "visited_media_library" } } }
						{ logType: { slug: { _eq: "scenario_nodes" } } }
					]
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
				experienced: { _eq: $xp }
				userOrganisations: { organisation: { _eq: $id } }
				company: {
					_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
					_eq: $company
				}
			}
		) {
			id
			logs(
				order_by: { created_at: asc }
				where: {
					_or: [
						{ logType: { slug: { _eq: "visited_media_library" } } }
						{ logType: { slug: { _eq: "scenario_nodes" } } }
					]
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
			) {
				logType {
					slug
				}
				created_at
				updated_at
			}
		}
	}
`

//-----------------Active Users-----------------//
const GC_GET_USER_ACTIVITY = gql`
	query ActiveUsersPerWeek(
		$from: timestamptz!
		$to: timestamptz!
		$id: uuid
		$xp: Boolean
		$status: Int
		$company: String
		$seminar: Boolean
		$course: Boolean
		$other: Boolean
		$mailing: Boolean
		$seniority: Int
	) {
		users(
			where: {
				role: { _eq: "user" }
				status: { _eq: $status }
				seniority: { _eq: $seniority }
				experience_seminar: { _eq: $seminar }
				experience_course: { _eq: $course }
				experience_other: { _eq: $other }
				mailing: { _eq: $mailing }
				logs: {
					_or: [
						{ logType: { slug: { _eq: "visited_media_library" } } }
						{ logType: { slug: { _eq: "scenario_nodes" } } }
					]
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
				userOrganisations: { organisation: { _eq: $id } }
				experienced: { _eq: $xp }
				company: {
					_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
					_eq: $company
				}
			}
		) {
			id
			logs(
				where: {
					_or: [
						{ logType: { slug: { _eq: "visited_media_library" } } }
						{ logType: { slug: { _eq: "scenario_nodes" } } }
					]
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
				order_by: { created_at: asc }
			) {
				created_at
			}
		}
	}
`

const GC_GET_USER_ACTIVITY_BY_ID = gql`
	query UserActivityByID($from: timestamptz, $to: timestamptz, $id: uuid!) {
		users(
			where: {
				logs: {
					_or: [
						{ logType: { slug: { _eq: "visited_media_library" } } }
						{ logType: { slug: { _eq: "scenario_nodes" } } }
					]
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
				id: { _eq: $id }
			}
		) {
			logs(
				where: {
					_or: [
						{ logType: { slug: { _eq: "visited_media_library" } } }
						{ logType: { slug: { _eq: "scenario_nodes" } } }
					]
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
				order_by: { created_at: asc }
			) {
				logType {
					slug
				}
				created_at
				updated_at
			}
		}
	}
`

//-----------------New Users-----------------//
const GC_GET_NEW_USERS_PER_WEEK = gql`
	query NewUsersPerWeek(
		$from: timestamptz!
		$to: timestamptz!
		$xp: Boolean
		$status: Int
		$company: String
		$seminar: Boolean
		$course: Boolean
		$other: Boolean
		$mailing: Boolean
		$seniority: Int
		$id: uuid
	) {
		users(
			where: {
				_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				role: { _eq: "user" }
				status: { _eq: $status }
				seniority: { _eq: $seniority }
				experience_seminar: { _eq: $seminar }
				experience_course: { _eq: $course }
				experience_other: { _eq: $other }
				mailing: { _eq: $mailing }
				experienced: { _eq: $xp }
				userOrganisations: { organisation: { _eq: $id } }
				company: {
					_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
					_eq: $company
				}
			}
			order_by: { created_at: asc }
		) {
			id
			created_at
		}
	}
`

//-----------------Finished users-----------------//
const GC_GET_FINISHED_USERS = gql`
	query GetFinishedUsers(
		$id: uuid
		$from: timestamptz
		$to: timestamptz
		$xp: Boolean
		$status: Int
		$company: String
		$seminar: Boolean
		$course: Boolean
		$other: Boolean
		$mailing: Boolean
		$seniority: Int
	) {
		users(
			where: {
				logs: {
					logType: { slug: { _eq: "scenario_end" } }
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
				role: { _eq: "user" }
				experienced: { _eq: $xp }
				experience_seminar: { _eq: $seminar }
				experience_course: { _eq: $course }
				experience_other: { _eq: $other }
				mailing: { _eq: $mailing }
				status: { _eq: $status }
				seniority: { _eq: $seniority }
				userOrganisations: { organisation: { _eq: $id } }
				company: {
					_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
					_eq: $company
				}
			}
		) {
			logs(where: { logType: { slug: { _eq: "scenario_end" } } }) {
				data_key
			}
			id
		}
	}
`

const GC_GET_ORGA_ONE = gql`
	query GetOrga(
		$id: uuid!
		$from: timestamptz
		$to: timestamptz
		$status: Int
		$xp: Boolean
		$company: String
		$seminar: Boolean
		$course: Boolean
		$other: Boolean
		$mailing: Boolean
		$seniority: Int
	) {
		organisations_by_pk(id: $id) {
			name
			id
			userOrganisations(
				distinct_on: user
				where: {
					users: {
						company: {
							_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
							_eq: $company
						}
						role: { _eq: "user" }
						experience_seminar: { _eq: $seminar }
						experience_course: { _eq: $course }
						experience_other: { _eq: $other }
						mailing: { _eq: $mailing }
						status: { _eq: $status }
						seniority: { _eq: $seniority }
						experienced: { _eq: $xp }
						_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
					}
				}
			) {
				users {
					id
					name
					first_name
					company
					email
					experience_other
					experience_seminar
					experience_course
					mailing
					position
					seniority
					status
				}
			}
		}
	}
`

export {
	GC_GET_GAME_RESULTS,
	GC_GET_ORGA_ONE,
	GC_GET_TOTAL,
	GC_GET_SCENARIO_COMPLETION,
	GC_GET_USER_ACTIVITY,
	GC_GET_NEW_USERS_PER_WEEK,
	GC_GET_FINISHED_USERS,
	GC_GET_USER_ACTIVITY_BY_ID,
	GC_GET_GAME_RESULTS_BY_USERID,
	GC_GET_AVERAGE_CO_TIME,
	GC_GET_SCENARIO_COMPLETION_BY_USERID,
	GC_INITALIZE_DASHBOARD_DATA,
}
