
<template>
    <div class="relative w-full h-full" :id="'popover-target-' + this.line + '-' + this.column">
        <div v-if="answers && answers.length > 0" class="h-full flex-row flex-wrap flex-center">
            <div class="label flex flex-row flex-center" v-for="(answer, i) in answers" :key="i">
                <div class="text-xs">
                    {{answer.text}}
                </div>
            </div>
        </div>
        <div v-else-if="currentQuestion" class="flex-center h-full text-principal-selected">
            <div>
                Ajouter un label
            </div>
        </div>
        <b-popover :target="'popover-target-' + this.line + '-' + this.column" triggers="blur click" placement="right" ref="popover">
            <template v-slot:title><span class="uppercase text-sm font-principal-bold">Liste de labels</span></template>
            <b-list-group class="label-zone twn-shadow mx-5 mb-5">
                <b-list-group-item button v-for="(label, k) in currentGame.labels" :key="k" class="bg-transparent flex flex-row justify-between" @click="selectLabel(label, k)">
                    <div class="w-2/3 h-full mr-3">
                        {{label.text}}
                    </div>
                    <b-form-checkbox :checked="isSelected(label)"></b-form-checkbox>
                </b-list-group-item>
            </b-list-group>
            <div class="w-full text-center">
                <button type="button" class="twn-button w-2/3" @click="closePopover">Valider</button>
            </div>
        </b-popover>
    </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import vClickOutside from 'v-click-outside';

export default {
    name: 'Question_Label',
    directives: {vClickOutside},
    data(){
        return {
            currentQuestion: null,
            index: -1
        }
    },
    props: ['line', 'column', 'selected'],
    mounted(){
        if (this.line != null && this.column != null){
            let questionData = this.questionByCoord({line:this.line, column:this.column})
            this.currentQuestion = questionData.question;
            this.index = questionData.index;

        }
    },
    computed: {
        ...mapGetters('Games', ['currentGame']),
        ...mapGetters('Games/CellByCell', ['questionByCoord', 'answersByCoord']),
        answers() {
            return this.answersByCoord({line:this.line, column:this.column});
        }
    },
    methods: {
        ...mapMutations('Games', ['updateAnswerText', 'removeAnswer']),
        ...mapActions('Games/CellByCell/DragNDrop', ['addLabelToAnswer']),
        findAnswerIndex(label){
            if (!this.currentQuestion) return -1;
            return this.answers.findIndex(a => a == label)
        },
        isSelected(label){
            return this.findAnswerIndex(label) !== -1 ? true : false;
        },
        selectLabel(label, i){
            let questionData = this.questionByCoord({line:this.line, column:this.column})
            this.index = questionData.index;
            let answerID = this.findAnswerIndex(label);
            if (answerID == -1){
                this.addLabelToAnswer({questionIndex:questionData.index, labelIndex: i});
            } else {
                this.removeAnswer({questionIndex: questionData.index, answerIndex: answerID});
            }
        },
        closePopover() {
            this.$refs.popover.$emit('close')
        }
    }
}
</script>
<style lang="scss">
.cell{
    .label{
        margin: 5px !important;
    }
}
.popover.b-popover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    border: 0;
    .list-group-item{
        @apply pl-1 pr-0;
        min-width:120px;
    }
    .label-zone{
        max-height: 200px;
        overflow-y: scroll;
        overflow-x: hidden;
        box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.1);
    }
    > .popover-header{
        @apply bg-white;
        border-bottom: none;
    }
}
</style>