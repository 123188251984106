import axios from 'axios'
import router from '@/router/index'
import { GC_USER_ID, GC_AUTH_TOKEN } from '@/constants/jwt'
import { GC_USER_INFOS, CG_GET_ORGA } from '@/graphql/auth'
import apollo from '@/apolloClient'

const backendURL = process.env.VUE_APP_WEBHOOK_URL;

export default {
    namespaced: true,
    state: {
        errorMessage: '',
        user: '',
        userInfo: '',
        orga: null,
        loggedIn: !!localStorage.getItem(GC_AUTH_TOKEN)
    },
    getters: {
        getErrorMessage(state){
            return state.errorMessage;
        },
        isLoggedIn(state){
            return state.loggedIn;
        }
    },
    mutations: {
        setUser(state, data){
            if (data.user && data.token){
                state.user = data.user
                localStorage.setItem(GC_AUTH_TOKEN, data.token)
                localStorage.setItem(GC_USER_ID, data.user.id)
                state.loggedIn = true;
            }
        },
        SET_USER_INFO(state, data){
            state.userInfo = data;
        }
    },
    // apollo: {

    // },
    actions: {
        setUser({commit}, data){
            commit('setUser', data)
            router.push({path: '/'})
        },
        login({dispatch, state}, data){
            state.errorMessage = ''
            let user = {
                email: data.email,
                password: data.password
            }
            axios.post(backendURL + '/signin', user)
            .then(res => {
                dispatch('setUser', res.data)
            })
            .catch(err => {
                if (err.response)
                    state.errorMessage = err.response.data.error
            })
        },
        logout({state}){
            localStorage.removeItem(GC_AUTH_TOKEN)
            localStorage.removeItem(GC_USER_ID)
            sessionStorage.clear();
            state.user = '';
            state.loggedIn = false;
            router.push({name: 'auth'}).catch(err => err)
        },
        async getCurrentUserInfo ({state, commit, dispatch }) {
            const currentUserId = localStorage.getItem(GC_USER_ID)
            if (currentUserId && !this.loggedIn) {
              try {

                const response = await apollo.query({
                  query: GC_USER_INFOS,
                  variables: {
                    id: currentUserId
                  }
                })
                commit('SET_USER_INFO', response.data.users[0])

                const orgaRes = await apollo.query({
                    query: CG_GET_ORGA,
                    variables: {
                        id: currentUserId
                    }
                })

                if (orgaRes.data.organisations.length > 0){
                    state.orga = orgaRes.data.organisations[0]
                }

                return dispatch('Utils/initAll', {}, {root:true});
              }
              catch (err) {
                  console.log('err :', err);
                dispatch('logout')
              }
            }
        }
    }
}