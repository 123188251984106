

export default {
    namespaced: true,
    getters: {
        currentGame(state, getters, rootState){
            return rootState.Games.currentGame;
        },
    },
    mutations: {

    },
    actions: {
        updateQCMAnswer({getters, dispatch}, data){
            getters.currentGame.questions[data.questionIndex].answers[data.answerIndex].is_correct = data.value;
            dispatch('commitGame');
        },
        toggleAnswerRemoveState({getters, dispatch}, data){
            let removeState = getters.currentGame.questions[data.questionIndex].answers[data.answerIndex].removeState;
            getters.currentGame.questions[data.questionIndex].answers[data.answerIndex].removeState = removeState ? false : true;
            dispatch('commitGame');
        },
        toggleQuestionRemoveState({getters, dispatch}, index){
            let removeState = getters.currentGame.questions[index].removeState;
            if (!removeState){
                getters.currentGame.questions.forEach(el => el.removeState = false)
            }
            getters.currentGame.questions[index].removeState = removeState ? false : true;
            dispatch('commitGame');
        },
        deactivateQuestionRemoveState({getters, dispatch}, index){
            getters.currentGame.questions[index].removeState = false;
            dispatch('commitGame');
        },
        activateQuestionRemoveState({getters, dispatch}, index){
            getters.currentGame.questions.forEach(el => el.removeState = false)
            getters.currentGame.questions[index].removeState = true;
            dispatch('commitGame');
        },
        commitGame({commit, getters}){
            commit('Games/setGame', getters.currentGame, {root:true})
        }
    },
}