<template>
    <div class="media-container">
        <div class="drop-zone">
            <vue-auto-dropzone ref="dz"
            @processing="process"
            :options="options"
            @error="error"
            @success="success"
            @thumbnail="setThumbnail"
            :include-styling="true"
            v-slot="{ files }"
            class="my-5 twn-dropzone cursor-pointer hover:text-principal-selected">
                <div class="dz-message text-sm ">Ajouter un ou plusieurs fichiers</div>
                <div class="flex w-full justify-around">
                    <figure v-for="file in files" :key="file.upload.uuid">
                        <img v-if="file.dataURL" :src="file.dataURL" :alt="file.name" />
                        <figcaption class="text-principal-gray">
                            <div class="name leading-none overflow" v-b-popover.hover.top="file.name">{{file.name}}</div><br>
                            <span v-if="file.upload.progress !== 100">{{ file.upload.progress.toFixed(0) }}%</span>
                        </figcaption>
                    </figure>
                </div>
                <div class="buttons flex justify-around w-full">
                    <button type="button" class="twn-button text-sm" v-if="files.length > 0" @click="openUploadModal">Confimer</button>
                    <button type="button" class="twn-button danger text-sm" v-if="files.length > 0" @click="$refs.dz.removeAllFiles()">Reset</button>
                </div>
            </vue-auto-dropzone>
        </div>
        <div>
            <div class="intermediary flex flex-row justify-between items-center mb-5">
                <div class="w-full md:w-2/3 flex flex-row items-center pr-2">
                    <label class="mb-0" for="search">
                        <b-icon-search class="h2 my-3 mx-2"></b-icon-search>
                    </label>
                    <input v-model="searchStr" type="text" id="search" class="form-control w-4/5" placeholder="Rechercher un média">
                </div>
                <v-select class="style-chooser text-sm w-1/3 mr-auto"
                    placeholder="Type"
                    label="type"
                    :options="typesList"
                    v-model="filter.item"
                    :reduce="f => f.id"
                >
                    <template v-slot:option="option">
                        <span class="capitalize">{{option.type}}</span>
                    </template>
                    <template v-slot:selected-option="option">
                        <span class="capitalize">{{option.type}}</span>
                    </template>
                </v-select>
                <div class="hidden md:flex flex-row items-center w-1/3 justify-end">
                    <b-icon-list @click="cardView = false" class="h2 cursor-pointer" v-bind:class="[cardView ? '' : 'text-principal-selected']"></b-icon-list>
                    <b-icon-documents @click="cardView = true" class="h2 cursor-pointer" v-bind:class="[cardView ? 'text-principal-selected' : '']"></b-icon-documents>
                </div>
            </div>
            <div class="list">
                <div class="card-list w-full flex-wrap justify-center md:justify-start items-center" v-bind:class="[cardView ? 'flex' : 'hidden']">
                    <div 
                    class="card hover:shadow-twn bg-assets-background relative w-56 h-56 md:w-28 md:h-28 mr-6 mb-6"
                    :class="{ 'cursor-pointer': selectOnly }" 
                    v-for="(asset, i) in filteredAssetList"
                    :key="i"
                    :draggable="draggableAsset"
                    @dragstart="cardDrag($event, asset)"
                    @click="selectMedia(asset, i, $event)">
                        
                        <div class="thumb absolute w-full h-full">
                            <img class="w-full h-full" :src="getThumbnailForAsset(asset)" alt="" draggable="false">
                        </div>

                        <div v-if="!selectOnly" @click="showDeleteModal(asset)" class="cursor-pointer flex justify-center items-center absolute top-0 right-0 w-10 h-10 lg:w-6 lg:h-6">
                            <div class="block lg:hidden"><b-icon-x class="h1 text-red-600 mb-0"></b-icon-x></div>
                            <div class="hidden lg:block"><b-icon-x class="h3 text-red-600 mb-0"></b-icon-x></div>
                        </div>
                        <div class="absolute opacity-75 bottom-0 p-1 w-full description bg-white flex flex-col justify-between rounded-b overflow-hidden">

                            <div class="md:text-xs">{{asset.name}}</div>
                            <div class="md:text-xs w-full text-right absolute bottom-0 bg-white px-2 h-5">{{asset.type.name}}</div>
                        </div>
                    </div>
                </div>
                <div class="b-list" v-bind:class="[cardView ? 'hidden' : 'block']">
                    <b-table
                         class="table_list min-w-0 bootstrap" responsive="lg" borderless
                         :busy="tableBusy"
                         :items="assetList"
                         :fields="fields"
                         :filter="filter"
                         :filter-function="filterTable"
                         :tbody-tr-class="{ 'cursor-pointer': selectOnly }"
                         @row-clicked="selectMedia"
                    >
                    <template v-slot:cell(action)="data">
                        <div v-if="!selectOnly" class="hidden lg:flex flex-row justify-around text-xs show-actions">
                            <button @click="showDeleteModal(data.item)" type="button" class="twn-button danger">Supprimer</button>
                        </div>
                        <div v-if="!selectOnly" v-click-outside="clickOutsideSubmenu" @click="showEditMenu(data.item.id, $event)" class="flex lg:hidden justify-center w-full">
                            <div class="relative flex-row justify-around text-xs leading-tight w-1/4 items-center">
                            • <br>
                            • <br>
                            •
                            <div class="rounded-lg edit-menu shadow-twn absolute flex-col justify-center h-24 bg-white px-3" v-bind:class="[mobile_edit_menu == data.item.id ? 'flex' : 'hidden']">
                                <div @click="showDeleteModal(data.item)" class="flex items-center justify-center px-10 text-principal-selected">Supprimer</div>
                                <div @click="closeSubmenu" class=" cursor-pointer w-6 h-6 mt-1 mr-1 flex justify-center items-center absolute top-0 right-0 text-lg font-principal"><b-icon-x></b-icon-x></div>
                            </div>
                            </div>
                        </div>
                    </template>
                    </b-table>
                </div>
            </div>
        </div>

        <b-modal ref="upload-modal" centered id="upload-modal" hide-footer title="Choissez un type pour chaque media" v-if="$refs.dz">
            <div v-for="(file, i) in $refs.dz.files" :key="i" class="">
                <div class="w-full flex flex-row justify-between my-2">
                    <div class="name w-3/5 pr-4" v-b-popover.hover.top="file.name">{{file.name}}</div>
                    <div class="selectType w-2/5">
                        <v-select
                        :options="typesList"
                        label="type"
                        class="twn-select"
                        v-bind:class="(file.incorrectType === false ? 'is-invalid' : '')"
                        v-model="file.assetType"
                        >
                            
                        </v-select>
                    </div>
                </div>
                <hr class="bg-line-top w-full ">
            </div>
            <div class="flex flex-row justify-evenly items-center">
                <button type="button" class="mt-4 twn-button" @click="confirmTypes">Confirmer</button>
            </div>
        </b-modal>

        <b-modal ref="delete-modal-media" class="bootstrap" centered hide-footer id="delete-modal-media" hide-header>
            <div class="d-block text-center my-6 uppercase font-semibold">
                <h3>Confirmer la suppression</h3>
            </div>
            <div class="flex flex-row justify-evenly items-center">
                <button type="button" class="mt-4 twn-button" @click="$bvModal.hide('delete-modal-media')">Retour</button>
                <button type="button" class="mt-4 twn-button danger" @click="deleteItem">Supprimer</button>
            </div>
        </b-modal>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import VueAutoDropzone from 'vue-auto-dropzone'
import tailwindConf from '../../tailwind.config'
import defaultImage from '@/assets/images/thumbnails/martine.png'

export default {
    name:'Medias',
    props: {
      selectOnly: {
        type: Boolean,
        required: false,
        default: false,
      },
      draggableAsset: {
        type: Boolean,
        required: false,
        default: false,
      },
      defaultTypeFilterSlug: {
          type: String,
          required: false,
          default: null,
      }
    },
    components:{
        VueAutoDropzone
    },
    created() {
        // Todo async function ? + loader ?
        this.getTypesList();
        this.getAssetList();
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    mounted() {
        const dz = this.$refs.dz;
        dz.setOption('accept', this.acceptFile)
        dz.setOption('sending', this.sending)
    },
    
    data() {
        return {
            options: {
                url: this.getUploadUrl,
                method: "PUT",
                parallelUploads: 1,
                uploadMultiple: false,
                header: "",
                chunking: true,
                maxFileSize: 50000,
                autoProcessQueue: false,
                timeout: 0,
            },
            searchStr: '',
            cardView: true,
            tableBusy: false,
            includedFields: ['searchStr', 'filter'],
            selectedID: null,
            mobile_edit_menu: -1,
            filter: {
                placeholder: 'Types',
                item: ''
            },
            fields: [
                {
                    key:'name',
                    label: 'Nom',
                    sortable: true,
                },
                {
                    key: 'type.name',
                    label: 'Type',
                    sortable: true,
                },
                {
                    key:'action',
                    sortable: false,
                    label: ''
                }
            ],
            fileEvents:[],
        }
    },
    watch: {
        typesList(newList, oldList) {
            if (this.defaultTypeFilterSlug && newList.length > 0) {
                // Get the media type instance coresponding to this slug
                for (var i = 0; i < newList.length; i++) {
                    if (newList[i].slug == this.defaultTypeFilterSlug) {
                        this.filter.item = newList[i].id
                    }
                }
            }
        },
        defaultTypeFilterSlug(newType, oldType) {
            if (newType && this.typesList.length > 0) {
                // Get the media type instance coresponding to this slug
                for (var i = 0; i < this.typesList.length; i++) {
                    if (this.typesList[i].slug == this.defaultTypeFilterSlug) {
                        this.filter.item = this.typesList[i].id
                    }
                }
            } else {
                this.filter.item = ''
            }
        }
    },
    computed: {
        ...mapGetters('Assets', ['assetList', 'typesList']),
        filteredAssetList() {
            if (!this.filter.item && !this.searchStr)
                return (this.assetList ? this.assetList : [])
            
            if (!this.filter.item){
                return this.assetList.filter(asset => {
                    return asset.name.includes(this.searchStr)
                })
            } else if (!this.searchStr) {
                return this.assetList.filter(asset => {
                    return asset.type.id.includes(this.filter.item)
                })
            } else {
                return this.assetList.filter(asset => {
                    return (asset.name.includes(this.searchStr) && asset.type.id.includes(this.filter.item))
                })
            }
        }
    },
    methods:{
        ...mapActions('Assets', ['addAsset', 'getAssetList', 'getTypesList', 'getSignedUrl', 'deleteFile', 'setFileThumbnail', 'sendFileToHasura',
        'getAssetFromPath']),
        error(err){
                console.log('err :', err);
        },
        getThumbnailForAsset(asset) {
            if (!asset.thumbnail_url) {
                switch (asset.type.slug) {
                    case 'file_pdf':
                        return require('../assets/images/thumbnails/PDF_THUMBNAIL.svg')
                    case 'file_video':
                        return require('../assets/images/thumbnails/VIDEO_THUMBNAIL.svg')
                    case 'asset_scene':
                        return require('../assets/images/thumbnails/SCENE_THUMBNAIL.svg')
                    
                }
            }

            return asset.thumbnail_url || defaultImage;
        },
        showDeleteModal(asset){
            this.selectedAsset = asset;
            this.$refs['delete-modal-media'].show();
        },
        deleteItem(){
            this.deleteFile(this.selectedAsset)
            .then(res => {
                this.getAssetList();
            })
            this.$refs['delete-modal-media'].hide();
            this.selectedAsset = null;
        },
        success(file){
            this.sendFileToHasura(file).then(res => {
                this.$refs.dz.removeFile(file);
                let returnedData = res.data.insert_media_one;
                let newAsset = {
                    id: returnedData.id,
                    path: returnedData.path,
                    name: returnedData.name,
                    thumbnail_path: file.thumbnail_path,
                    type: {
                        id: file.assetType.id,
                        name: file.assetType.type,
                        slug: file.assetType.slug
                    }
                }
                this.addAsset(newAsset);
            });
        },
        handleResize(){
            if (window.innerWidth <= parseInt(tailwindConf.theme.screens.sm, 10)){
                this.cardView = true;
            }
        },
        processQueue(){
            let acceptedFiles = this.$refs.dz.acceptedFiles
            for (let i = 0; i < acceptedFiles.length; i++) {
                if (acceptedFiles[i] && this.fileEvents[i]){
                    this.fileEvents[i](acceptedFiles[i]);
                }
            }
        },
        sending (file, xhr) {
            let _send = xhr.send
            xhr.send = () => {
                _send.call(xhr, file)
            }
        },
        acceptFile(file, done){
            this.getSignedUrl(file)
            .then((url) => {
                file.uploadURL = url
                done()
                // Manually process each file
                this.fileEvents.push(this.$refs.dz.processFile)
                // setTimeout(() => this.$refs.dz.processFile(file))
            })
            .catch((err) => {
                done('Failed to get an S3 signed upload URL', err)
            })
        },
        getUploadUrl(files) {
            if (files.length <= 0 || files.length > 1)
                return '#'
            return files[0].uploadURL
        },
        filterTable(row){
            if (!this.filter.item && !this.searchStr)
                return true
            
            if (!this.filter.item){
                return row.name.includes(this.searchStr)
            } else if (!this.searchStr) {
                return (row.type.id === this.filter.item)
            } else {
                return (row.type.id === this.filter.item && row.name.includes(this.searchStr))
            }
        },
        process(file){
            this.$refs.dz.setOption('url', file.uploadURL);
            this.options.url = file.uploadURL;
        },
        setThumbnail(file, thumbnail){
            this.setFileThumbnail({file, thumbnail})
            .then(path => {
                file.thumbnail_path = path;
            });
        },
        showEditMenu(id, e){
            e.stopPropagation();
            this.mobile_edit_menu = id;
        },
        clickOutsideSubmenu (event) {
            this.mobile_edit_menu = -1;
        },
        closeSubmenu(e){
            e.stopPropagation();
            this.mobile_edit_menu = -1;
        },
        cardDrag(e, asset) {
            if (!this.draggableAsset) {
                return
            }
            e.dataTransfer.effectAllowed = "copy"
            e.dataTransfer.dropEffect = "copy"
            e.dataTransfer.setData("application/json", JSON.stringify(asset))
        },
        openUploadModal(e){
            let files = this.$refs.dz.queuedFiles;
            this.$refs['upload-modal'].show();
        },
        confirmTypes(e){
            e.preventDefault();
            let allow = true;
            let i = 0
            for (i = 0; i < this.$refs.dz.queuedFiles.length; i++) {
                const file = this.$refs.dz.queuedFiles[i];
                file.incorrectType = false;
                if (!file.assetType){
                    allow = false
                    file.incorrectType = true;
                }
            }
            if (allow && i !== 0){
                this.processQueue();
                this.$refs['upload-modal'].hide();
            }
        },
        selectMedia(item, index, event) {
            this.$emit('select-media', item)
        }
    }
}
</script>
<style lang="scss">

#upload-modal{
    .name{
        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: nowrap;
    }

    .twn-select {
        .vs__dropdown-toggle {
            @apply border-line-top;
        }
        &.is-invalid{
            .vs__dropdown-toggle {
                border-color: #dc3545;
            }
        }
    }
}

.media-container{
    .twn-dropzone{
        @apply border-dashed border-principal-gray rounded border-2;
        &:hover{
            @apply border-principal-selected;
        }
        min-height: 10rem;
        figure{
            width: 10%;
            figcaption{
                @apply break-words text-center;
                .name{
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    @apply text-xs;
                }
            }
        }
    }
    .card {
        @screen md {
            &.md\:w-28, .w-28 {
                width: 7rem !important;
            }

            &.md\:h-28, .h-28 {
                height: 7rem !important;
            }
        }

        .description {
            height: 50%;
        }
    }
    .edit-menu{
        right:0px;
        top: -10px;
    }
}
</style>