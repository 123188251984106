import gql from 'graphql-tag'
import apollo from '@/apolloClient'


function getObject(theObject, nested) {
    var result = [];
    if(theObject instanceof Array) {
        for(var i = 0; i < theObject.length; i++) {
            result.push(getObject(theObject[i], nested));
        }
    }
    else
    {
        for(var prop in theObject) {
            if(prop == nested) {
                return theObject[prop];
            }
            if(theObject[prop] instanceof Object || theObject[prop] instanceof Array)
                result = getObject(theObject[prop], nested);
        }
    }
    return result;
}

export default {
    namespaced: true,
    state: {
        itemList: []
    },
    getters:{
        getItemsList(state){
            return state.itemList;
        }
    },
    mutations: {
        SET_ITEM_LIST(state, list){
            state.itemList = list;
        }
    },
    actions: {
        async listItems({commit}, data){
            let hasuraFields = data.fields.map(f => {
                if (f.db == false) return null
                return f.db !== undefined ? f.db : f.key
            });

            let order = "created_at: desc"
            if(data.orderBy){
                order = data.orderBy
            }

            let fields = data.fields;

            let queryModif = gql`
                query getList {
                    ${data.table}(order_by: { ${order} }) {
                    id
                    ${hasuraFields}
                    }
                }
            `
            const response = await apollo.query({query: queryModif})
            let items = [];
            if (response.data[data.table]){
                items = response.data[data.table].map(element => {
                    fields.forEach(field => {
                        if (element[field.key] && typeof(element[field.key]) === "object" && Object.keys(element[field.key]).length){
                            let result = getObject(element[field.key], field.nested);
                            element[field.key] = result;
                        }

                        if (element[field.key] && Array.isArray(element[field.key])) {
                            element[field.key] = element[field.key].join(', ');
                        }
                    })

                    return element;
                });
            }

            commit('SET_ITEM_LIST', items);
            return this;
        }
    }
}