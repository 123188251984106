
export default {
    namespaced: true,
    getters: {
        currentGame(state, getters, rootState){
            return rootState.Games.currentGame;
        },
    },
    mutations: {
    },
    actions: {
        changeImageType({getters, commit}, value){
            getters.currentGame.imageType = value;
            commit('commitGame')
        },
        dropAsset({getters, rootState, dispatch}, data) {
            let e = data.value;
            let index = data.index;
            if (e.dataTransfer) {
                const assetJSON = e.dataTransfer.getData("application/json")
                const asset = (assetJSON ? JSON.parse(assetJSON) : null)

                if (asset) {
                    getters.currentGame.questions[index].image = asset
                    dispatch('commitGame')
                }
            }
        },
        commitGame({commit, getters}){
            commit('Games/setGame', getters.currentGame, {root:true})
        }
    }
}