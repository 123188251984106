<template>
    <div>
        <div>
            <Toggle :checked="currentGame.imageType" :onInput="changeReorderType" class="mb-5 mr-3"></Toggle>
            <span v-if="!imageState" class="uppercase font-principal-medium mr-3">Texte</span>
            <span v-else class="uppercase font-principal-medium mr-3">Images</span>
        </div>
        <draggable v-model="questionList" v-bind="dragOptions">
            <div v-for="(question, i) in currentGame.questions" :key="i" class="question flex flex-row relative justify-center items-center mb-5" >
                <Question
                :number="i"
                :noAnswer="true"
                :noCorrection="true"
                :imageState="imageState"
                questionPlaceholder="Proposition"
                >
                    <template v-slot:image-content>
                        <div class="w-full m-5 image-content" @click="openMediaChoice" @dragover="allowDrop" @drop.prevent.stop="dropImageInQuestion($event, i)">
                            <div class="drop-zone p-10 w-full h-full border-dashed border-2" v-if="!question.image" >
                                <div class="m-auto w-1/3 break-words text-center text-xs">
                                    Ajouter une image depuis la bibliothèque de média
                                </div>
                            </div>
                            <div v-else class="image m-auto w-1/5 h-2/3">
                                <img :src="question.image.thumbnail_url" alt="" class="">
                            </div>
                        </div>
                    </template>
                </Question>
            </div>
        </draggable>
        <div @click="addQuestion" class="plus m-auto rounded-full w-12 h-12 flex justify-center items-center cursor-pointer text-xl ">+</div>
        <RightPanel ref="rightPanel" title="Choix de média">
            <Medias
            :draggable-asset="true"></Medias>
        </RightPanel>
    </div>
</template>
<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import RightPanel from '@/components/RightPanel'
import Medias from '@/views/Medias'
import draggable from 'vuedraggable'
import Toggle from '@/components/Toggle'
import defaultImage from '@/assets/images/thumbnails/martine.png'
import Question from '@/components/Games/Question_simple.vue'

const default_question = () => {
    console.log("resetq");

    return {
        answers:[],
        text: '',
        gameID: '',
        type: '',
        correction: '',
        removeState: false,
        image: ''
    }
}

const default_game = () => {
    return {
        id: '',
        type: {name: '', id: ''},
        name: '',
        questions: [],
        instruction: '',
        extra_instruction: '',
        imageType: false
    }
}

export default {
    name:'Reorder',
    components: {
        draggable,
        Toggle,
        RightPanel,
        Medias,
        Question
    },
    data(){
        return {
            // default_game: default_game(),
            default_game: default_game,
            imageState: false,
        }
    },
    created(){
        if (this.resetGame) this.setDefaultGame(this.default_game);
        console.log('this.currentGame.questions[0].image :>> ', this.currentGame.questions[0] ? this.currentGame.questions[0].image : null );
        this.setDefaultQuestion(default_question);
    },
    computed: {
        ...mapGetters('Games', ['currentGame', 'resetGame']),
        questionList:{
            get(){
                return this.currentGame.questions;
            },
            set(value){
                this.updateListOrder(value);
            }
        },
        dragOptions() {
            return {
                animation: 180,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        }
    },
    methods: {
        ...mapMutations('Games', [
            'updateListOrder',
            'addQuestion', 
            'updateQuestionText',
            'updateAnswerText',
            'addDefaultAnswer',
            'removeQuestion',
            'updateCorrectionText',
            'setDefaultQuestion'
        ]),
        ...mapActions('Games', ['setDefaultGame']),
        ...mapActions('Games/Reorder/', ['updateQCMAnswer', 'toggleQuestionRemoveState', 'changeImageType',
        'dropAsset']),
        ...mapActions('Assets', ['getSignedUrl']),
        changeQuestionText(e, i){
            this.updateQuestionText({index: i, value:e});
        },
        changeReorderType(e){
            this.imageState = this.imageState ? false : true;
            //TODO: update state
        },
        openMediaChoice(e){
            e.stopPropagation();
            this.$refs['rightPanel'].show();
        },
        dropImageInQuestion(e, i){
            this.dropAsset({value: e, index:i});
            this.$forceUpdate();
        },
        allowDrop:function(event) {
            event.preventDefault();
        }
    }
}
</script>
<style lang="scss">
.question {
    box-shadow: $cardShadow;
    border-radius: 10px;
    overflow: hidden;
    margin-left:0 !important;
    margin-right:0 !important;

    color: $textLight;
    .number{
        background-color: rgba(220, 151, 153, 0.1);
    }

    .rotate-icon{
        transform: rotate(90deg);
    }

    .deleteQuestion{
        @apply bg-principal-selected;
        // border-radius: 0 10px 10px 0;
    }


}
</style>