import { GC_GET_GAME_TYPES, GC_ADD_GAME_ONE, GC_GET_GAME_BY_ID, GC_UPDATE_GAME_BY_ID, GC_DELETE_GAME_BY_ID,
    GC_CHECK_NAME_EXISTENCE, GC_GET_GAME_LIST }from '@/graphql/game';
import apollo from '@/apolloClient';
import router from '@/router/index'
import CellByCell from '@/store/Games/CellByCell/index'
import QCM from '@/store/Games/qcm'
import Reorder from '@/store/Games/reorder'
import Vocabulary from '@/store/Games/vocabulary'
import TexteTrou from '@/store/Games/textetrou'


const default_answer = () => {
    return {
        text: '',
        is_correct: false,
        removeState: false
    }
}

const default_question = () => {
    return {
        answers:[default_answer()],
        text: '',
        column: 0,
        line: 0,
        gameID: '',
        type: '',
        correction: '',
        removeState: false
    }
}

const default_drag_drop_question = (col, line) => {
    return {
        answers:[],
        text: '',
        column: col,
        line: line,
        gameID: '',
        type: '',
        correction: '',
        removeState: false
    }
}

const default_quiz_table_answer = () => {
    return {
        text: 'Default',
        is_correct: false
    }
}


export default {
    namespaced: true,
    state: {
        list: [],
        gameTypes: [],
        resetGame: true,
        currentType: null,
        currentGame: null,
        nameTaken: null,
        default_question: default_question(),
        default_answer: default_answer()
    },
    modules: {
        CellByCell,
        QCM,
        Reorder,
        Vocabulary,
        TexteTrou
    },
    getters: {
        resetGame(state){
            return state.resetGame;
        },
        isNameTaken(state){
            return state.nameTaken == null ? null : false;
        },
        getGameTypes(state){
            return state.gameTypes;
        },
        currentGame(state){
            return state.currentGame;
        },
        currentType(state){
            return state.currentType;
        }
    },
    mutations:{
        SET_GAME_LIST(state, games){
            state.list = (games || [])
        },
        setReset(state, reset){
            state.resetGame = reset;
        },
        SET_GAME_TYPE_LIST(state, gameTypes){
            state.gameTypes = gameTypes
        },
        SET_GAME_TYPE(state, type){
            state.currentGame.type = type;
        },
        SET_DEFAULT_GAME(state, game){
            state.nameTaken = null;
            state.currentGame = (game ? game() : null);
            state.currentGame.type = state.currentType;
        },
        setCurrentType(state, type){
            state.currentType = type;
        },
        SET_GAME(state, game){
            state.nameTaken = null;
            state.currentGame = null;
            state.currentType = game.type;
            let ret = game;
            let table_header_line = ret.headers.map(header => {
                if (header.line != null){
                    return {
                        content:header.content,
                        index: header.line
                    }
                }
            }).filter(e => e);
            let table_header_column = ret.headers.map(header=>{
                if (header.column != null){
                    return {
                        content:header.content,
                        index: header.column
                    }
                }
            }).filter(e => e);
            table_header_column.unshift(null);
            delete ret.headers;
            ret.table_header_line = table_header_line;
            ret.table_header_column = table_header_column;
            if (!ret.labels){
                ret.labels = [];
            }
            ret.questions.forEach(question => {
                question.removeState = false;
                let tmpAnswer = [];
                question.answers.forEach(answer => {
                    if (ret.type.slug == 'drag_drop'){
                        let found = ret.labels.find(l => l.text == answer.text);
                        if (!found){
                            ret.labels.push(answer);
                        } else if (found != answer) {
                            answer = found;
                        }
                    }
                    answer.removeState = false;
                    tmpAnswer.push(answer);
                });
                question.answers = tmpAnswer;
            });

            // Add missing empty/default questions
            if (ret.type.slug == 'drag_drop' && ret.questions.length < ((table_header_column.length - 1) * table_header_line.length)) {
                for (var column = 0; column < table_header_column.length; column++) {
                    if (table_header_column[column] && table_header_column[column].index && table_header_column[column].index > 0) {
                        for (var line = 0; line < table_header_line.length; line++) {
                            const question = ret.questions.findIndex(q => q.line == line && q.column == (column - 1))
                            
                            if (question < 0) {
                                ret.questions.push(default_drag_drop_question(column - 1, line))
                            }
                        }
                    }
                }
            } else if (ret.type.slug == 'quiz_table') {
                for (var col = 0; col < table_header_column.length; col++) {
                    if (table_header_column[col] && table_header_column[col].index && table_header_column[col].index > 0) {
                        for (var ln = 0; ln < table_header_line.length; ln++) {
                            const questionIndex = ret.questions.findIndex(q => q.line == ln && q.column == (col - 1))
                            
                            if (questionIndex >= 0) {
                                if (!ret.questions[questionIndex].answers || ret.questions[questionIndex].answers.length <= 0) {
                                    ret.questions[questionIndex].answers.push(default_quiz_table_answer())
                                }
                            }
                        }
                    }
                }
            }
            state.currentGame = ret;
        },
        updateGameName(state, name){
            state.currentGame.name = name;
        },
        updateGameTitle(state, title){
            state.currentGame.title = title;
        },
        updateInstruction(state, instruction){
            state.currentGame.instruction = instruction;
        },
        updateExtraInstruction(state, instruction){
            state.currentGame.extra_instruction = instruction;
        },
        updateQuestionText(state, data){
            state.currentGame.questions[data.index].text = data.value;
        },
        updateAnswerText(state, data){
            state.currentGame.questions[data.questionIndex].answers[data.answerIndex].text = data.value;
        },
        addDefaultAnswer(state, i){
            state.currentGame.questions[i].answers.push(state.default_answer());
        },
        removeAnswer(state, data){
            state.currentGame.questions[data.questionIndex].answers.splice(data.answerIndex, 1);
        },
        addQuestion(state){
            state.currentGame.questions.push(state.default_question());
        },
        removeQuestion(state, index){
            state.currentGame.questions.splice(index, 1);
        },
        updateListOrder(state, value){
            state.currentGame.questions = value;
        },
        updateCorrectionText(state, data){
            state.currentGame.questions[data.index].correction = data.value;
        },
        setDefaultQuestion(state, question){
            state.default_question = question;
        },
        setDefaultAnswer(state, answer){
            state.default_answer = answer;
        },
        setGame(state, game){
            state.currentGame = game;
        }
    },
    actions: {
        async getList({ commit }) {
            const response = await apollo.query({ query: GC_GET_GAME_LIST })
            commit('SET_GAME_LIST', response.data.game)
        },
        async setGameTypes({commit}){
            const response = await apollo.query({
                query: GC_GET_GAME_TYPES
            })
            commit('SET_GAME_TYPE_LIST', response.data.game_type);
            return response;
        },
        setCurrentGame({commit}, gameTypeKey){
            commit('SET_GAME_TYPE', gameTypeKey);
        },
        async setCurrentGameTypeBySlug({commit}, gameSlug){
            const response = await apollo.query({
                query: GC_GET_GAME_TYPES
            })
            commit('SET_GAME_TYPE_LIST', response.data.game_type);
            
            let gameType = false
            for (var i = 0; i < response.data.game_type.length; i++) {
                let type = response.data.game_type[i]
                if (type.slug == gameSlug) {
                    gameType = response.data.game_type[i]
                }
            }

            if (!gameType) {
                return
            }
            
            commit('SET_GAME_TYPE', {
                id: gameType.id,
                name: gameType.name,
                description: gameType.description,
                slug: gameType.slug
            });
        },
        setDefaultGame({commit}, game){
            commit('SET_DEFAULT_GAME', game);
        },
        async getGameFromID({commit}, id){
            let response = await apollo.query({
                query:GC_GET_GAME_BY_ID,
                variables: {id: id}
            })
            commit('SET_GAME', response.data.game[0]);
        },
        async submitGame({commit, dispatch, state}){
            if (state.currentGame.name){

                let nameIsTaken = await apollo.query({
                    query: GC_CHECK_NAME_EXISTENCE,
                    variables: {name:state.currentGame.name}
                })

                if (nameIsTaken.data.game && nameIsTaken.data.game.length > 0) {
                    const otherGame = nameIsTaken.data.game.find(game => (game.id != state.currentGame.id))
                    state.nameTaken = (otherGame && otherGame.id ? true : null)
                } else {
                    state.nameTaken = null
                }

                if (!state.nameTaken){
                    let response;
                    let variables = await dispatch('buildAddEditVar');
    
                    if (!variables.id){
                        response = await apollo.mutate({
                            mutation: GC_ADD_GAME_ONE,
                            variables: variables
                        })
                    } else {
                        response = await apollo.mutate({
                            mutation: GC_UPDATE_GAME_BY_ID,
                            variables: variables
                        })
                    }
                }
            }
        },
        buildAddEditVar({state}, data){
            let game = state.currentGame;
            let queryVar = {};
            queryVar.name = game.name;
            queryVar.title = game.title;
            queryVar.instruction = game.instruction;
            queryVar.extra_instruction = game.extra_instruction;
            queryVar.gameType = game.type.id;
            queryVar.questions = game.questions.map(question => {
                let answers = question.answers.map(answer => {
                    return {text: answer.text, is_correct: answer.is_correct}
                });
                return {
                    text: question.text,
                    correction: question.correction,
                    column: question.column,
                    line: question.line,
                    answers: {data: answers}
                }
            })
            if (game.type.description == "tableau"){
                let arr = game.table_header_line.map((header, i) => {
                    return {
                        content: header.content,
                        column: null,
                        line: i
                    }
                })
                queryVar.headers = arr.concat(game.table_header_column.map((header, i) => {
                    if (header){
                        return {
                            content: header.content,
                            line: null,
                            column: i
                        }
                    }
                }).filter(e => e))
            } else if (game.type.description == "simple") {
                queryVar.headers = [];
            }
            if(game.id && game.id != ''){
                queryVar.id = game.id;
            }
            return queryVar;
        },
        async deleteGameByID({commit}, id){
            let response = await apollo.mutate({
                mutation: GC_DELETE_GAME_BY_ID,
                variables: {id: id}
            })

            if (response.data.delete_game && response.data.delete_question){
                commit('SET_DEFAULT_GAME');
                return true;
            } else{
                return false;
            }
        }
    }
}