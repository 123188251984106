<template>
    <div class="game-tab flex flex-col">
        <div class="flex flex-col ml-10">
            <div class="column-headers flex flex-row mt-10">
                <div class="origin">
                    <div v-if="currentGame.table_header_column[0] && currentGame.table_header_column[0].image">
                        {{currentGame.table_header_column[0].image}}
                    </div>
                </div>
                <div class="twn-shadow flex flex-row">
                    <div v-for="(headerColumn, j) in currentGame.table_header_column" :key="j" @mouseover="activateHeaderColumnRS(j)" @mouseleave="deactivateHeaderColumnRS(j)">
                        <div class="header-column text-center h-full flex flex-col relative" v-if="j > 0" v-bind:class="[j > 0 && headerColumn.removeState ? 'columnRS' : '']">
                            <div @click="removeColumn(j)" v-if="j > 0 && headerColumn.removeState" class="h-10 text-white removeColumn cursor-pointer flex-center bg-principal-selected w-full">
                                <b-icon-x></b-icon-x>
                            </div>
                            <div class="flex-center number h-10 cursor-pointer" v-else>
                                {{getLetterFromNum(j)}}
                            </div>
                            <div class="h-full p-1 flex-center break-words overflow-hidden" contenteditable="true" @click="activateHeaderColumnRS(j)" @blur="updateColumnHeaderText({index: j, value: $event.target.innerText})" >
                                {{headerColumn.content}}
                            </div>
                        </div>
                    </div>
                </div>
                <div @click="addColumn" class="ml-5 plus m-auto rounded-full w-12 h-12 flex justify-center items-center cursor-pointer text-xl ">+</div>
            </div>
            <div class="flex lines" ref="lines">
                <div class="line-headers twn-shadow self-start">
                    <div class="header-line flex flex-row flex-shrink-0 relative" v-for="(lineHeader, i) in currentGame.table_header_line" :key="i" v-bind:class="[lineHeader.removeState ? 'lineRS' : '']" @mouseover="activateHeaderLineRS(i)" @mouseleave="deactivateHeaderLineRS(i)">
                        <div @click="removeLine(i)" v-if="lineHeader.removeState" class="w-10 text-white removeLine cursor-pointer flex-center bg-principal-selected w-full">
                            <b-icon-x></b-icon-x>
                        </div>
                        <div class="w-10 flex-center number cursor-pointer" v-else>
                            {{i + 1}}
                        </div>
                        <div class="w-full flex-center break-words p-1 overflow-hidden" contenteditable="true" @blur="updateLineHeaderText({index: i, value: $event.target.innerText})" @click="activateHeaderLineRS(i)">
                            {{lineHeader.content}}
                        </div>
                    </div>
                </div>
                <div class="questions flex flex-col twn-shadow-light self-start" @click="disableAllRS">
                    <div v-for="(line, i) in currentGame.table_header_line.length" :key="i" class="flex flex-row cell-parent flex-shrink-0">
                        <div v-for="(column, j) in currentGame.table_header_column.length - 1" :key="j" class="cell" v-bind:class="cellRemoveStateClass(i, j)">
                            <slot v-if="hasSameCell" :i="i" :j="j">
                            </slot>
                            <div v-else @click="openCellChoice" class="w-full h-full">
                                NOTHANG
                            </div>
                            <!--  -->
                        </div>
                    </div>
                </div>
            </div>
            <div @click="addLine" class="mt-5 plus m-auto rounded-full w-12 h-12 flex justify-center items-center cursor-pointer text-xl ">+</div>
        </div>
        <RightPanel ref="rightPanel" title="Cellule configuration">

        </RightPanel>
    </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import RightPanel from '@/components/RightPanel'

/* Data structure */
    const default_table_header_line = () => {
        return {
            content: 'Ligne',
            index: 1,
            removeState: false
        }
    }

    const default_table_header_column = () => {
        return {
            content: 'Colonne',
            index: 1,
            removeState: false
        }
    }

    const default_question = (col, line) => {
        return {
            answers:[],
            text: '',
            column: col,
            line: line,
            gameID: '',
            type: '',
            correction: '',
            removeState: false
        }
    }
    const default_game = () => {
        return {
            id: '',
            type: {name: '', id: ''},
            name: '',
            table_header_line: [default_table_header_line()],
            table_header_column: [null, default_table_header_column()],
            header_visibility: 'both',
            questions: [default_question(0, 0)],
            labels: [],
            instruction: '',
            extra_instruction: '',
        }
    }
/*  */

export default {
    name: 'CellByCell',
    data() {
        return {
            columnRemoveState: -1,
            lineRemoveState: -1,
            hasSameCell: this.sameCell,
            selectedCell: {
                type:'bool'
                
            }
        }
    },
    components: {
        RightPanel
    },
    props: ['sameCell'],
    created(){
        if (!this.sameCell && this.resetGame) {
            this.setDefaultGame(default_game);
        }
        if (!this.sameCell){
            this.setDefaultQuestion(default_question);
        }
    },
    mounted(){
        if (this.lineCount > 5){
            this.$refs['lines'].style.maxHeight = '640px';
            this.$refs['lines'].style.overflowY = 'scroll';
            this.$refs['lines'].style.overflowX = 'visible';
        }
    },
    computed: {
        ...mapGetters('Games', ['currentGame', 'lineCount', 'columnCount', 'labels', 'resetGame']),
        ...mapGetters('Games/CellByCell', ['lineCount', 'columnCount']),
    },
    methods: {
        ...mapActions('Games/CellByCell', ['addLine', 'addColumn', 'removeColumn', 'removeLine', 'updateColumnHeaderText', 'updateLineHeaderText',
        'activateColumnRemoveState', 'disableColumnRemoveState', 'activateLineRemoveState', 'disableLineRemoveState', 'disableAllColumnRemoveState',
        'disableAllLineRemoveState', 'updateQCMAnswer']),
        ...mapMutations('Games', ['setDefaultQuestion']),
        ...mapActions('Games', ['setDefaultGame']),
        getLetterFromNum(num){
            return String.fromCharCode(num + 64);
        },
        activateHeaderColumnRS(index){
            this.columnRemoveState = index - 1;
            this.activateColumnRemoveState(index);
        },
        deactivateHeaderColumnRS(index){
            if (index - 1 == this.columnRemoveState){
                this.columnRemoveState = -1;
                this.disableColumnRemoveState(index);
            }
        },
        activateHeaderLineRS(index){
            this.lineRemoveState = index;
            this.activateLineRemoveState(index);
        },
        deactivateHeaderLineRS(index){
            if (index == this.lineRemoveState){
                this.lineRemoveState = -1;
                this.disableLineRemoveState(index);
            }
        },
        cellRemoveStateClass(i, j){
            if (i == this.lineRemoveState){
                return 'lineRS'
            } else if (j == this.columnRemoveState){
                return 'columnRS';
            } else {
                return '';
            }
        },
        disableAllRS(){
            this.disableAllColumnRemoveState();
            this.disableAllLineRemoveState();
            this.columnRemoveState = -1
            this.lineRemoveState = -1
        },
        openCellChoice(){
            this.$refs['rightPanel'].show();
        }
    },
    watch: {
        lineCount: function(newVal, oldVal){
            if (this.$refs['lines']){
                this.disableAllRS();
                if (newVal > 5){
                    this.$refs['lines'].style.maxHeight = '640px';
                    this.$refs['lines'].style.overflowY = 'scroll';
                    this.$refs['lines'].style.overflowX = 'visible';
                } else {
                    this.$refs['lines'].style.maxHeight = null;
                    this.$refs['lines'].style.overflowY = 'visible';
                }
            }
        },
        columnCount: function(newVal, oldVal){
            this.disableAllRS();
        }
    }
}
</script>
<style lang="scss">
.game-tab{
    width: -moz-fit-content;
    width: fit-content;
    // .b-icon.bi{
    //     vertical-align: 0;
    // }
    .label{
        @apply flex flex-row rounded-full px-3 py-1 m-2;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        .content{
            min-width: 1rem;
            min-height: 1rem;
        }
    }

    .number{
        @apply text-xs text-principal-list-item;
        background-color: rgba(220, 151, 153, 0.1);
    }
    .twn-shadow{
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    }

    .header-input{
        resize: none;
    }

    .twn-shadow-light{
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
    }

    .header-line, .cell-parent, .header-column{
        @apply h-32;
    }

    .headerRS{
        @apply mt-0;
    }

    .header-line, .cell, .header-column{
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.05);

        border-left: 1px solid #f2f1f1;
        border-top: 1px solid #f2f1f1;
    }
    .lines {
        &.scrollable{
            overflow-y: scroll;
        }
        .line-headers{
            @apply w-48 flex flex-col;
        }
    
    }


    //Same width as line-headers
    .origin{
        @apply w-48 h-32;
    }

    .cell{
        @apply w-40 flex justify-center items-center flex-wrap;

        &:hover{
            box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.1);
        }
    }
    .columnRS{
        border-left: 2px solid $redDelete;
        border-right: 2px solid $redDelete;
    }
    .lineRS{
        border-top: 2px solid $redDelete;
        border-bottom: 2px solid $redDelete;
    }

    .header-column{
        @apply w-40;
    }

    .removeColumn{
        top: -2.5rem;
        margin-left: -1%;
        width: 102%;
    }

    .removeLine{
        left: -2.5rem;
        margin-top: -1%;
        height: 103%;
    }

        /* The switch - the box around the slider */
    .switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 24px;
        margin-bottom:0;
    }

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider {
        @apply bg-principal-selected;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 24px;
    }

    .slider.round:before {
        border-radius: 50%;
    } 
}
</style>