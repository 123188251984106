import {ApolloClient} from 'apollo-client'
import router from '@/router/index'
import store from '@/store/index'
import 'isomorphic-unfetch'
import { GC_AUTH_TOKEN } from '@/constants/jwt'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";


// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: process.env.VUE_APP_BACKEND_URL,
})

const middlewareLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem(GC_AUTH_TOKEN)
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : ''
    }
  })
  return forward(operation);
});

const errorLink = onError( err => {
  console.log('err', err)
  if (err.response && err.response.errors && err.response.errors[0].extensions.code == "access-denied") {
    const token = localStorage.getItem(GC_AUTH_TOKEN)
    if (token) {
      const decodedToken = parseJwt(token)
      var current_time = Date.now().valueOf() / 1000;
      if (decodedToken.exp < current_time) {
        /* expired */
        store.dispatch('Auth/logout')
        router.push('/auth')
      }
    }
  }
});

const link = new ApolloLink.from([
  errorLink,
  middlewareLink,
  httpLink
])

const cache = new InMemoryCache()

const client = new ApolloClient({
  // uri: 'https://cooc-admins.walkingnerds.dev/hasura/v1/graphql',
  link: link,
  cache
})

client.defaultOptions = {
  query: {
    fetchPolicy: 'no-cache'
  }
}

function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  }

export default client