import { Node } from 'tiptap'
import { wrappingInputRule, toggleWrap, updateMark } from 'tiptap-commands'
import { mapMutations, mapGetters, mapState, mapActions } from 'vuex'

export default class Hole extends Node {

  get name() {
    return 'hole'
  }

  get schema() {
    return {
    attrs: {
            holeid: {
                default: null,
            },
            questionIndex: {
                default:null
            }
        },
        inline: true,
        group: 'inline',
        selectable: true,
        parseDOM: [{
        tag: 'span',
        getAttrs: dom => ({
            holeid: dom.getAttribute('holeid'),
            questionIndex: dom.getAttribute('questionIndex')
            }),
        }],
        toDOM: node => ['span', node.attrs],
    }
  }

  commands({ type }) {
    return attrs => (state, dispatch) => {
      const { selection } = state
      const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos
      const node = type.create(attrs)
      const transaction = state.tr.insert(position, node)
      dispatch(transaction)
    }
  }

  get view() {
    return {
      data(){
        return {
          randomID: null
        }
      },
      created(){
        this.randomID = Math.random().toString(36).substr(2, 9);
      },
      props: ['node', 'updateAttrs'],
      beforeDestroy(){
          if (this.holeid !== null && this.questionIndex !== null){
            if (this.currentGame.questions[this.questionIndex]){
              this.removeHole({answerIndex:this.holeid - 1, questionIndex:this.questionIndex, randomID:this.randomID})
            }
          }
      },
      mounted(){
        if (this.holeid){
          this.addNewHole({questionIndex:this.questionIndex, answerIndex: this.holeid - 1, randomID:this.randomID});
        }
      },
      computed: {
          ...mapGetters('Games', ['currentGame']),
        holeid: {
          get() {
            return this.node.attrs.holeid
          },
          set(val) {
            this.updateAttrs({
              val,
            })
          },
        },
        questionIndex: {
            get() {
              return this.node.attrs.questionIndex
            },
            set(questionIndex) {
              this.updateAttrs({
                questionIndex,
              })
            },
          },
          currentAnswerText(){
            // if (this.currentGame.questions[this.questionIndex])
            //   return this.currentGame.questions[this.questionIndex].answers[this.holeid - 1].text;
            return '';
          }
      },
      methods:{
        ...mapMutations('Games', ['removeAnswer']),
        ...mapActions('Games/TexteTrou', ['removeHole', 'addNewHole'])
      },
      template: `<mark class="hole number" :data-id="holeid" :holeid="holeid" :questionIndex="questionIndex" v-b-popover.hover.top="currentAnswerText" >{{holeid}}</mark>`,
    }
  }

}