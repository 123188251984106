import { GC_GET_SCENARIO_TYPE_LIST, GC_GET_SCENARIO_LIST, GC_GET_SCENARIO_BY_ID, GC_ADD_SCENARIO_ONE, GC_UPDATE_SCENARIO_BY_ID, GC_ADD_NODE_TRIGGERS } from '@/graphql/scenario'
import apollo from '@/apolloClient'

const formatScenarioData = (oldDbData, data, nodeExtContent, types) => {
    let formattedData = {
        scenario: {
            name: data.name,
            description: data.description,
        }
    }

    if (data.id) {
        formattedData.scenario.id = data.id
    }

    // Format scenario type
    if (data.type && data.type.id) {
        formattedData.scenario.scenario_type_id = data.type.id
    } else {
        formattedData.scenario.scenario_type_id = types.scenario_type_by_slug.scenario.id
    }

    // Format scenario locations
    if (data.location && data.location.id) {
        if (data.id) {
            formattedData.scenario.locations_ids = []
            formattedData.scenario.new_locations = []

            if (oldDbData && oldDbData.scenarioLocations && oldDbData.scenarioLocations.length > 0) {
                // Current/New location
                formattedData.scenario.locations_ids.push(data.location.id)

                // Check if it's a new location
                if (oldDbData.scenarioLocations[0].location_id != data.location.id) {
                    formattedData.scenario.new_locations.push({
                        location_id: data.location.id,
                        scenario_id: data.id
                    })
                }
            } else {
                formattedData.scenario.new_locations.push({
                    location_id: data.location.id,
                    scenario_id: data.id
                })
            }
        } else {
            formattedData.scenario.locations = [{
                location_id: data.location.id,
            }]  
        }
    }

    // Format nodes
    if (data.nodes) {
        formattedData.scenario.nodes = []
        formattedData.triggers = []
        
        // Associative array between Rete node IDs and their Graph QL counterpart index (usefull when creating triggers afterward)
        formattedData.nodes_index = {}
        
        // Format each node from Rete.js format to Graph QL format
        const keys = Object.keys(data.nodes)

        for (var i = 0; i < keys.length; i++) {
            const reteNode = data.nodes[keys[i]]

            let node = {
                node_content: null,
                node_type: (types.node_type_by_slug[reteNode.name].id || null),
                position_x: reteNode.position[0],
                position_y: reteNode.position[1],
            }

            // Set scenario id if updating
            if (data.id) {
                node.scenario_id = data.id
            }

            // Parse node data
            if (reteNode.data) {
                // Get title if needed
                if (reteNode.data.title) {
                    node.title = reteNode.data.title
                }

                // Get content id from node data
                switch (reteNode.name) {
                    case 'scene':
                        node.node_content = reteNode.data.scene
                        break
                    case 'game':
                        node.node_content = reteNode.data.game
                        break
                    case 'pdf':
                        node.node_content = reteNode.data.pdf
                        break
                    case 'media':
                        node.node_content = reteNode.data.media
                        break
                    case 'simple_proposal':
                        if (nodeExtContent[reteNode.id] && nodeExtContent[reteNode.id].simple_proposal) {
                            node.node_content = nodeExtContent[reteNode.id].simple_proposal
                        }
                        break
                    case 'interactive':
                        node.node_content = reteNode.data.interactive
                        break
                    
                }
            }

            formattedData.nodes_index[reteNode.id] = formattedData.scenario.nodes.length
            formattedData.scenario.nodes.push(node)

            // Pre-format node triggers with node outputs
            const outputsKeys = Object.keys(reteNode.outputs)

            for (var j = 0; j < outputsKeys.length; j++) {
                const key = outputsKeys[j]
                const output = reteNode.outputs[key]

                if (output.connections) {
                    // Create a trigger for each connections of this output
                    for (var k = 0; k < output.connections.length; k++) {
                        const connection = output.connections[k]

                        let trigger = {
                            node_id: reteNode.id,
                            triggered_node_id: connection.node
                        }

                        // Get name for button triggers
                        const isButton = (key.substring(0, 3) == 'btn')
                        if (isButton && reteNode.data && reteNode.data.buttons) {
                            trigger.name = (reteNode.data.buttons[key] || null)
                        }

                        // Get name for interactive triggers
                        const isInteractiveTrigger = (key.substring(0, 8) == 'faa_int_')
                        if (isInteractiveTrigger) {
                            trigger.name = key
                        }

                        // Get trigger type
                        if (isButton) {
                            trigger.trigger_type_id = types.trigger_type_by_slug.scene_button.id
                        } else if (isInteractiveTrigger) {
                            trigger.trigger_type_id = types.trigger_type_by_slug.interactive_trigger.id
                        } else {
                            switch (key) {
                                case "next":
                                    trigger.trigger_type_id = types.trigger_type_by_slug.continue.id
                                    break
                                case "success":
                                    trigger.trigger_type_id = types.trigger_type_by_slug.game_success.id
                                    break
                                case "failure":
                                    trigger.trigger_type_id = types.trigger_type_by_slug.game_failure.id
                                    break
                            }
                        }

                        formattedData.triggers.push(trigger)
                    }
                }
            }
        }
    }

    return formattedData
}

export default {
    namespaced: true,
    state: {
        list: [],
        items: {},
        type_list: [],
    },
    mutations: {
        SET_SCENARIO_TYPE_LIST(state, types) {
            state.type_list = types;
        },
        SET_SCENARIO_LIST(state, scenarios) {
            state.list = (scenarios || [])
        },
        SET_SCENARIO(state, scenario) {
            if (!scenario || !scenario.id)
                return

            state.items[scenario.id] = scenario
        },
    },
    actions: {
        async getTypeList({ commit }) {
            const response = await apollo.query({ query: GC_GET_SCENARIO_TYPE_LIST })

            commit('SET_SCENARIO_TYPE_LIST', response.data.scenario_type)
        },
        async getList({ commit }) {
            const response = await apollo.query({ query: GC_GET_SCENARIO_LIST })

            commit('SET_SCENARIO_LIST', response.data.scenario)
        },
        async getByID({ commit }, id) {
            let response = await apollo.query({
                query: GC_GET_SCENARIO_BY_ID,
                variables: { id }
            })

            if (!response.data.scenario || response.data.scenario.length <= 0) {
                commit('SET_SCENARIO', null)
                return
            }

            commit('SET_SCENARIO', response.data.scenario[0])
        },
        async handleNodeExtContent({ dispatch }, { oldDbData, data, types }) {
            // Return a dictionary of: Rete node ID => external content info
            let nodeExtContent = {}

            // Hash map of external content ID => boolean, used to remove old content
            let extContentList = {}

            // Check each rete node
            const keys = Object.keys(data.nodes)

            for (var i = 0; i < keys.length; i++) {
                let reteNode = data.nodes[keys[i]]

                // Parse node data
                if (reteNode.data) {
                    // Handle simple proposal nodes
                    if (reteNode.name == 'simple_proposal') {
                        let proposal = {
                            texte: reteNode.data.text
                        }
                        
                        if (reteNode.data.simple_proposal) {
                            proposal.id = reteNode.data.simple_proposal
                        }

                        const id = await dispatch('Proposals/save', proposal, { root: true })//todo improve by batching add/update request ?

                        // Add to content list
                        extContentList[id] = true

                        // Add node extra data
                        nodeExtContent[reteNode.id] = {
                            simple_proposal: id,
                            text: proposal.texte,
                        }
                    }
                }
            }

            // Check old nodes to delete old content //todo: improve with node db id ?
            const oldKeys = (oldDbData && oldDbData.nodes ? Object.keys(oldDbData.nodes) : [])

            for (var j = 0; j < oldKeys.length; j++) {
                let oldDbNode = oldDbData.nodes[oldKeys[j]]

                // Parse node data
                if (oldDbNode.nodeType) {
                    // Handle simple proposal nodes
                    if (oldDbNode.nodeType.slug == 'simple_proposal') {
                        // Remove proposal, if not in the content list
                        if (oldDbNode.node_content && !extContentList[oldDbNode.node_content]) {
                            await dispatch('Proposals/delete', oldDbNode.node_content, { root: true })
                        }
                    }
                }
            }

            // Update store data
            await dispatch('Proposals/getList', null, { root: true })

            return nodeExtContent
        },
        async save({ commit, state, dispatch, rootState }, data) {
            if (!state.type_list || state.type_list.length <= 0) {
                await dispatch('getTypeList')

                if (state.type_list.length <= 0)
                    return// todo
            }

            let response = null
            let result = {}

            // Format scenario data
            const scenario_type_by_slug = state.type_list.reduce((dict, type) => {
                dict[type.slug] = type

                return dict
            }, {})

            const node_type_by_slug = rootState.Nodes.type_list.reduce((dict, type) => {
                dict[type.slug] = type

                return dict
            }, {})

            const trigger_type_by_slug = rootState.Triggers.type_list.reduce((dict, type) => {
                dict[type.slug] = type

                return dict
            }, {})

            const types = { scenario_type_by_slug, node_type_by_slug, trigger_type_by_slug }

            // Create/Update/Remove node data like new/old external node_content (proposal, etc...)
            result.nodeExtContent = await dispatch('handleNodeExtContent', { oldDbData: state.items[data.id], data, types })

            let gql_data = formatScenarioData(state.items[data.id], data, result.nodeExtContent, types)

            // Update or add the scenario
            if (gql_data.scenario.id) {
                // Update remote data
                response = await apollo.mutate({
                    mutation: GC_UPDATE_SCENARIO_BY_ID,
                    variables: gql_data.scenario
                })

                result.success = true
                result.nodes = response.data.insert_node.returning
            } else {
                // Add remote data
                response = await apollo.mutate({
                    mutation: GC_ADD_SCENARIO_ONE,
                    variables: gql_data.scenario
                })
                
                result.id = response.data.insert_scenario_one.id
                result.nodes = response.data.insert_scenario_one.nodes
            }

            // Create a dictionary of Rete Node ID => GQL Node UUID
            const keys = Object.keys(data.nodes)
            let nodes_uuids = {}

            for (var i = 0; i < keys.length; i++) {
                const reteNode = data.nodes[keys[i]]
                const index = gql_data.nodes_index[reteNode.id]

                nodes_uuids[reteNode.id] = result.nodes[index].id
            }

            // Update node triggers with the real nodes uuids
            for (var j = 0; j < gql_data.triggers.length; j++) {
                gql_data.triggers[j].node_id = nodes_uuids[gql_data.triggers[j].node_id]
                gql_data.triggers[j].triggered_node_id = nodes_uuids[gql_data.triggers[j].triggered_node_id]
            }

            // Add nodes triggers
            response = await apollo.mutate({
                mutation: GC_ADD_NODE_TRIGGERS,
                variables: {
                    triggers: gql_data.triggers,
                }
            })

            // Update store data
            await dispatch('getByID', (gql_data.scenario.id || result.id))

            return result
        }
    }
}