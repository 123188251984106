import router from '@/router/index'
import { GC_GET_USER_N_ORGAS, GC_EDIT_USER, GC_ADD_USER_ONE } from '@/graphql/edit'
import { GC_GET_ORGA_ONE, GC_EDIT_ORGA_ONE, GC_ADD_ORGA_ONE } from '../graphql/edit';
import apollo from '@/apolloClient'

export default {
    namespaced: true,
    state: {
        currentID: -1,
        user: {
            id: '',
            firstName: '',
            name: '',
            email: '',
            organizations: [],
            status: ''
        },
        organization: {
            id: '',
            address: '',
            address2: '',
            post_code: 0,
            city: '',
            name: '',
            country: ''
        },
        organizationList: [],
        countryList: [],
        statusList: [],
        userList: []
    },
    getters:{
        getUser(state){
            return state.user;
        },
        getOrganization(state){
            return state.organization;
        },
        getOrganizationList(state){
            return state.organizationList;
        },
        getStatusList(state){
            return state.statusList;
        },
        getCountryList(state){
            return state.countryList;
        },
        getCurrentID(state){
            return state.currentID;
        },
        getUserList(state){
            return state.userList;
        }
    },
    mutations: {
        SET_USER(state, user){
            let orgaArr = user.userOrganisations.map(orga => orga.organisations.id);
            state.user = {
                id: user.id,
                firstName: user.first_name,
                name: user.name,
                email: user.email,
                organizations: orgaArr,
                seniority: user.seniority,
                status: user.status,
                company: user.company,
                experienced: user.experienced,
                role: user.role
            };
        },
        SET_ORGA(state, orga){
            state.organization = orga;
        },
        SET_ORGA_LIST(state, orgaList){
            state.organizationList = orgaList;
        },
        SET_STATUS_LIST(state, statusList){
            if (statusList && Array.isArray(statusList)){
                let filteredArr = statusList.filter(e => e.experienced !== null);
                state.statusList = filteredArr;
            }
        },
        SET_USER_LIST(state, userList){
            state.userList = userList;
        }
    },
    actions: {
        async initUserEditLists({commit, rootState}){
            commit('SET_ORGA_LIST', rootState['Utils'].organisationNameList);
            commit('SET_STATUS_LIST', rootState['Utils'].statusNameList);
        },
        async initOrgaEditLists({commit, rootState}){
            commit('SET_USER_LIST', rootState['Utils'].userNameList);
        },
        async setUser({commit, state}, userID){
            let id = userID;
            if (!id){
                router.push('/404')
                return;
            }
            state.currentID = id;
            const response = await apollo.query({
                query: GC_GET_USER_N_ORGAS,
                variables: {
                    id: id
                }
            })
            commit('SET_USER', response.data.users[0]);
            commit('SET_ORGA_LIST', response.data.organisations_aggregate.nodes);
            commit('SET_STATUS_LIST', response.data.users_aggregate.nodes);
        },
        async addUser({commit, dispatch}, userInfo){
            let orgaArr = userInfo.organizations.map(id => {
                return {
                    organisation: id
                }
            })
            let response = await apollo.mutate({
                mutation: GC_ADD_USER_ONE,
                variables: {
                    email: userInfo.email,
                    fName: userInfo.firstName,
                    lName: userInfo.name,
                    experienced: userInfo.experienced,
                    orgas: orgaArr,
                    seniority: userInfo.seniority,
                    status: userInfo.status,
                    company: userInfo.company,
                    role: userInfo.role
                }
            })
            dispatch('setUser', response.data.insert_users_one.id);
        },
        async editUser({dispatch}, userInfo){
            let orgaArr = userInfo.organizations.map(id => {
                return {
                    user: userInfo.id,
                    organisation: id
                }
            })

            for (const key in userInfo) {
                if (Object.prototype.hasOwnProperty.call(userInfo, key)) {
                    if (userInfo[key] && typeof(userInfo[key]) == 'string'){
                        userInfo[key] = userInfo[key].trim()
                    }
                }
            }

            await apollo.mutate({
                mutation: GC_EDIT_USER,
                variables: {
                    email: userInfo.email,
                    fName: userInfo.firstName,
                    lName: userInfo.name,
                    status: userInfo.status,
                    seniority: userInfo.seniority,
                    company: userInfo.company,
                    id: userInfo.id,
                    experienced: userInfo.experienced,
                    orgas: orgaArr,
                    role: userInfo.role
                }
            })
            dispatch('setUser', userInfo.id);
        },
        async setOrganization({commit, state}, organizationID){
            let id = organizationID;
            if (!id){
                router.push('/404')
                return;
            }
            state.currentID = id;
            const response = await apollo.query({
                query: GC_GET_ORGA_ONE,
                variables: {
                    id: id
                }
            })
            commit('SET_ORGA', response.data.organisations_by_pk);
        },
        async editOrganization({dispatch}, orgaInfo){
            delete orgaInfo.__typename;
            if (orgaInfo.userOrganisations){
                delete orgaInfo.userOrganisations
            }
            await apollo.mutate({
                mutation: GC_EDIT_ORGA_ONE,
                variables: orgaInfo
            })
            dispatch('setOrganization', orgaInfo.id);
        },
        async addOrganization({dispatch}, orgaInfo){
            delete orgaInfo.__typename;
            let response = await apollo.mutate({
                mutation: GC_ADD_ORGA_ONE,
                variables: orgaInfo
            })
            dispatch('setOrganization', response.data.insert_organisations_one.id);
        },
        clearCurrentID({state}){
            state.currentID = -1;
        }
    }
}