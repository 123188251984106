<template>
    <div>
        <div class="flex flex-row relative justify-center items-center mb-5" >
            <div class="flex items-center flex-1 flex-col m-5 ml-10 mr-10">
                <div class="top-block w-full" >
                    <span class="font-principal-bold text-sm uppercase">Texte</span>
                    <div class="editor border-line-top rounded-sm" >
                        <editor-content class="editor__content" :editor="editor" />
                    </div>
                </div>
                <div class="bottom-block w-full flex flex-row">
                    <b-table-lite class="table w-11/12 mt-5" borderless :items="currentGame.questions" :fields="fields" v-if="currentGame.questions.length != 0">
                        <template v-slot:cell(index)="data">
                            <div class="bg-principal-selected mt-1 number rounded-full w-5 h-5 flex-center text-xs">
                                {{ data.index + 1 }}
                            </div>
                        </template>
                        <template v-slot:cell(text)="data" >
                            <input class="words border-line-top rounded-sm" type="text" :value="data.item.text" @input="updateQuestionText({questionIndex:number, answerIndex:data.index, value:$event.target.value})">
                        </template>
                    <template v-slot:cell(authorized_values)="" >
                            <v-select class="style-chooser w-full text-xs"></v-select>
                        </template>

                    </b-table-lite>
                    <editor-menu-bar :editor="editor" v-slot="{ commands }">
                        <div @click="addQuestion(commands.hole)" class="plus my-auto ml-auto rounded-full w-10 h-10 flex justify-center items-center cursor-pointer">+</div>
                    </editor-menu-bar>
                </div>
            </div>
        </div>
        <div @click="addQuestion" class="plus m-auto rounded-full w-12 h-12 flex justify-center items-center cursor-pointer text-xl ">+</div>
    </div>
</template>
<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import Hole from '@/tiptap/Hole.js'
import {mapGetters, mapActions, mapMutations} from 'vuex'

const default_question = () => {
    return {
        answers:[],
        text: '',
        removeState: false,
        authorized_values: [],
        holeid:'',
    }
}

const default_game = () => {
    return {
        id: '',
        type: {name: '', id: ''},
        name: '',
        text: '',
        questions: [default_question()],
        instruction: '',
        extra_instruction: '',
        holes: []
    }
}


export default {
    name:'TexteTrou',
    components: {
        EditorContent,
        EditorMenuBar
    },
    created(){
        if (this.resetGame) this.setDefaultGame(default_game);
        this.setDefaultQuestion(default_question);
    },
    beforeDestroy() {
        this.editor.destroy()
    },
    mounted(){
        let content = this.currentGame.text;
        this.editor = new Editor({
            extensions: [
            // custom extension
                new Hole(),
            ],
            onBlur: this.updateText,
            onUpdate:this.checkForDeleteAnswer
        })
        this.editor.setContent(content)
    },
    data() {
        return {
            editor: null,
            fields:[
                {
                    key: 'index',
                    label: '',
                    sortable: false,
                },
                {
                    key:'text',
                    label: 'Mots',
                    sortable: false,
                },
                {
                    key: 'authorized_values',
                    label: 'Valeurs acceptées',
                    sortable: false,
                },
            ],
       }
    },
    computed:{
        ...mapGetters('Games', ['currentGame', 'resetGame']),
        questionList:{
            get(){
                return this.currentGame.questions;
            },
            set(value){
                this.updateListOrder(value);
            }
        },
        dragOptions() {
            return {
                animation: 180,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
                handle: '.handle'
            };
        }
    },
    methods: {
        ...mapActions('Games', ['setDefaultGame']),
        ...mapMutations('Games', [
            'updateListOrder',
            'addQuestion',
            'updateQuestionText',
            'updateAnswerText',
            'addDefaultAnswer',
            'removeQuestion',
            'updateCorrectionText',
            'setDefaultQuestion',
            'setDefaultAnswer'
        ]),
        ...mapActions('Games/TexteTrou', ['registerEditorJSON']),
        addAnswer(command){
            this.addDefaultAnswer(this.number);
            let nb = this.currentGame.answers.length;
            command({ holeid:nb, questionIndex: this.number})
        },
        checkForDeleteAnswer(e){
            this.registerEditorJSON(this.editor.getJSON());
        },
        updateText(e){
            if (this.currentGame)
                this.updateQuestionText({index: this.number, value: e.event.target.innerHTML})
        }
    }
}
</script>
