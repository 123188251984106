<template>
  <div class="node" :class="[selected(), node.name] | kebab"
  :title="(node && node.data && node.data.title ? node.data.title : title)"
  @pointerup="pointerUp">
    <div class="cross-container text-right">
      <div class="cross cursor-pointer" @pointerdown.stop="removeNode">
        <img src="@/assets/images/NODE_CROSS.svg">
      </div>
    </div>

    <div class="content bg-white">
      <div class="title">{{title}}</div>
      <div class="description">{{description}}</div>

      <!-- Inputs-->
      <div class="input" v-for="input in inputs()" :key="input.key" :class="{ connected: input.hasConnection() }">
        <Socket v-socket:input="input" type="input" :socket="input.socket"></Socket>
        <div class="input-title" v-show="!input.showControl() && input.name">{{input.name}}</div>
        <div class="input-control" v-show="input.showControl()" v-control="input.control"></div>
      </div>

      <!-- Controls-->
      <div class="control" v-for="control in controls()" :key="node.id + control.key" v-control="control" @content-update="controlContentUpdate(control)"></div>

      <!-- Outputs-->
      <div class="outputs" v-for="output in outputs()" :key="output.key" :class="{ connected: output.hasConnection() }">
        <div v-if="output.isButton" class="output-title output-button">
          <div v-if="output.isEditing">
            <input
            type="text"
            v-model="output.newName"
            v-focus
            @pointerdown.stop
            @keyup.enter="saveButton(output)"
            @keyup.esc="cancelButtonEditing(output)"
            >
              <span class="button-save-edit" @pointerdown.stop="saveButton(output)">
                <img src="@/assets/images/CONFIRM.svg">
              </span>
              <span class="button-cancel-edit" @pointerdown.stop="cancelButtonEditing(output)">
                <img src="@/assets/images/CANCEL.svg">
              </span>
            </div>
            <div v-if="!output.isEditing">
              <span class="button-name" @pointerdown.stop="editButton(output)">{{output.name}}</span>
              <span class="button-edit" @pointerdown.stop="editButton(output)">
                <img src="@/assets/images/EDIT.svg">
              </span>
              <span class="button-delete" @pointerdown.stop="deleteButton(output)">
                <img src="@/assets/images/DELETE.svg">
              </span>
            </div>
          </div>
          <div v-else class="output-title">{{output.name}}</div>
          <Socket v-socket:output="output" type="output" :socket="output.socket"></Socket>
        </div>

        <div class="button" v-if="node.hasButtons">
          <button :disabled="isEditing" @click="addButton">
            <img src="@/assets/images/ADD_BUTTON.svg">
            Ajouter un bouton
          </button>
        </div>
    </div>
    
  </div>
</template>

<script>
// Extends default Node component to be able to apply a custom style and add description for each node type
import Vue from 'vue'
import VueRender from 'rete-vue-render-plugin';
import Rete from 'rete';
import CustomSocket from './CustomSocket.vue';

import * as Socket from '../sockets';

export default {
  extends: VueRender.Node,
  mixins: [VueRender.mixin],
  components: {
    Socket: CustomSocket
  },
  directives: {
    focus: {
      inserted: function (el) {
        // setTimeout instead of nextTick because nextTick seems to fire too early(... & ???)
        setTimeout(() => {
          el.focus()
        }, 100)
      },
    }
  },
  props: {
    title: String,
    description: String,
    controlContentUpdated: Function,
  },
  data() {
    return {
      isEditing: false,
      buttonsCount: 0,
    };
  },
  created() {
    if (this.node.data.buttons) { //todo move to Scenecomponent.js ? (via custom triggers data ? see: interactivecomponent.js)
      const keys = Object.keys(this.node.data.buttons)
      var buttons = {}

      for (var i = 0; i < keys.length; i++) {
        this.buttonsCount += 1

        const buttonKey = 'btn' + this.buttonsCount

        buttons[buttonKey] = this.node.data.buttons[keys[i]]

        const output = new Rete.Output(buttonKey, buttons[buttonKey], Socket.trigger, false)
        output.isButton = true

        this.node.addOutput(output)
      }
    }
  },
  methods: {
    pointerUp(event) {
      // Handle node 'selection' for the customm 'connectiondrop' event
      if (event.target.className.indexOf('socket ') > -1) {
        this.editor.custom_selected_node = null
      } else {
        this.editor.custom_selected_node = this.node
      }
    },
    updateNodeView(force = false) {
      if (force)
        this.$forceUpdate()

      // Update display of all connections of this node
      this.$nextTick(() => {
        this.editor.view.updateConnections({node: this.node})
      })
    },
    async controlContentUpdate(control) {
      let force = false

      // Call custom method if specified by the node type, this method can be used to update the node inputs, outputs, ... before updating the node view
      if (this.controlContentUpdated) {
        force = await this.controlContentUpdated(control, this.node, this.editor)
      }

      // Update node view
      this.updateNodeView(force)
    },
    addButton() {
      if (this.isEditing)
        return

      this.isEditing = true

      this.buttonsCount += 1

      const buttonKey = 'btn' + this.buttonsCount

      if (!this.node.data.buttons) {
        this.node.data.buttons = {}
      }
      this.node.data.buttons[buttonKey] = 'Bouton #' + this.buttonsCount
      
      const output = new Rete.Output(buttonKey, this.node.data.buttons[buttonKey], Socket.trigger, false)
      output.isButton = true

      this.node.addOutput(output)

      this.isEditing = false
      
      this.updateNodeView(true)
    },
    editButton(output) {
      output.newName = output.name

      this.updateButtonEditing(output, true)
    },
    saveButton(output) {
      output.name = output.newName

      this.node.data.buttons[output.key] = output.name

      this.updateButtonEditing(output, false)
    },
    cancelButtonEditing(output) {
      this.updateButtonEditing(output, false)
    },
    updateButtonEditing(output, startEditing) {
      if (this.isEditing)
        return

      this.isEditing = true

      output.isEditing = startEditing

      this.isEditing = false
    },
    deleteButton(output) {
      if (this.isEditing)
        return

      this.isEditing = true

      // Remove outputs connections (must be done via editor.removeConnection because it's not done in removeOutput...)
      output.connections.slice().map(this.editor.removeConnection.bind(this.editor));

      // Remove output
      this.node.removeOutput(output)

      delete this.node.data.buttons[output.key]

      this.isEditing = false

      this.updateNodeView(true)
    },
    removeNode() {
      this.editor.removeNode(this.node)
    }
  },
}
</script>

<style lang="scss" scoped>
$node-color: #FFF;
$node-color-selected: #ffd92c;
$group-color: rgba(15,80,255,0.2);
$group-handler-size: 40px;
$group-handler-offset: -10px;
$socket-size: 24px;
$socket-margin: 6px;
$socket-color: #dc9799;
$node-width: 320px;

.node {
  background: $node-color;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  cursor: grab;
  min-width: $node-width;
  height: auto;
  box-sizing: content-box;
  position: relative;
  user-select: none;
  padding: 10px 0 22px 0;

  &:hover {;
    background-color: lighten($node-color,4%);
  }
  
  &:active {
    cursor: grabbing;
  }

  .title {
    padding: 0 22px;
    margin-bottom: 4px;
    @apply text-xs uppercase font-principal-bold;
  }

  .description {
    color: $textLight;
    padding: 0 22px;
    @apply text-xs;
  }

  .outputs {
    text-align: left;
    border-radius: 5px;
    border: solid 1px #e1e1e1;
    padding: 0 11px;
    margin-left: 22px;
    margin-top: 14px;
    margin-bottom: 14px;
    margin-right: - $socket-size;
    background: white;
  }

  .output-title {
    width: calc(100% - #{$socket-size + 2*$socket-margin});
  }

  .output-button {
    white-space: nowrap;
    
    span {
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
      margin-bottom: 4px;

      img {
        display: inline-block;
        vertical-align: middle;
        height: 16px;
        color: red;
      }

      &:hover {
        img {
          opacity: 1;
        }
      }
    }

    .button-name {
      margin: 0;
      height: 26px;
      line-height: 26px;
    }

    input {
      padding: 0 10px;
      border: 1px solid #E1E1E1;
      border-radius: 0.275rem;
    }

    .button-name, .button-edit, .button-delete {
      cursor: pointer;
    }

    .button-save-edit, .button-cancel-edit {
      cursor: pointer;
      margin-left: 8px;
      opacity: 0.6;

      &:hover {
        opacity: 1;
      }

      img {
        height: 12px;
      }
    }
  }

  .input {
    .socket {
      position: absolute;
      top: calc(50% - #{$socket-size/2});
      left: 0;
    }
  }

  .input-title, .output-title {
    @apply text-sm;
    vertical-align: middle;
    display: inline-block;
    margin: $socket-margin;
    line-height: $socket-size;
  }

  .input-control {
    z-index: 1;
    width: calc(100% - #{$socket-size + 2*$socket-margin});
    vertical-align: middle;
    display: inline-block;
  }

  .control, .button {
    @apply text-sm;
    color: $textLight;
    padding: 0 22px;
  }

  .control {
    margin-top: 14px;
    margin-bottom: 14px;
  }

  .button {
    img {
      display: inline-block;
      margin-right: 14px;
    }

    button {
      height: 26px;
    }
  }

  .cross-container {
    line-height: 14px;
  }

  .cross {
    display: inline-block;
    margin-right: 10px;
  }

  .connected {
    .socket {
      background: $socket-color;
    }
  }
}
</style>
