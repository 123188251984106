import { GC_GET_MEDIA_LIST } from '@/graphql/media'
import apollo from '@/apolloClient'

export default {
    namespaced: true,
    state: {
        list: [],
    },
    mutations: {
        SET_MEDIA_LIST(state, locations) {
            state.list = locations;
        }
    },
    actions: {
        async getList({ commit }) {
            const response = await apollo.query({ query: GC_GET_MEDIA_LIST })

            commit('SET_MEDIA_LIST', response.data.media)
        }
    }
}