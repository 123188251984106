
export default {
    namespaced: true,
    state:{
        editorJSON: null,
        ids: []
    },
    getters: {
        currentGame(state, getters, rootState){
            return rootState.Games.currentGame;
        },
    },
    mutations: {
    },
    actions: {
        addNewHole({getters, state, rootState}, data){
            if (getters.currentGame){
                rootState.Games.currentGame.questions[data.questionIndex].answers[data.answerIndex].id = data.randomID
            }
        },
        registerEditorJSON({state}, editorJSON){
            state.editorJSON = editorJSON;
        },
        removeHole({state, getters, commit}, data){
            if(getters.currentGame && getters.currentGame.questions[data.questionIndex]){
                let answer = getters.currentGame.questions[data.questionIndex].answers[data.answerIndex];
                if (answer && answer.id === data.randomID){
                    commit('Games/removeAnswer', {questionIndex: data.questionIndex, answerIndex: data.answerIndex}, {root:true})
                    state.editorJSON.content.forEach((line) => {
                        if (line.content){
                            line.content.forEach(el => {
                                if (el.type === "hole" && el.attrs.holeid > data.answerIndex + 1){
                                    el.attrs.holeid--;
                                }
                            })
                        }
                    })
                }

            }
        }
    }
}