import 'regenerator-runtime';
import Rete from "rete";

import VueRenderPlugin from "rete-vue-render-plugin";
import ConnectionPlugin from "rete-connection-plugin";
import AreaPlugin from "rete-area-plugin";
import DockPlugin from 'rete-dock-plugin';

// Common
import { ColorComponent } from "./components/ColorComponent";// todo: cleanup
import { AddComponent } from "./components/AddComponent";// todo: cleanup
import { SceneComponent } from "./components/SceneComponent";
import { MediaComponent } from "./components/MediaComponent";
import { PDFComponent } from "./components/PDFComponent";
import { GameComponent } from "./components/GameComponent";

// Admin
import { SimpleProposalComponent } from "./components/SimpleProposalComponent";
import { InteractiveComponent } from "./components/InteractiveComponent";

// RS
import { VideoPortionComponent } from "./components/VideoPortionComponent";
import { ProposalComponent } from "./components/ProposalComponent";
import { SubtitlesComponent } from "./components/SubtitlesComponent";
import { PhoneMessageComponent } from "./components/PhoneMessageComponent";
import { VideoCallComponent } from "./components/VideoCallComponent";
import { ActionsComponent } from "./components/ActionsComponent";

import { TextControl } from "./controls/TextControl.js";// todo: cleanup

import * as Socket from "./sockets";// todo: cleanup

import data from "./data.json";// todo: cleanup

// Rete component class associated to node type slug
const NODE_TYPE_SLUG_CLASS = {
  // Common
  scene: SceneComponent,
  media: MediaComponent,
  pdf: PDFComponent,
  game: GameComponent,
  // Admin
  simple_proposal: SimpleProposalComponent,
  interactive: InteractiveComponent,
  // RS
  video_portion: VideoPortionComponent,
  proposal: ProposalComponent,
  subtitles: SubtitlesComponent,
  phone_message: PhoneMessageComponent,
  video_call: VideoCallComponent,
  actions: ActionsComponent,
}

export default async function(container, dock_container, node_types = []) {
  // Init Rete in the specified container and setup plugins
  var editor = new Rete.NodeEditor("ScenarioEditor@1.0.0", container);

  editor.use(ConnectionPlugin);
  editor.use(VueRenderPlugin);
  
  editor.use(AreaPlugin, {
    scaleExtent: { min: 0.25, max: 1 }
  });

  editor.use(DockPlugin, {
    container: dock_container,
    itemClass: 'item',
    plugins: [VueRenderPlugin],
  });

  // Init and register node type components
  const components = node_types.map((type) => {
    if (!type || !type.slug || !NODE_TYPE_SLUG_CLASS[type.slug])
      return null

    return (new NODE_TYPE_SLUG_CLASS[type.slug](type))
  }).filter((component) => (component !== null))

  components.map(c => {
    editor.register(c);
  });

  // Init editor area
  AreaPlugin.zoomAt(editor);

  return editor
}
