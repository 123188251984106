<template>
    <div class="right-pane overflow-auto" v-show="isShow">
        <div class="mt-1 mb-6">
            <span class="title">
                {{title}}
            </span>
            <div class="float-right cursor-pointer pt-1"
            @pointerdown.stop="hide">
                <img src="@/assets/images/NODE_CROSS.svg">
            </div>
        </div>
        <slot></slot>
    </div>
</template>
<script>


export default {
    name: 'RightPanel',
    data(){
        return {
            isShow: false,
        }
    },
    props: {
      title: {
          type: String,
          required: false,
          default: null,
      },
    },
    methods:{
        show(){
            this.isShow = true;
            this.$emit('show')
        },
        hide(){
            this.isShow = false;
            this.$emit('hide')
        }
    }
}
</script>
<style lang="scss">
.right-pane{
    @apply absolute bg-white right-0 bottom-0 z-10 w-2/5 p-6;
    .title {
        @apply text-xs uppercase font-principal-bold;
    }
}
</style>