import Rete from "rete";
import VueTextControl from "./TextControl.vue";

export class TextControl extends Rete.Control {
  constructor(key, label, readonly, multiline = 0) {
    super(key);
    this.component = VueTextControl;
    this.props = { ikey: key, label, readonly, multiline };
  }

  setValue(val) {
    this.vueContext.value = val;
  }
}
