import gql from 'graphql-tag';

const GC_GET_GAME_LIST = gql`
query GetGameList {
    game {
      id
      name
      title
      game_type_id
    }
  }
`

const GC_GET_GAME_TYPES = gql`
query GetGameTypes {
    game_type {
      id
      name
      description
      picto
      slug
    }
  }
`;

const GC_ADD_GAME_ONE = gql`
mutation AddGameOne($name: String!, $title: String!, $instruction: String!, $extra_instruction: String!, $gameType: uuid!, $questions: [question_insert_input!]!, $headers:[table_header_insert_input!]!) {
  insert_game_one(object: {name:$name, title:$title, instruction: $instruction, extra_instruction: $extra_instruction, game_type_id: $gameType, questionId: {data:$questions}, headers: {data: $headers}}) {
    id
  }
}
`

const GC_GET_GAME_BY_ID = gql`
query GameByID($id: uuid!) {
  game(where: {id: {_eq: $id}}) {
    name
    title
    instruction
    extra_instruction
    id
    headers {
      content
      column
      line
    }
    type:gameType {
      name
      id
      description
      slug
    }
    questions:questionId {
      correction
      column
      line
      id
      text
      answers {
        text
        is_correct
        id
      }
    }
  }
}
`

const GC_DELETE_GAME_BY_ID = gql`
mutation DeleteGameOne($id: uuid!) {
  delete_game(where: {id: {_eq: $id}}) {
    affected_rows
  }
  delete_question(where: {game_id: {_eq: $id}}) {
    affected_rows
  }
  delete_table_header(where: {game_id: {_eq: $id}}) {
    affected_rows
  }
}
`

const GC_UPDATE_GAME_BY_ID = gql`
mutation UpdateGameOne($id: uuid!, $instruction: String!, $extra_instruction: String!, $name: String!, $title: String!, $gameType: uuid!, $questions: [question_insert_input!]!, $headers:[table_header_insert_input!]!) {
  delete_game(where: {id: {_eq: $id}}) {
    affected_rows
  }
  delete_question(where: {game_id: {_eq: $id}}) {
    affected_rows
  }
  delete_table_header(where: {game_id: {_eq: $id}}) {
    affected_rows
  }
  insert_game_one(object: {name:$name, title:$title, instruction: $instruction, extra_instruction: $extra_instruction, id: $id, game_type_id: $gameType, questionId: {data:$questions}, headers: {data: $headers}}) {
    id
  }
}
`

const GC_CHECK_NAME_EXISTENCE = gql`
query NameExist($name: String!) {
  game(where: {name: {_eq:$name}}) {
    id
    name
  }
}

`

export {
    GC_GET_GAME_TYPES,
    GC_GET_GAME_LIST,
    GC_ADD_GAME_ONE,
    GC_GET_GAME_BY_ID,
    GC_UPDATE_GAME_BY_ID,
    GC_DELETE_GAME_BY_ID,
    GC_CHECK_NAME_EXISTENCE
}