import gql from 'graphql-tag'

const GC_GET_MEDIA_LIST = gql`
query GetMediaList {
    media {
    	id
    	name
    	description
    	path
    	thumbnail_path
    	media_type_id
    }
  }
`

export {
    GC_GET_MEDIA_LIST
}