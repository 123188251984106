<template>
    <div class="game">
        <div class="game-header">
            <div class="buttons flex flex-row justify-between">
                <button class="twn-button text-xs" type="button" @click="goBackAndReset">Retour</button>
                <div class="buttons-right">
                    <button @click="deleteGame" class="twn-button text-xs danger" type="button" :disabled="!itemID || isSaving">Supprimer le minijeu</button>
                </div>
            </div>
        </div>
        <div class="form-group mt-12 mb-5">
            <label for="game-name" class="uppercase font-principal-medium text-sm">Identifiant</label>
            <b-form-input v-model="gameName" :state="isNameTaken" type="text" id="game-name" class="w-full"></b-form-input>
        </div>

        <div class="form-group mt-0 mb-5">
            <label for="game-title" class="uppercase font-principal-bold text-sm">Titre du {{currentType ? currentType.name : ''}}</label>
            <b-form-input v-model="gameTitle" type="text" id="game-title" class="w-full"></b-form-input>
        </div>

        <div class="form-group mt-0 mb-5">
            <label for="game-instruction" class="uppercase font-principal-medium text-sm">Instructions</label>
            <b-form-input v-model="gameInstruction" type="text" id="game-instruction" class="w-full"></b-form-input>
        </div>

        <div v-if="currentType.slug == 'quiz_table' || currentType.slug == 'drag_drop'" class="form-group mt-0 mb-10">
            <label for="game-instruction" class="uppercase font-principal-medium text-sm">Instructions supplémentaires</label>
            <b-form-input v-model="gameExtraInstruction" type="text" id="game-instruction" class="w-full"></b-form-input>
        </div>

        <component :is="selectComponent()"></component>


        <button @click="saveGame" class="twn-button text-xs float-right mt-5 mb-16" type="button" :disabled="isSaving">Enregistrer le minijeu</button>

        <button @click="previewGame" :disabled="!itemID || isSaving" class="twn-button text-xs float-right mt-5 mb-16 mr-2" type="button">Aperçu</button>

        <b-modal ref="delete-modal-game" class="bootstrap" centered hide-footer id="delete-modal-game" hide-header>
            <div class="d-block text-center my-6 uppercase font-semibold">
                <h3>Confirmer la suppression</h3>
            </div>
            <div class="flex flex-row justify-evenly items-center">
                <button type="button" class="mt-4 twn-button" @click="$bvModal.hide('delete-modal-game')">Retour</button>
                <button type="button" class="mt-4 twn-button danger" @click="confirmDelete">Supprimer</button>
            </div>
        </b-modal>
    </div>
</template>
<script>
import router from '@/router/index'
import {mapGetters, mapActions, mapMutations} from 'vuex'
import QCM from '@/views/Games/QCM'
import QCMTab from '@/views/Games/QCM_Tab'
import DragNDrop from '@/views/Games/DragNDrop'
import CellByCell from '@/views/Games/CellByCell'
import Reorder from '@/views/Games/Reorder'
import Vocab from '@/views/Games/Vocabulary'
import TexteTrou from '@/views/Games/Texte_Trou'

export default {
    name:'GameCreation',
    components:{
        QCM,
        QCMTab,
        DragNDrop,
        CellByCell,
        Reorder,
        Vocab,
        TexteTrou
    },
    data() {
        return {
            isSaving: false,
            currentComponent: null,
        }
    },
    props:['itemID', 'gameType'],
    created() {
        if (!this.itemID){
            if (this.gameType){
                this.setCurrentType(this.gameType);
                this.setReset(true);
            } else {
                this.setReset(false);
            }
            this.$router.currentRoute.meta.title = `Créer un ${this.currentType ? this.currentType.name : ''}`
        } else {
            this.getGameFromID(this.itemID);
            this.setReset(false);
        }
    },
    computed:{
        ...mapGetters('Games', ['currentGame', 'isNameTaken', 'currentType']),
        gameName: {
            get () {return this.currentGame ? this.currentGame.name : ''},
            set (value) { this.updateGameName(value) }
        },
        gameTitle: {
            get () {return this.currentGame ? this.currentGame.title : ''},
            set (value) { this.updateGameTitle(value) }
        },
        gameInstruction: {
            get () {return this.currentGame ? this.currentGame.instruction : ''},
            set (value) { this.updateInstruction(value) }
        },
        gameExtraInstruction: {
            get () {return this.currentGame ? this.currentGame.extra_instruction : ''},
            set (value) { this.updateExtraInstruction(value) }
        }
    },
    methods: {
        ...mapActions('Games', ['submitGame', 'getGameFromID', 'deleteGameByID']),
        ...mapMutations('Games', ['setCurrentType', 'updateGameName', 'updateGameTitle', 'updateInstruction', 'updateExtraInstruction', 'setReset']),
        showDeleteModal(){
            this.$refs['delete-modal-game'].show();
        },
        async saveGame() {
            this.isSaving = true
            await this.submitGame()
            this.isSaving = false
        },
        deleteGame(){
            if (this.currentGame.id){
                this.showDeleteModal()
            }
        },
        confirmDelete(){
            this.deleteGameByID(this.currentGame.id);
            this.$router.push({name: 'games-list'});
        },
        goBackAndReset(){
            this.$router.push({name: 'games-list'});
        },
        selectComponent(){
            if (!this.currentType)
                return null

            switch(this.currentType.name){
                case 'Vrai / faux':
                    return QCM;
                case 'QCM':
                    return QCM;
                case 'QCM tableaux':
                    return QCMTab;
                case 'Drag and drop':
                    return DragNDrop;
                case 'Cell by cell':
                    return CellByCell;
                case 'Reorder':
                    return Reorder;
                case 'Vocabulaire':
                    return Vocab;
                case 'Texte à trou':
                    return TexteTrou;
            }
        },
        previewGame() {
            window.open(process.env.VUE_APP_FRONT_URL + 'game/preview/' + this.itemID, '_blank');
        }
    }
}
</script>