<template>
    <div>
        <CellByCell sameCell="true">
            <template slot-scope="{i, j}">
                <BooleanCell :i="i" :j="j"></BooleanCell>
            </template>
        </CellByCell>
    </div>
</template>
<script>

import CellByCell from '@/views/Games/CellByCell'
import BooleanCell from '@/components/Games/Cells/Boolean'
import { mapActions, mapGetters, mapMutations } from 'vuex';

const default_answer = () => {
    return {
        text: 'Default',
        is_correct: false
    }
}

const default_table_header_line = () => {
    return {
        content: 'Ligne',
        index: 1,
        removeState: false
    }
}

const default_table_header_column = () => {
    return {
        content: 'Colonne',
        index: 1,
        removeState: false
    }
}

const defaultQuestionEx = (col, line) => {
    return {
        answers:[default_answer()],
        text: '',
        column: col,
        line: line,
        gameID: '',
        type: '',
        correction: '',
        removeState: false
    }
}

const default_game = () => {
    return {
        id: '',
        type: {name: '', id: ''},
        name: '',
        table_header_line: [default_table_header_line()],
        table_header_column: [null, default_table_header_column()],
        questions: [defaultQuestionEx(0, 0)],
        instruction: '',
        extra_instruction: '',
    }
}


export default {
    components: {
        CellByCell,
        BooleanCell
    },
    created(){
        //TODO: see why state is freed on reload
        if (this.resetGame) this.setDefaultGame(default_game);
        this.setDefaultQuestion(defaultQuestionEx);
    },
    computed: {
        ...mapGetters('Games', ['currentGame', 'resetGame']),
    },
    methods: {
        ...mapActions('Games', ['setDefaultGame']),
        ...mapMutations('Games', ['setDefaultQuestion'])
    }
}
</script>
<style scoped>

</style>