<template>
    <div>
        <div class="label-zone mb-5">
            <div class="uppercase font-principal-bold text-sm">Zone de creation de label</div>
            <div class="flex flex-row mt-2 max-h-full flex-wrap">
                <div class="label flex flex-row flex-center" v-for="(label, i) in currentGame.labels" :key="i">
                    <div class="content ml-1 text-sm" contenteditable="true" @blur="updateLabelText({value:$event.target.innerText, index:i})" v-text="label.text">
                        <!-- {{label.text}} -->
                    </div>
                    <div class="flex close" @click="removeLabel(i)"><b-icon-x ></b-icon-x></div>
                </div>
                <div class="ml-2 cursor-pointer flex-center text-xl text-principal-list-item" @click="addDefaultLabel">
                    +
                </div>
            </div>
        </div>
        <div class="uppercase font-principal-bold text-sm">Tableau {{currentGame.type.name}} </div>
        <CellByCell sameCell="true">
            <template slot-scope="{i, j}">
                <QuestionLabel :line="i" :column="j"></QuestionLabel>
            </template>
        </CellByCell>
    </div>
</template>
<script>
import QuestionLabel from '@/components/Games/Cells/Question_Label'
import CellByCell from '@/views/Games/CellByCell'
import { mapActions, mapMutations, mapGetters } from 'vuex';


const default_table_header_line = () => {
    return {
        content: 'Ligne',
        index: 1,
        removeState: false
    }
}

const default_table_header_column = () => {
    return {
        content: 'Colonne',
        index: 1,
        removeState: false
    }
}

const default_question = (col, line) => {
    return {
        answers:[],
        text: '',
        column: col,
        line: line,
        gameID: '',
        type: '',
        correction: '',
        removeState: false
    }
}

const default_label = () => {
    return {
        text:'Default',
        is_correct: true,
        removeState: false
    }
}

const default_game = () => {
    return {
        id: '',
        type: {name: '', id: ''},
        name: '',
        table_header_line: [default_table_header_line()],
        table_header_column: [null, default_table_header_column()],
        questions: [default_question(0, 0)],
        labels: [],
        instruction: '',
        extra_instruction: '',
    }
}

export default {
    components: {
        CellByCell,
        QuestionLabel
    },
    created(){
        if (this.resetGame) this.setDefaultGame(default_game);
    },
    computed: {
        ...mapGetters('Games', ['currentGame', 'resetGame']),
    },
    methods: {
        ...mapMutations('Games', ['setDefaultAnswer', 'setDefaultQuestion']),
        ...mapActions('Games/CellByCell/DragNDrop', ['addDefaultLabel', 'updateLabelText', 'removeLabel']),
        ...mapActions('Games', ['setDefaultGame'])
    }
}
</script>
<style scoped>

</style>