import Vue from 'vue'
import VueRouter from 'vue-router'
import Authentication from '@/views/Authentication';
import Dashboard from '@/views/Dashboard'
import UserList from '@/views/UserList'
import OrganizationList from '@/views/OrganizationList'
import NotFound from '@/views/NotFound'
import UserDetail from '@/views/UserDetail'
import OrganizationDetail from '@/views/OrganizationDetail'
import ScenarioList from '@/views/ScenarioList'
import ScenarioDetail from '@/views/ScenarioDetail'
import MediaList from '@/views/Medias'
import GamesList from '@/views/MinigamesList'
import GameCreation from '@/views/GameCreation'

import { GC_AUTH_TOKEN } from '@/constants/jwt'

import store from '@/store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      title: 'Tableau de bord',
      breadcrumb: 'Tableau de bord'
    }
  },
  {
    path: '/auth',
    name: 'auth',
    component: Authentication,
    meta: {
      title: 'Authentication',
      layout: 'no-sidebar',
      isAuth: true,
    }
  },
  {
    path: '/user/list',
    name: 'users-list',
    props: true,
    component: UserList,
    meta: {
      breadcrumb: 'Utilisateurs',
      title: 'Liste des utilisateurs'
    }
  },
  {
    path: '/user/edit',
    name: 'users-edit',
    component: UserDetail,
    props: true,
    meta: {
      breadcrumb: 'Utilisateurs',
      title: 'Editer un utilisateur'
    }
  },
  {
    path: '/user/create',
    name: 'users-create',
    component: UserDetail,
    props: true,
    meta: {
      breadcrumb: 'Utilisateurs',
      title: 'Ajouter un utilisateur'
    }
  },
  {
    path: '/organisation/list/',
    name: 'organisations-list',
    props: true,
    component: OrganizationList,
    meta: {
      breadcrumb: 'Gestion des offices',
      title: 'Liste des offices'
    }
  },
  {
    path: '/organisation/edit',
    name: 'organisations-edit',
    component: OrganizationDetail,
    props: true,
    meta: {
      breadcrumb: 'Offices',
      title: 'Editer un office'
    }
  },
  {
    path: '/organisation/create',
    name: 'organisations-create',
    component: OrganizationDetail,
    props: true,
    meta: {
      breadcrumb: 'Offices',
      title: 'Ajouter un office'
    }
  },
  {
    path: '/scenario/list',
    name: 'scenario-list',
    props: true,
    component: ScenarioList,
    meta: {
      breadcrumb: "Scénarios",
      title: 'Liste des scénarios'
    }
  },
  {
    path: '/scenario/edit/:itemID?',
    name: 'scenario-edit',
    component: ScenarioDetail,
    props: true,
    meta: {
      title: 'Editer un scénario',
      noMargin: true
    }
  },
  {
    path: '/media/list',
    name: 'media-list',
    component: MediaList,
    meta: {
      breadcrumb: 'Medias',
      title: 'Liste des assets'
    }
  },
  {
    path: '/games/list',
    name: 'games-list',
    props: true,
    component: GamesList,
    meta: {
      breadcrumb: 'Minijeux',
      title: 'Liste des minijeux'
    }
  },
  {
    path:'/game/create',
    name: 'game-create',
    component: GameCreation,
    props: true,
    meta: {
      breadcrumb: 'Minijeux',
      title: 'Créer un minijeu'
    }
  },
  {
    path:'/game/edit',
    name: 'game-edit',
    component: GameCreation,
    props: true,
    meta: {
      breadcrumb: 'Minijeux',
      title: 'Editer un minijeu'
    }
  },
  {
    path: '*',
    component: NotFound,
    name: 'notfound',
    meta: {
      breadcrumb: '404',
      layout: 'no-sidebar',
      title: '404 - Not Found'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/backoffice/',
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.path !== '/auth' && to.path !== '/password-forgot' && to.name !== 'password-reset') {
    if (store.getters['Auth/isLoggedIn']) {
      await store.dispatch('Auth/getCurrentUserInfo')
      return next()
    } else {
      return next('/auth')
    }
  } else {
    if (store.getters['Auth/isLoggedIn']) {
      await store.dispatch('Auth/getCurrentUserInfo')
      return next('/')
    } else {
      return next()
    }
  }
})


export default router
